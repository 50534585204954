import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const WhatWeCollect = () => (
  <>
    <Points.Heading pointNumber="2." heading="What We Collect About You" />
    <Points.Indent>
      <>
        <Points.Span pointNumber="2.1." Content={POINT_2_1} />
        <POINT_2_2 />
        <Points.Span pointNumber="2.3." Content={POINT_2_3} />
        <Points.Span pointNumber="2.4." Content={POINT_2_4} />
      </>
    </Points.Indent>
  </>
);

const POINT_2_1 = (
  <Text.Span content="Personal data, or personally identifiable data, means any information about an individual, from which that person can be identified. It does not include information where the identity has been removed (anonymous data)." />
);
const POINT_2_3 = (
  <Points.Content>
    <Text.Span content="We also collect, use, and share " />
    <Text.Bold content="Aggregated Data" />
    <Text.Span content=" such as statistical or demographic data for any purpose. Aggregated Data may be derived from personal data but is not considered personal data in law as this data does " />
    <Text.Bold content="not" />
    <Text.Span content=" directly or indirectly reveal an identity. For example, we may aggregate Usage Data to calculate the percentage of users accessing a specific feature. However, if we combine or connect Aggregated Data with personal data so that it can directly or indirectly identify anyone, we treat the combined data as personal data which will be used in accordance with this Privacy Policy." />
  </Points.Content>
);
const POINT_2_4 = (
  <Text.Span content="Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with services or allow you to provide us with your services)." />
);

//#region 2.2
const POINT_2_2 = () => (
  <>
    <Points.SubHeadingElement pointNumber="2.2." Content={POINT_2_2_HEADING} />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="2.2.1." Content={POINT_2_2_1} />
        <Points.Span2 pointNumber="2.2.2." Content={POINT_2_2_2} />
        <Points.Span2 pointNumber="2.2.3." Content={POINT_2_2_3} />
        <Points.Span2 pointNumber="2.2.4." Content={POINT_2_2_4} />
        <Points.Span2 pointNumber="2.2.5." Content={POINT_2_2_5} />
        <Points.Span2 pointNumber="2.2.6." Content={POINT_2_2_6} />
        <Points.Span2 pointNumber="2.2.7." Content={POINT_2_2_7} />
        <Points.Span2 pointNumber="2.2.8." Content={POINT_2_2_8} />
      </>
    </Points.Indent>
  </>
);
const POINT_2_2_HEADING = (
  <Points.Content>
    <Text.Span content="We may collect, use, store, and transfer (" />
    <Text.Bold content="“process”" />
    <Text.Span content=") different kinds of personal data about you which we have grouped together as follows:" />
  </Points.Content>
);
const POINT_2_2_1 = (
  <Points.Content>
    <Text.Bold content="Identity Data" />
    <Text.Span content=" including full/name, or the information about your company such as company name, address and/or company registration details;" />
  </Points.Content>
);
const POINT_2_2_2 = (
  <Points.Content>
    <Text.Bold content="Contact Data" />
    <Text.Span content=" including email address, physical/registered addresses, and contact numbers;" />
  </Points.Content>
);
const POINT_2_2_3 = (
  <Points.Content>
    <Text.Bold content="Jobseeker Data" />
    <Text.Span content=" including all information provided by a Jobseeker to Ditto Jobs including their curriculum vitae, contact details and all other information accessible in their candidate profile on Ditto Jobs;" />
  </Points.Content>
);
const POINT_2_2_4 = (
  <Points.Content>
    <Text.Bold content="Job Listing Data" />
    <Text.Span content=" including all information provided by an Employer to Nova to advertise an available job listing including job description, location, estimated salary/earnings, company information and anything else associated with the job listing;" />
  </Points.Content>
);
const POINT_2_2_5 = (
  <Points.Content>
    <Text.Bold content="Transaction Data" />
    <Text.Span content=" including details about payments to and from you, contracts, contractual terms, contract fees, signups, subscriptions, invoices and other details of products and services you have obtained from us, or provide to us;" />
  </Points.Content>
);
const POINT_2_2_6 = (
  <Points.Content>
    <Text.Bold content="Technical Data" />
    <Text.Span content=" including internet protocol address/es, login data, browser type and version, time zone setting and location, cookies, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access the Platform;" />
  </Points.Content>
);
const POINT_2_2_7 = (
  <Points.Content>
    <Text.Bold content="Usage Data" />
    <Text.Span content=" including information about how you use our company, Platform, surveys, and Services; and" />
  </Points.Content>
);
const POINT_2_2_8 = (
  <Points.Content>
    <Text.Bold content="Marketing and Communications Data" />
    <Text.Span content=" including your preferences in receiving notices and marketing from us and our third parties and your communication preferences." />
  </Points.Content>
);
//#endregion
