import styled from "styled-components";
import { FIELDS, FIELD_COLORS, FIELD_CSS } from "../../FormFieldsStyles";

type ContainerProps = {
  disabled: boolean;
  Error: boolean;
  isDarkTheme: boolean;
  isFieldActive: boolean;
};

const Container = styled.div<ContainerProps>`
  .icon-input {
    ${({ disabled }) => disabled && FIELDS.DISABLED_FORM_CONTROL};
    ${({ isFieldActive, Error }) => isFieldActive && !Error && FIELD_CSS.HOVER};
    border: 1px solid
      ${({ Error }) => {
        return Error
          ? `${FIELD_COLORS.ERROR_FIELD_BORDER_COLOR} !important`
          : FIELD_COLORS.FIELD_BORDER_COLOR;
      }};
    border-radius: 5px;
  }
  .input-field-icon {
    border: 0px solid;
    border-radius: 4px 0 0 4px;
  }
  .icon-input:hover {
    ${({ Error }) => (Error ? FIELD_CSS.ERROR_HOVER : FIELD_CSS.HOVER)};
  }
  .icon-input:active {
    ${FIELD_CSS.HOVER};
  }
  .icon-input:focus {
    ${FIELD_CSS.HOVER};
  }
  .icon-input:focus::placeholder {
    color: transparent;
  }

  .icon-input input:hover {
    box-shadow: none !important;
  }
  .icon-input input:focus {
    box-shadow: none !important;
  }
  .icon-input input:active {
    box-shadow: none !important;
  }

  .form-control {
    border: 0px !important;
    box-shadow: 0 0 0px 1000px white inset;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    ${FIELD_CSS.TEXT};
  }
`;
export const InputIconStyles = { Container };
