import * as dateFn from "date-fns";

import { HELPERS } from "src/Common";

const {
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  startOfLastWeek,
  endOfLastWeek,
  startOfMonth,
  endOfMonth,
  startOfLastMonth,
  endOfLastMonth,
} = HELPERS.DATE;

const getRangeType = (
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  const start = dateFn.startOfDay(startDate || 0).getTime();
  const end = dateFn.endOfDay(endDate || 0).getTime();

  const isToday = start === startOfToday && end === endOfToday;
  const isYesterday = start === startOfYesterday && end === endOfYesterday;
  const isThisWeek = start === startOfWeek && end === endOfWeek;
  const isLastWeek = start === startOfLastWeek && end === endOfLastWeek;
  const isThisMonth = start === startOfMonth && end === endOfMonth;
  const isLastMonth = start === startOfLastMonth && end === endOfLastMonth;

  if (isToday) return "Today";
  else if (isYesterday) return "Yesterday";
  else if (isThisWeek) return "This Week";
  else if (isLastWeek) return "Last Week";
  else if (isThisMonth) return "This Month";
  else if (isLastMonth) return "Last Month";
  else return "Custom";
};

const getDayObject = (date: Date | undefined) => {
  const day = date?.getDate();
  const month = date?.toLocaleString("default", { month: "short" });
  const year = date?.getFullYear();
  return { day, month, year };
};
const getDateLabel = (
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  const { day: startDay, month: startMonth } = getDayObject(startDate);
  const { day: endDay, month: endMonth, year: endYear } = getDayObject(endDate);
  return `${startDay} ${startMonth} - ${endDay} ${endMonth}, ${endYear}`;
};

export const datepickerButtonUtils = {
  getDateLabel,
  getRangeType,
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  startOfLastWeek,
  endOfLastWeek,
  startOfMonth,
  endOfMonth,
  startOfLastMonth,
};
