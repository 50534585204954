import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const Payments = () => (
  <>
    <Points.Heading pointNumber="4." heading="Payment For Our Services" />
    <Points.Indent>
      <>
        <POINT_4_1 />
        <POINT_4_2 />
      </>
    </Points.Indent>
  </>
);

//#region 4.1
const POINT_4_1 = () => (
  <>
    <Points.SubHeading pointNumber="4.1." heading="Packages" />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="4.1.1." Content={POINT_4_1_1} />
        <Points.Span2 pointNumber="4.1.2." Content={POINT_4_1_2} />
        <Points.Span2 pointNumber="4.1.3." Content={POINT_4_1_3} />
        <Points.Span2 pointNumber="4.1.4." Content={POINT_4_1_4} />
        <Points.Span2 pointNumber="4.1.5." Content={POINT_4_1_5} />
        <Points.Span2 pointNumber="4.1.6." Content={POINT_4_1_6} />
      </>
    </Points.Indent>
  </>
);
const POINT_4_1_1 = (
  <Points.Content>
    <Text.Span
      content={`Nova has various packages available to use the Services which may change from time to time. Employers can decide between single listing packages or the programmatic package.`}
    />
  </Points.Content>
);
const POINT_4_1_2 = (
  <Points.Content>
    <Text.Span
      content={`There are two single listing packages available, Basic, and Premium which are both available as a once off purchase for one set advertised fee. For these packages, we request an upfront payment of the advertised fee per listing. There are no additional costs associated with the package offering.`}
    />
  </Points.Content>
);
const POINT_4_1_3 = (
  <Points.Content>
    <Text.Span
      content={`The third available package is programmatic which includes a set advertised price with the option to allocate additional budget towards creating an advertising campaign for your job listing.`}
    />
  </Points.Content>
);
const POINT_4_1_4 = (
  <Points.Content>
    <Text.Span
      content={`With the programmatic package, you will be presented with a recommended Cost Per Click or “CPC” (being the charge per Jobseeker click on Nova). You will be asked to select your desired bid and to allocate the maximum campaign budget you are willing to spend for that campaign. The higher the bid, the better chance you have of your advert being seen by the category of Jobseekers you are desiring.`}
    />
  </Points.Content>
);
const POINT_4_1_5 = (
  <Points.Content>
    <Text.Span
      content={`When you opting for the programmatic package, Nova requires you to select your maximum cost per view, the total campaign budget, and the campaign end date.`}
    />
  </Points.Content>
);
const POINT_4_1_6 = (
  <Points.Content>
    <Text.Span
      content={`Once your campaign is running on Nova, you will be charged on a weekly basis depending on how much of your nominated budget you use until the budget you set is exhausted or until your campaign comes to an end (whichever is sooner). The minimum amount that will be charged at the end of any campaign will be the base cost of a single job post. You will only ever be charged by Nova for the amount of the set budget that you use. Nova will never exceed your budget or allow you to exceed the budget and incur in additional unforeseen costs. The minimum charge amount may change from time to time in Ditto Media’s discretion.`}
    />
  </Points.Content>
);
//#endregion

//#region 4.2
const POINT_4_2 = () => (
  <>
    <Points.SubHeading pointNumber="4.2." heading="General Payment Terms" />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="4.2.1." Content={POINT_4_2_1} />
        <Points.Span2 pointNumber="4.2.2." Content={POINT_4_2_2} />
        <Points.Span2 pointNumber="4.2.3." Content={POINT_4_2_3} />
      </>
    </Points.Indent>
  </>
);

const POINT_4_2_1 = (
  <Points.Content>
    <Text.Span
      content={`All fees paid for any of the above packages are non-refundable. You can view the fees on Nova. Receipts will be made available for download on your profile.`}
    />
  </Points.Content>
);
const POINT_4_2_2 = (
  <Points.Content>
    <Text.Span
      content={`Nova cannot guarantee a Jobseeker will view any listing or apply for such listing. For single listings, we do not charge for views on any job listing. For the programmatic package, you will be charged for the job listing and any Jobseeker views based on your CPC value.`}
    />
  </Points.Content>
);
const POINT_4_2_3 = (
  <Points.Content>
    <Text.Span
      content={`We are committed to providing secure online payment facilities. All transactions are processed using `}
    />
    <Text.Link content="Stripe" />
    <Text.Span
      content={`. When using Stripe, Stripe and our internal CRM systems will process your personal data. You are welcome to opt-out of any marketing communications and exercise your rights to the processing of your data in accordance with our Privacy Policy. `}
    />
  </Points.Content>
);
//#endregion
