import { createSlice } from "@reduxjs/toolkit";

import { ReportsProps } from "./Reports";
import { HELPERS } from "src/Common";

const initialHiringProgressResponse: ReportsProps.HiringProgress = {
  count: 0,
  percentage: 0,
};

const start_date = HELPERS.DATE.getStartOfMonth();
const end_date = HELPERS.DATE.getEndOfMonth();
const linearChartRequest = { start_date, end_date };

const initialState: ReportsProps.State = {
  applicationRange: {} as ReportsProps.applicationRange,

  cardGroupData: {} as ReportsProps.CardGroupData,
  cardGroupAPICallsCompleted: false,

  viewsChartRequest: linearChartRequest,
  applicationsChartRequest: linearChartRequest,
  jobsCreatedChartRequest: linearChartRequest,
  viewsChartResponse: {} as ReportsProps.linearChartResponse,
  applicationsChartResponse: {} as ReportsProps.linearChartResponse,
  jobsCreatedChartResponse: {} as ReportsProps.linearChartResponse,
  viewsChartErrorCode: 0,
  applicationsChartErrorCode: 0,
  jobsCreatedChartErrorCode: 0,

  workSituationChartResponse: {} as ReportsProps.WorkSituationChartResponse,
  employmentTypeChartResponse: {} as ReportsProps.EmploymentTypeChartResponse,
  workSituationChartCompleted: false,
  employmentTypeChartCompleted: false,
  workSituationChartErrorCode: 0,
  employmentTypeChartErrorCode: 0,

  advertisingMixChartResponse: {
    premium: { percentage: 0 },
    programmatic: { percentage: 0 },
    standard: { percentage: 0 },
  },
  advertisingMixChartCompleted: false,
  advertisingMixChartErrorCode: 0,

  hiringProgressResponse: {
    inbox: initialHiringProgressResponse,
    screening: initialHiringProgressResponse,
    shortlist: initialHiringProgressResponse,
    interview: initialHiringProgressResponse,
    offer_made: initialHiringProgressResponse,
    onboarding: initialHiringProgressResponse,
  },
  hiringProgressCompleted: false,
  hiringProgressErrorCode: 0,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    setApplicationRange: (state, action: ReportsProps.setApplicationRange) => {
      state.applicationRange = action.payload;
    },

    //#region Card group
    setCardGroupData: (state, action: ReportsProps.setCardGroupData) => {
      state.cardGroupData = action.payload;
    },
    reset: () => initialState,
    setInitialCardGroupData: (state) => {
      state.cardGroupData = {
        active_jobs: 0,
        unread: 0,
        views: 0,
        applications: 0,
      };
    },
    setCardGroupAPICallsCompleted: (
      state,
      action: ReportsProps.setCardGroupAPICallsCompleted
    ) => {
      state.cardGroupAPICallsCompleted = action.payload;
    },
    //#endregion

    //#region Linear charts
    setViewsChartRequest: (
      state,
      action: ReportsProps.setViewsChartRequest
    ) => {
      state.viewsChartRequest = action.payload;
    },
    setApplicationsChartRequest: (
      state,
      action: ReportsProps.setApplicationsChartRequest
    ) => {
      state.applicationsChartRequest = action.payload;
    },
    setJobsCreatedChartRequest: (
      state,
      action: ReportsProps.setJobsCreatedChartRequest
    ) => {
      state.jobsCreatedChartRequest = action.payload;
    },

    setViewsChartResponse: (
      state,
      action: ReportsProps.setViewsChartResponse
    ) => {
      state.viewsChartResponse = action.payload;
    },
    setApplicationsChartResponse: (
      state,
      action: ReportsProps.setApplicationsChartResponse
    ) => {
      state.applicationsChartResponse = action.payload;
    },
    setJobsCreatedChartResponse: (
      state,
      action: ReportsProps.setJobsCreatedChartResponse
    ) => {
      state.jobsCreatedChartResponse = action.payload;
    },

    setViewsChartErrorCode: (
      state,
      action: ReportsProps.setViewsChartErrorCode
    ) => {
      state.viewsChartErrorCode = action.payload;
    },
    setApplicationsChartErrorCode: (
      state,
      action: ReportsProps.setApplicationsChartErrorCode
    ) => {
      state.applicationsChartErrorCode = action.payload;
    },
    setJobsCreatedChartErrorCode: (
      state,
      action: ReportsProps.setJobsCreatedChartErrorCode
    ) => {
      state.jobsCreatedChartErrorCode = action.payload;
    },
    //#endregion

    //#region Doughnut charts
    setWorkSituationChartResponse: (
      state,
      action: ReportsProps.setWorkSituationChartResponse
    ) => {
      state.workSituationChartResponse = action.payload;
    },
    setEmploymentTypeChartResponse: (
      state,
      action: ReportsProps.setEmploymentTypeChartResponse
    ) => {
      state.employmentTypeChartResponse = action.payload;
    },

    setWorkSituationChartCompleted: (
      state,
      action: ReportsProps.setWorkSituationChartCompleted
    ) => {
      state.workSituationChartCompleted = action.payload;
    },
    setEmploymentTypeChartCompleted: (
      state,
      action: ReportsProps.setEmploymentTypeChartCompleted
    ) => {
      state.employmentTypeChartCompleted = action.payload;
    },

    setWorkSituationChartErrorCode: (
      state,
      action: ReportsProps.setWorkSituationChartErrorCode
    ) => {
      state.workSituationChartErrorCode = action.payload;
    },
    setEmploymentTypeChartErrorCode: (
      state,
      action: ReportsProps.setEmploymentTypeChartErrorCode
    ) => {
      state.employmentTypeChartErrorCode = action.payload;
    },
    //#endregion

    //#region Half Doughnut charts
    setAdvertisingMixChartResponse: (
      state,
      action: ReportsProps.setAdvertisingMixChartResponse
    ) => {
      state.advertisingMixChartResponse = action.payload;
    },
    setAdvertisingMixChartCompleted: (
      state,
      action: ReportsProps.setAdvertisingMixChartCompleted
    ) => {
      state.advertisingMixChartCompleted = action.payload;
    },
    setAdvertisingMixChartErrorCode: (
      state,
      action: ReportsProps.setAdvertisingMixChartErrorCode
    ) => {
      state.advertisingMixChartErrorCode = action.payload;
    },
    //#endregion

    //#region Hiring progress
    setHiringProgressResponse: (
      state,
      action: ReportsProps.setHiringProgressResponse
    ) => {
      state.hiringProgressResponse = action.payload;
    },
    setHiringProgressCompleted: (
      state,
      action: ReportsProps.setHiringProgressCompleted
    ) => {
      state.hiringProgressCompleted = action.payload;
    },
    setHiringProgressErrorCode: (
      state,
      action: ReportsProps.setHiringProgressErrorCode
    ) => {
      state.hiringProgressErrorCode = action.payload;
    },
    //#endregion
  },
});

export const reportsActions = reportsSlice.actions;
export const reportsReducer = reportsSlice.reducer;
