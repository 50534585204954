import { EChartsOption, SeriesOption } from "echarts";

import { theme } from "src/components/Theme";

const radius = 50;

const series: SeriesOption = {
  cursor: "default",
  name: "",
  type: "pie",
  radius: [`${radius}`, `${radius + 38}px`],
  center: [`50%`, `${radius * 2}px}`],
  bottom: "25px",
  // adjust the start angle
  startAngle: 180,
  label: {
    show: false,
    formatter: (param: any) => `${param.data.value}% \n${param.name}`,
    position: "inner",
    fontFamily: "Poppins, Sans-serif",
    fontSize: 12,
    fontWeight: 500,
    color: theme.colors.setting_grey,
    lineHeight: 16,
  },
  data: [
    { value: 46, name: "Standard", itemStyle: { color: "#1e78be" } },
    { value: 36, name: "Premium", itemStyle: { color: "#52aef5" } },
    { value: 18, name: "Boosted", itemStyle: { color: "#c8e7ff" } },
    {
      // make an record to fill the bottom 50%
      value: 100,
      // stop the chart from rendering bottom half
      itemStyle: { color: "none", decal: { symbol: "none" } },
      label: { show: false },
    },
  ],
};
const option: EChartsOption = {
  tooltip: {
    trigger: "item",
    valueFormatter: (value) => `${value}%`,
  },
  legend: {
    top: "32px",
    left: "center",
    name: "Advertising Mix",
    itemGap: 20,
    textStyle: { color: theme.colors.setting_grey },
  },
  series,
};

export const HalfDoughnutChartUtils = { option, series };
