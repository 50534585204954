import * as Yup from "yup";

import { CampaignProps } from "src/Redux";
import { theme } from "src/components/Theme";
import { FormFieldsProps } from "src/components/Basic";

const chartData: CampaignProps.chartData = {
  bid_start: "1.2",
  bid_end: "10.8",
  bid_stepper: "0.15",

  info: {
    job_title: "", // Job title + City + Distance,
    city: "",
    distance: "",
  },
  x_axis_data: [
    "0.25",
    "0.5",
    "0.75",
    "1",
    "1.25",
    "1.5",
    "1.75",
    "2",
    "2.25",
    "2.5",
    "2.75",
    "3",
    "3.25",
    "3.50",
  ],
  axis_pointer_value: "2.75", // Set to middle of recommended section (green)
  series_data: [
    // Each item is a bar. Must match total items in x-axis
    {
      value: 156,
      bidType: "low",
    },
    {
      value: 391,
      bidType: "low",
    },
    {
      value: 441,
      bidType: "low",
    },
    {
      value: 1127,
      bidType: "other",
    },
    {
      value: 1255,
      bidType: "other",
    },
    {
      value: 1307,
      bidType: "other",
    },
    {
      value: 2005,
      bidType: "recommended",
    },
    {
      value: 2129,
      bidType: "recommended",
    },
    {
      value: 2209,
      bidType: "recommended",
    },
    {
      value: 2212,
      bidType: "other",
    },
    {
      value: 2282,
      bidType: "other",
    },
    {
      value: 2402,
      bidType: "other",
    },
    {
      value: 2752,
      bidType: "other",
    },
    {
      value: 2797,
      bidType: "other",
    },
  ],
  //  Start and end values of green section
  recommended_range: { start_value: "2.5", end_value: "3" },
  low_range: { start_value: "0", end_value: "1" },
};
const bidColors: CampaignProps.BidColors = {
  recommended: theme.colors.success_default,
  low: theme.colors.warning_default,
  other: theme.colors.primary_default,
};

const idPrefix = "boosted_campaign";
const date = new Date();
const placeholder = date.toLocaleDateString();
const campaign_duration_from: FormFieldsProps.Datepicker = {
  fieldName: "campaign_duration_from",
  idPrefix,
  isRequired: true,
  label: "Campaign start date",
  showLabel: false,
  placeholder: placeholder,
};
const campaign_duration_to: FormFieldsProps.Datepicker = {
  fieldName: "campaign_duration_to",
  idPrefix,
  isRequired: true,
  label: "Campaign end date",
  showLabel: false,
  placeholder: placeholder,
};
const total_campaign_budget: FormFieldsProps.InputIcon = {
  fieldName: "total_campaign_budget",
  idPrefix,
  isRequired: true,
  label: "Total Campaign Budget",
  placeholder: "Budget",
  icon: <div />,
  iconLocation: "prefix",
  inputType: "number",
};
const campaign_input: FormFieldsProps.Input = {
  fieldName: "campaign_input",
  idPrefix,
  isRequired: false,
  inputType: "number",
  placeholder: " ",
};
const formFields = {
  campaign_duration_from,
  campaign_duration_to,
  total_campaign_budget,
  campaign_input,
};
const requiredFields = {
  campaign_duration_from: true,
  campaign_duration_to: true,
  total_campaign_budget: true,
  campaign_input: true,
};

//#region Validation schema
// const today = new Date();
// today.setHours(0, 0, 0, 0);

const validationSchema = Yup.object({
  campaign_duration_from: Yup.date()
    //.min(today, "Date cannot be in the past")
    .required("Campaign start date is required"),
  campaign_duration_to: Yup.date()
    .test(
      "campaign_duration_to",
      "Campaign end date must be after Campaign start date",
      function (value) {
        const { campaign_duration_from } = this.parent;
        if (!campaign_duration_from || !value) return true; // Validation will pass if either field is empty
        return new Date(value) > new Date(campaign_duration_from);
      }
    )
    .required("Campaign end date is required"),
  total_campaign_budget: Yup.number()
    .min(50, "Total campaign budget must be greater than or equal to 50")
    .required("Total campaign budget is required"),
});
//#endregion

export const BoostedCampaignUtils = {
  chartData,
  bidColors,
  validationSchema,
  idPrefix,
  formFields,
  requiredFields,
};
