import { useContext, useEffect } from "react";

import { useAppSelector } from "src/Redux/";
import { HalfDoughnutChartUtils } from "./Utils";
import { HalfDoughnutChart } from "./HalfDoughnutChart";
import { theme } from "src/components/Theme";
import { ReportsPageProps } from "../Reports";
import { AppContext } from "src/App";
import { API_CALLS } from "src/API_CALLS";
import { ERROR_CODES } from "src/API_CALLS/utils";

export const HalfDoughnutCharts = ({
  type,
}: ReportsPageProps.HalfDoughnutCharts) => {
  const { isMobile } = useContext(AppContext);

  const { common, reports } = useAppSelector((store) => store);
  const { isDarkTheme } = common;
  const {
    advertisingMixChartResponse,
    advertisingMixChartCompleted,
    advertisingMixChartErrorCode,
  } = reports;
  const data: ReportsPageProps.HalfDoughnutChartsData = {
    AdvertiseMix: {
      title: "Advertising Mix",
      response: advertisingMixChartResponse,
      apiCall: API_CALLS.REPORTS.getAdvertisingMixChart,
      apiCallCompleted: advertisingMixChartCompleted,
      errorCode: advertisingMixChartErrorCode,
    },
  };

  const { apiCall, apiCallCompleted, response, title, errorCode } = data[type];

  useEffect(() => {
    if (apiCallCompleted) return;
    apiCall();
  }, [apiCall, apiCallCompleted]);

  const color = theme.colors[isDarkTheme ? "black50" : "setting_grey"];
  const { option, series } = HalfDoughnutChartUtils;

  const [standard, premium, boosted, invisible] = series.data as any[];
  standard.value = response.standard?.percentage;
  premium.value = response.premium?.percentage;
  boosted.value = response.programmatic?.percentage;

  const radius = isMobile ? 100 : 150;
  series.radius = [`${radius}`, `${radius + 38}px`];
  series.center = [`50%`, `${radius * 2 + 32}px}`];
  series.top = isMobile ? "-15px" : "-25px";

  const hasNoResultsError = ERROR_CODES.NO_RESULTS_ERROR === errorCode;
  return (
    <HalfDoughnutChart
      option={{
        ...option,
        legend: { ...option.legend, textStyle: { color } },
        series: {
          ...series,
          data: [
            { ...standard },
            { ...premium },
            { ...boosted },
            { ...invisible },
          ],
        },
      }}
      {...{ title, hasNoResultsError }}
    />
  );
};
