import { Typography } from "src/components/Basic";
import { ICONS } from "src/assets/custom_Icons";
import {
  ActivityContainer,
  ActivityTimelineContainer,
  Container,
  DateContainer,
  IconContainer,
  TimelineContainer,
  Wrapper,
  HeadingContainer,
} from "./ActivityStyle";
import { useAppSelector } from "src/Redux";

const Activity = () => {
  const { activity } = useAppSelector((state) => state.candidate);
  const wordHandler = (value: string) => {
    let data = value;
    const wordData = ["deleted", "files", "folders", "login"];
    for (let word of wordData) {
      data = data.replaceAll(
        word,
        `<label class ="word_${word}">${word}</label>`
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: data }} />;
  };
  return (
    <ActivityContainer>
      <Typography
        content="ACTIVITY"
        styleName="candidateNameEmailOpen"
        color="primary_default"
      />
      <ActivityTimelineContainer>
        {activity?.length > 0 ? (
          <TimelineContainer className="vtimeline">
            {activity?.map((comment, index) => (
              <div
                className="timeline-wrapper timeline-inverted timeline-wrapper-primary"
                key={index}
              >
                <div className="timeline-badge "></div>
                <Container>
                  <div className="comment-name">
                    <Typography
                      content={comment.name}
                      styleName="activityTextHeading"
                      color="black85"
                    />
                  </div>
                  <Wrapper>
                    {wordHandler(comment.description)}
                    <DateContainer>
                      <Typography
                        content={comment.date}
                        styleName="activityDateText"
                        color="black160"
                      />
                    </DateContainer>
                  </Wrapper>
                </Container>
              </div>
            ))}
          </TimelineContainer>
        ) : (
          <>
            <HeadingContainer className="no-data-msg">
              <Typography
                content="No  activity added."
                styleName="text1"
                color="black80"
              />
            </HeadingContainer>
            <IconContainer>
              <ICONS.ActivityIcon />
            </IconContainer>
          </>
        )}
      </ActivityTimelineContainer>
    </ActivityContainer>
  );
};

export default Activity;
