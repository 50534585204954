import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const General = () => (
  <>
    <Points.Heading pointNumber="15." heading="General" />
    <Points.Indent>
      <>
        <Points.Span pointNumber="15.1." Content={POINT_15_1} />
        <Points.Span pointNumber="15.2." Content={POINT_15_2} />
        <Points.Span pointNumber="15.3." Content={POINT_15_3} />
        <Points.Span pointNumber="15.4." Content={POINT_15_4} />
        <Points.Span pointNumber="15.5." Content={POINT_15_5} />
        <Points.Span pointNumber="15.6." Content={POINT_15_6} />
        <Points.Span pointNumber="15.7." Content={POINT_15_7} />
        <Points.Span pointNumber="15.8." Content={POINT_15_8} />
        <Points.Span pointNumber="15.9." Content={POINT_15_9} />
        <Points.Span pointNumber="15.10." Content={POINT_15_10} />
        <Points.Span pointNumber="15.11." Content={POINT_15_11} />
      </>
    </Points.Indent>
  </>
);

const POINT_15_1 = (
  <Points.Content>
    <Text.SpanUnderline content="Relationship Between the Parties" />
    <Text.Span content=" - The relationship of the parties, inter se, shall be governed by these Terms and nothing contained herein shall be deemed to constitute a partnership, joint venture, employer/employee agreement, agency agreement, or the like between them. No party shall by the reasons of the actions of the other party incur any personal liability as co-partner to any third party.  Ditto Media provides software services and is not an agent, intermediary nor partner or adviser to any user. All tools provided on Nova or as part of the Services are for information purposes only. Any formal engagement between users facilitated by Nova is between them privately." />
  </Points.Content>
);
const POINT_15_2 = (
  <Points.Content>
    <Text.SpanUnderline content="Force Majeure" />
    <Text.Span content=" - If either party is prevented, whether in whole or in part, or delayed from performing any of its duties, functions or obligations under these Terms, whether timeously or at all, due to an event out of their control (which for the purposes hereof shall mean war, political riots, civil commotions, electrical load-shedding or surges, legal prohibitions or restrictions, epidemics, pandemics, governmental lockdowns, fire, floods or other similar natural disasters), then such failure shall not constitute a breach under these Terms, and the obligation to perform shall be suspended to the extent and during the continuance of such prevention provided that the parties shall use their reasonable commercial endeavors to minimize any delay occasioned" />
  </Points.Content>
);
const POINT_15_3 = (
  <Points.Content>
    <Text.SpanUnderline content="Change Without Notice" />
    <Text.Span content=" - Nova and these Terms are subject to change without notice. These Terms are updated or amended from time to time and will be effective once we upload the amended Terms to Nova. Your continued access or use of the Nova/Services constitutes your acceptance to be bound by these Terms, as amended. It is your responsibility to read these Terms periodically to ensure you are aware of any changes." />
  </Points.Content>
);
const POINT_15_4 = (
  <Points.Content>
    <Text.SpanUnderline content="Entire Agreement" />
    <Text.Span content=" - This document contains the entire agreement between the parties in relation to the subject matter hereof. Save as contemplated in clause 15.3, no alteration, cancellation, variation of, or addition hereto will be of any force or effect unless reduced to writing and signed by all the parties to these Terms or their duly authorized representatives." />
  </Points.Content>
);
const POINT_15_5 = (
  <Points.Content>
    <Text.SpanUnderline content="No Indulgence" />
    <Text.Span content=" - No indulgence, leniency or extension of time granted by Ditto Media shall constitute a waiver of any of our rights under these Terms and, accordingly, we shall not be precluded as a consequence of having granted such indulgence, from exercising any rights against the client which may have arisen in the past or which might arise in the future." />
  </Points.Content>
);
const POINT_15_6 = (
  <Points.Content>
    <Text.SpanUnderline content="Importation of Words" />
    <Text.Span content=" - Words importing the singular will include the plural and vice versa. Words importing one gender will include the other genders, and words importing persons will include partnerships, trusts, and bodies corporate, and vice versa." />
  </Points.Content>
);
const POINT_15_7 = (
  <Points.Content>
    <Text.SpanUnderline content="Headings as Reference" />
    <Text.Span content=" - The headings to the paragraphs in these Terms are inserted for reference purposes only and will not affect the interpretation of any of the provisions to which they relate." />
  </Points.Content>
);
const POINT_15_8 = (
  <Points.Content>
    <Text.SpanUnderline content="Governing Law" />
    <Text.Span content=" - Your access and/or use of Nova and/or the Services and the operation of these Terms (including any transaction concluded pursuant thereto) shall be governed by and construed in accordance with the laws of the State of Delaware." />
  </Points.Content>
);
const POINT_15_9 = (
  <Points.Content>
    <Text.SpanUnderline content="Failure to Pay" />
    <Text.Span content=" - In the event of a user failing to pay any amount timeously or breaching these Terms, they shall be liable for all legal costs (on the scale as between attorney and client) (including collection commission) which may be incurred by Ditto Media in relation to the payment failure or breach." />
  </Points.Content>
);
const POINT_15_10 = (
  <Points.Content>
    <Text.SpanUnderline content="Severability" />
    <Text.Span content=" - Each sentence, paragraph, term, clause and provision of these Terms and any portion thereof shall be considered severable and if for any reason, any such sentence, paragraph, term, clause or provision is held to be invalid, contrary to, or in conflict with any applicable present or future law or regulation or in terms of a final, binding judgment issued by any court, it shall to that extent be deemed not to form part hereof and shall not impair the operation of, or have any effect upon such other sentence, paragraph, term, clause or provision hereof as may otherwise remain valid or intelligible, which shall continue to be given full force and effect and bind the parties hereto." />
  </Points.Content>
);
const POINT_15_11 = (
  <Points.Content>
    <Text.SpanUnderline content="Prohibited Provision" />
    <Text.Span content=" - No term or condition of these Terms is intended to breach any peremptory provisions of any consumer protection legislation and any regulations thereto (" />
    <Text.Bold content={`"Prohibited Provision"`} />
    <Text.Span content=").  Any breach of any such Prohibited Provision shall be governed by the provisions of clause 15.10." />
  </Points.Content>
);
