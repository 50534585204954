import styled from "styled-components";

import { theme } from "src/components/Theme";

export const BoostedPointer = () => <Circle />;

const Circle = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${theme.colors.white_pure};
  border: 1px solid ${theme.colors.primary_default};
  box-shadow: 0px 0px 8px 1px #1e96f2, inset 0px 0px 1px #1e96f2;
`;
