import store, { CampaignProps, actions } from "src/Redux";
import { AdvertiseProps } from "./Advertise";
import { API_CALLS } from "src/API_CALLS";
import { HELPERS } from "src/Common";

const standard: AdvertiseProps.AdvertiseType = {
  title: "Standard",
  price: { price: `0`, isStriked: false },
  points: [
    {
      bold: "Hi ",
      normal: "sentence with 30 characters",
      advertType: "standard",
    },
    {
      bold: "2 Free ",
      normal: "Domain Name with 5 day",
      advertType: "standard",
    },
    {
      bold: "30 characters ",
      normal: "per bullet point",
      advertType: "standard",
    },
    { bold: "2 Free ", normal: "Domain Name", advertType: "standard" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "standard" },
  ],
};
const premium: AdvertiseProps.AdvertiseType = {
  title: "Premium",
  price: { price: `0`, isStriked: false },
  points: [
    {
      bold: "Hi ",
      normal: "sentence with 30 characters",
      advertType: "premium",
    },
    {
      bold: "2 Free ",
      normal: "Domain Name with 5 day",
      advertType: "premium",
    },
    { bold: "2 Free ", normal: "Domain Name", advertType: "premium" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "premium" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "premium" },
  ],
};
const boosted: AdvertiseProps.AdvertiseType = {
  title: "Boosted",
  price: {} as AdvertiseProps.price,
  points: [
    { bold: "2 Free ", normal: "Domain Name", advertType: "boosted" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "boosted" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "boosted" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "boosted" },
    { bold: "2 Free ", normal: "Domain Name", advertType: "boosted" },
  ],
};
const points = { standard, premium, boosted };

const product_ids = {
  standard: process.env.REACT_APP_STRIPE_STANDARD || "",
  premium: process.env.REACT_APP_STRIPE_PREMIUM || "",
  boosted: process.env.REACT_APP_STRIPE_PROGRAMMATIC || "",
} as const;

const postCampaign = (
  boostedCampaignForm: CampaignProps.boostedCampaignForm
) => {
  const { isEditFlow } = store.getState().createJob;

  if (isEditFlow) postCampaignUpdate(boostedCampaignForm);
  else postCampaignCreate(boostedCampaignForm);
};
const postCampaignUpdate = (
  boostedCampaignForm: CampaignProps.boostedCampaignForm
) => {
  const { campaignProductResponse } = store.getState().campaign;

  const start_date = new Date(boostedCampaignForm.campaign_duration_from);
  const end_date = new Date(boostedCampaignForm.campaign_duration_to);

  const data: CampaignProps.updateBoostedCampaign = {
    job_id: campaignProductResponse.job_id,
    currency: campaignProductResponse.currency,
    product_id: product_ids["boosted"],
    budget: parseFloat(boostedCampaignForm.total_campaign_budget),
    cpc: parseFloat(boostedCampaignForm.campaign_input),
    start_date: HELPERS.DATE.getDateInAPIFormat(start_date),
    end_date: HELPERS.DATE.getDateInAPIFormat(end_date),
  };
  API_CALLS.CAMPAIGN.updateBoostedCampaign(data);
  store.dispatch(actions.campaign.setShowCampaignLoadingAnimation(true));
};
const postCampaignCreate = (
  boostedCampaignForm: CampaignProps.boostedCampaignForm
) => {
  const { createJob, campaign } = store.getState();
  const { createResponse, createNovaJobForm } = createJob;
  const { advertType } = campaign;

  const common = {
    job_id: `${createResponse.id}`,
    product_id: product_ids[advertType || "standard"],
    currency: createNovaJobForm.base_currency.label,
  } as const;

  const start_date = new Date(boostedCampaignForm.campaign_duration_from);
  const end_date = new Date(boostedCampaignForm.campaign_duration_to);
  if (advertType === "boosted") {
    const boostedCampaignData: CampaignProps.createBoostedCampaign = {
      ...common,
      budget: parseFloat(boostedCampaignForm.total_campaign_budget),
      cpc: parseFloat(boostedCampaignForm.campaign_input),
      start_date: HELPERS.DATE.getDateInAPIFormat(start_date),
      end_date: HELPERS.DATE.getDateInAPIFormat(end_date),
    };
    API_CALLS.CAMPAIGN.createBoostedCampaign(boostedCampaignData);
  } else {
    const singleCampaignData: CampaignProps.createSingleCampaign = {
      ...common,
      price: 0,
      charge_id: "",
    };
    API_CALLS.CAMPAIGN.createSingleCampaign(singleCampaignData);
  }
};

export const AdvertiseUtils = { points, postCampaign };
