const HOME = "/";

const REGISTER = "/auth/register";
const SIGN_IN = "/auth/sign-in";
const FORGOT = "/auth/forgot";
const RESET = "/auth/reset";
const OTP = "/auth/otp";

const JOBS = "/jobs";
const CREATE_JOB = "/create-job";
const SETTINGS = "/settings";
const PIPELINE = "/pipeline/:job_id";
const TEAM_MANAGEMENT = "/settings/team/verify";
const PIPELINE_OVERVIEW = "/pipeline";

const ONBOARDING = "/onboarding";
const ONBOARDING_VERIFY = "/onboarding/verify";

const TERMS_AND_CONDITIONS = "/terms_and_conditions";
const REPORTS = "/reports";
const NOTIFICATION = "/notification";

export const ROUTES = {
  HOME,

  REGISTER,
  SIGN_IN,
  FORGOT,
  RESET,
  OTP,

  JOBS,
  CREATE_JOB,
  SETTINGS,
  PIPELINE,
  TEAM_MANAGEMENT,
  PIPELINE_OVERVIEW,

  ONBOARDING,
  ONBOARDING_VERIFY,

  TERMS_AND_CONDITIONS,
  REPORTS,
  NOTIFICATION,
};
