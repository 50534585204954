import styled from "styled-components";

import { theme } from "src/components/Theme";
import { useAppSelector } from "src/Redux";

type TableData = {
  color: string;
  label: string;
  value: number;
  percent: number;
};
type TableProps = {
  values: TableData[];
};

const TableRow = ({ color, label, percent, value }: TableData) => (
  <tr key={label}>
    <td>
      <IconContainer color={color} />
    </td>
    <td className="label">{label}</td>
    <td className="value">{value}</td>
    <td className="percent">{`${percent}%`}</td>
  </tr>
);

export const Table = ({ values }: TableProps) => {
  const { isDarkTheme } = useAppSelector((store) => store.common);
  return (
    <Container isDarkTheme={isDarkTheme}>
      <thead>
        <tr className="heading">
          <th></th>
          <th>Label</th>
          <th className="value">Value</th>
          <th className="percent">%</th>
        </tr>
      </thead>
      <tbody>
        {values.map((value) =>
          value.percent > 0 ? (
            <TableRow key={value.label} {...value} />
          ) : (
            <TableRow key={value.label} {...value}></TableRow>
          )
        )}
      </tbody>
    </Container>
  );
};

const Container = styled.table<{ isDarkTheme: boolean }>`
  flex-grow: 1;
  height: fit-content;
  max-height: 220px;
  overflow: auto;
  .heading {
    border-bottom: 1px solid ${theme.colors.neutral50};
  }
  th,
  td {
    padding: 6px 12px 6px 8px;
  }
  th {
    ${theme.text.reportsChartTableHeading};
    color: ${({ isDarkTheme }) =>
      isDarkTheme ? theme.colors.black50 : theme.colors.neutral50};
  }
  td {
    ${theme.text.reportsChartTableText};
    color: ${({ isDarkTheme }) =>
      isDarkTheme ? theme.colors.black40 : theme.colors.neutral70};
  }
  .label {
    color: ${({ isDarkTheme }) =>
      isDarkTheme ? theme.colors.black60 : theme.colors.neutral70};
  }
  .value,
  .percent {
    text-align: right;
  }

  tbody {
    display: block;
    max-height: 160px;
    overflow: auto;
  }
  thead tr {
    display: block;
  }
  .value {
    width: 100%;
  }
`;

type IconProps = { color: string };
const IconContainer = styled.div<IconProps>`
  height: 12px;
  width: 12px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`;
