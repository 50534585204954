import { Form } from "react-bootstrap";
import { Field, FieldProps } from "formik";
import styled from "styled-components";
import { useState } from "react";
import { FormFieldCommon } from "../FormFieldsCommon";
import { FormFieldsProps } from "../FormFields";
import { FIELDS } from "../FormFieldsStyles/common";
import { REGEX } from "../REGEX";

import { useAppSelector } from "src/Redux";

export const InputWithIcon = (
  props: FormFieldsProps.InputWithIcon & { errorMessage?: string }
) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    errorMessage,
    disabled = false,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    inputType = "text",
    defaultValue = "",
    showLabel = true,
    icon,
  } = props;

  const [isFocused, setFocused] = useState(false);
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const { isDarkTheme } = useAppSelector((state) => state.common);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta, form }: FieldProps) => {
        const { onChange, value, onBlur } = field;
        const { touched } = meta;
        const { setFieldValue } = form;

        let hasError =
          !isFocused &&
          isRequired &&
          touched &&
          (value === undefined || value?.length === 0);
        let errorLabel = errorMessage || `${label || fieldName} is required`;

        if (inputType === "email") {
          const isValidEmail = REGEX.EMAIL.test(value);
          if (!isValidEmail) hasError = !isFocused && touched && true;
          errorLabel = "Please enter valid email";
        } else if (inputType === "URL" && value) {
          const isValidURL = REGEX.WEBSITE.test(value);
          if (!isValidURL) hasError = !isFocused && touched && true;
          errorLabel = "Please enter valid URL";
        } else if (!isDefaultSet && inputType === "number" && isNaN(value)) {
          setFieldValue(fieldName, defaultValue);
          setIsDefaultSet(true);
        }

        const onChangeNumber = (e: React.ChangeEvent<any>) => {
          const value = e.target.value;
          if (!value) {
            onChange(e);
            return;
          }
          const newNumber = Number.parseFloat(value);
          if (!isNaN(newNumber)) setFieldValue(fieldName, newNumber);
        };

        const showErrorLabel = hasError && errorLabel ? true : false;

        return (
          <InputContainer {...{ isDarkTheme, disabled, Error: hasError }}>
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <div className="position-relative">
              <Form.Control
                id={`${idPrefix}_${fieldName}`}
                type="text"
                className="form-control"
                name={fieldName}
                placeholder={placeholder}
                onChange={inputType === "number" ? onChangeNumber : onChange}
                value={value || defaultValue}
                disabled={disabled}
                onFocus={() => setFocused(true)}
                onBlur={(e) => {
                  setFocused(false);
                  onBlur(e);
                }}
              />
              <div
                className="position-absolute"
                style={{ top: "10px", left: "17px" }}
              >
                {icon}
              </div>
            </div>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </InputContainer>
        );
      }}
    </Field>
  );
};

const InputContainer = styled.div<{
  disabled: boolean;
  Error: boolean;
  isDarkTheme: boolean;
}>`
  ${({ Error, disabled }) => {
    if (disabled) return FIELDS.DISABLED_FORM_CONTROL;
    return Error ? FIELDS.ERROR_INPUT_FORM_CONTROL : FIELDS.INPUT_FORM_CONTROL;
  }};

  & input {
    padding-left: 50px;
  }
`;
