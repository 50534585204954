import { useContext, useState } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import { Buttons, FormikHelpers, Typography } from "src/components/Basic";
import Input from "../Components/input";
import { onboardValidationSchema } from "../Components/Utils";
import { OnboardingUtils } from "./Utils";
import { AppContext } from "src/App";
import { API_CALLS } from "src/API_CALLS";
import { useAppSelector } from "src/Redux";

export const SetPassword = () => {
  const navigate = useNavigate();
  const { verifyResponse } = useAppSelector((store) => store.onboarding);

  const { isMobile } = useContext(AppContext);
  const [submitClicked, setSubmitClicked] = useState(false);

  const isAPICallInProgress = false;
  const errorMessageFromAPI = "";
  const { formFields, initialValues, idPrefix } = OnboardingUtils;
  const { password, confirm_password } = formFields;

  return (
    <Container className="set-password-container">
      <TypoWrapper>
        <Typography
          styleName={
            isMobile ? "onboardingIntroTextMobile" : "onboardingPasswordText"
          }
          content="Please set your password"
          color="black_pure"
        />
      </TypoWrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={onboardValidationSchema}
        onSubmit={(values) => {
          const register = API_CALLS.ONBOARDING.register;
          register({ ...verifyResponse, password: values.password }, navigate);
        }}
      >
        {(formik) => (
          <FormContainer>
            <Input {...password} />
            <Input {...confirm_password} />
            <Buttons
              isDisable={
                submitClicked &&
                (isAPICallInProgress || !formik.isValid || errorMessageFromAPI)
                  ? true
                  : false
              }
              label="Register"
              handleClick={() => {
                formik.setTouched({ password: true, confirm_password: true });
                formik.validateForm();
                const requiredFieldNameWithNoValue =
                  FormikHelpers.getRequiredFieldNameWithNoValue(
                    { password, confirm_password },
                    formik.values
                  );
                const isFormUnTouched =
                  Object.keys(formik.touched).length === 0;
                const isNoErrors =
                  isFormUnTouched || Object.keys(formik.errors).length === 0;
                const isValidForm = !requiredFieldNameWithNoValue && isNoErrors;
                !isValidForm &&
                  FormikHelpers.setFocusOnErrorNode(
                    idPrefix + requiredFieldNameWithNoValue
                  );

                isValidForm && formik.submitForm();
                setSubmitClicked(true);
              }}
              variant="primary"
              width={isMobile ? "142px" : "342px"}
            />
          </FormContainer>
        )}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 576px) {
    padding-top: 12px;
    width: 446px;
  }
`;
const TypoWrapper = styled.span`
  padding-bottom: 32px;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;
  align-items: center;
`;
