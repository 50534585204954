import { createContext, useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { Grid } from "antd";

import {
  Buttons,
  FormFields,
  FormFieldCommon,
  FormikHelpers,
  FormFieldsProps,
} from "src/components/Basic";
import { QualifyingQuestion, Question } from "./QualifyingQuestion";
import { jobCreateUtils } from "./Utils";
import { API_CALLS } from "src/API_CALLS";
import {
  CommonProps,
  useAppDispatch,
  useAppSelector,
  actions,
  CreateJobProps,
} from "src/Redux";
import { CreateJobStyles } from "../Styles";
import { CreateJobComponentProps } from "../CreateJob";
import { CreateJobContext } from "..";

export const CreateJobStep1Context = createContext(
  {} as CreateJobComponentProps.CreateJobStep1Context
);
export const JobCreate = () => {
  const screen = Grid.useBreakpoint();
  const {
    initialJobCreateFields,
    idPrefix,
    jobCreateValidationSchema,
    QualifyingQuestionFields,
  } = jobCreateUtils;

  //#region Redux
  const dispatch = useAppDispatch();
  const { common, createJob } = useAppSelector((store) => store);
  const { industries, skills } = common;
  const {
    createInProgress,
    createNovaJobForm,
    createError,
    questionComponents,
    isJobCreated,
    createResponse,
  } = createJob;
  //#endregion

  const { setCreateJobFormValid } = useContext(CreateJobContext);

  //#region States
  const [submitClicked, setSubmitClicked] = useState(false);
  const [lastQuestionNumber, setLastQuestionNumber] = useState(0);
  const [jobCreateFields, setJobCreateFields] = useState(
    initialJobCreateFields
  );
  const [addExternalApplyURL, setAddExternalApplyURL] = useState(false);
  //#endregion

  //#region UseEffects
  const external_apply_name = jobCreateFields.external_apply.fieldName;
  useEffect(() => {
    if (!addExternalApplyURL) return;

    const id = `${idPrefix}_${external_apply_name}`;
    const element = document.getElementById(id);
    element?.focus();
  }, [addExternalApplyURL, external_apply_name, idPrefix, createInProgress]);
  //#endregion

  //#region Helper functions
  const addQuestion = () => {
    const newQuestionNumber = lastQuestionNumber + 1;
    const newQuestionComponents = { ...questionComponents };
    const newQuestion = <Question questionNumber={newQuestionNumber} />;
    newQuestionComponents[newQuestionNumber] = newQuestion;
    setLastQuestionNumber(newQuestionNumber);
    dispatch(actions.createJob.setQuestionComponents(newQuestionComponents));

    //Adding new fields to jobCreateFields
    const {
      idealAnswerNumeric,
      qualifyingQuestion,
      rejectCriteria,
      responseType,
    } = QualifyingQuestionFields;

    const getFieldName = (object: any) => object.fieldName + newQuestionNumber;
    const idealAnswerFieldName = getFieldName(idealAnswerNumeric);
    const newJobCreateFields = { ...jobCreateFields };
    newJobCreateFields[idealAnswerFieldName] = {
      ...idealAnswerNumeric,
      fieldName: idealAnswerFieldName,
    };
    const qualifyingQuestionFieldName = getFieldName(qualifyingQuestion);
    newJobCreateFields[qualifyingQuestionFieldName] = {
      ...qualifyingQuestion,
      fieldName: qualifyingQuestionFieldName,
    };
    const rejectCriteriaFieldName = getFieldName(rejectCriteria);
    newJobCreateFields[rejectCriteriaFieldName] = {
      ...rejectCriteria,
      fieldName: rejectCriteriaFieldName,
    };
    const responseTypeFieldName = getFieldName(responseType);
    newJobCreateFields[responseTypeFieldName] = {
      ...responseType,
      fieldName: responseTypeFieldName,
    };
    setJobCreateFields(newJobCreateFields);
    setSubmitClicked(false);
  };
  const removeQuestion = (questionNumber: number) => {
    const newQuestionComponents = { ...questionComponents };
    delete newQuestionComponents[questionNumber];
    dispatch(actions.createJob.setQuestionComponents(newQuestionComponents));

    //Removing fields from jobCreateFields
    const {
      idealAnswerNumeric,
      qualifyingQuestion,
      rejectCriteria,
      responseType,
    } = QualifyingQuestionFields;

    const newJobCreateFields = { ...jobCreateFields };
    const getFieldName = (object: any) => object.fieldName + questionNumber;
    const idealAnswerFieldName = getFieldName(idealAnswerNumeric);
    const qualifyingQuestionFieldName = getFieldName(qualifyingQuestion);
    const rejectCriteriaFieldName = getFieldName(rejectCriteria);
    const responseTypeFieldName = getFieldName(responseType);

    delete newJobCreateFields[idealAnswerFieldName];
    delete newJobCreateFields[qualifyingQuestionFieldName];
    delete newJobCreateFields[rejectCriteriaFieldName];
    delete newJobCreateFields[responseTypeFieldName];
    setJobCreateFields(newJobCreateFields);
  };
  const removeAllQuestions = () => {
    const {
      idealAnswerNumeric,
      qualifyingQuestion,
      rejectCriteria,
      responseType,
    } = QualifyingQuestionFields;
    const getFieldName = (object: any) => object.fieldName;

    const idealAnswerFieldName = getFieldName(idealAnswerNumeric);
    const qualifyingQuestionFieldName = getFieldName(qualifyingQuestion);
    const rejectCriteriaFieldName = getFieldName(rejectCriteria);
    const responseTypeFieldName = getFieldName(responseType);

    const newJobCreateFields = { ...jobCreateFields };
    Object.keys(questionComponents).forEach((question) => {
      delete newJobCreateFields[idealAnswerFieldName + question];
      delete newJobCreateFields[qualifyingQuestionFieldName + question];
      delete newJobCreateFields[rejectCriteriaFieldName + question];
      delete newJobCreateFields[responseTypeFieldName + question];
    });
    return newJobCreateFields;
  };
  const questionComponent = <QualifyingQuestion />;
  //#endregion

  //#region Autocomplete fields
  const getOptions = (object: CommonProps.industries | CommonProps.skills) =>
    Object.values(object).map(({ id, name }) => ({
      label: name,
      value: id,
    }));

  const industryOptions: CommonProps.industryOptions = getOptions(industries);
  const skillOptions: CommonProps.skillOptions = getOptions(skills);
  const onIndustryChangeHandler = (value: string) => {
    industryOptions.push({ label: value, value });
  };
  const onSkillsChangeHandler = (value: string) => {
    skillOptions.push({ label: value, value });
  };
  //#endregion

  return (
    <CreateJobStep1Context.Provider value={{ removeQuestion, addQuestion }}>
      <Formik
        initialValues={{ ...createNovaJobForm }}
        onSubmit={() => {}}
        validationSchema={jobCreateValidationSchema}
      >
        {(formik) => {
          const questions = Object.keys(questionComponents);
          const _ = formik.values;

          let isValidForm = formik.isValid;

          if (_.add_external_apply_URL && _.external_apply === "")
            isValidForm = false;
          if (_.add_qualifying_question) {
            const fields = {} as { [key: string]: boolean };
            questions.forEach(
              (question) => (fields[`qualifying_question${question}`] = true)
            );
            if (FormikHelpers.hasRequiredFieldNameWithNoValue(fields, _))
              isValidForm = false;
          }
          const disableButton =
            createInProgress || (submitClicked && !isValidForm);

          const isFormTouched = Object.keys(formik.touched).length > 0;
          if (isFormTouched) {
            setCreateJobFormValid(formik.isValid);
            formik.isValid &&
              dispatch(actions.createJob.setCreateNovaJobForm(_));
          }
          const onSubmitHandler = () => {
            if (!isValidForm) return;

            const qualifying_questions = _.add_qualifying_question
              ? questions.map((question) => ({
                  ideal_answer: _[`ideal_answer${question}`],
                  qualifying_question: _[`qualifying_question${question}`],
                  reject_criteria: _[`reject_criteria${question}`]
                    ? _[`reject_criteria${question}`]
                    : false,
                  response_type: _[`response_type${question}`],
                }))
              : [];

            //#region Industries and skills
            const industries = [] as CreateJobProps.industries[];
            const industries_custom = [] as CreateJobProps.industries_custom[];
            _.industry.forEach((industry) => {
              if (isNaN(industry.value))
                industries_custom.push({ name: industry.value });
              else industries.push({ id: +industry.value });
            });

            const skills = [] as CreateJobProps.skill[];
            const skills_custom = [] as CreateJobProps.skills_custom[];
            _.skills.forEach((skill) => {
              if (isNaN(skill.value)) skills_custom.push({ name: skill.value });
              else skills.push({ id: +skill.value });
            });
            //#endregion

            const base_currency = _.base_currency as FormFieldsProps.option;

            const createUpdateRequest: CreateJobProps.CreateUpdateRequest = {
              advertised_type: _.advertised_type,
              base_currency: base_currency?.label,
              city: _.city.value,
              country: _.country.name,
              department: _.department,
              experience_from: _.experience_from,
              experience_to: _.experience_to,
              external_apply: _.add_external_apply_URL ? _.external_apply : "",
              industry: [{ industries, industries_custom }],
              job_description: _.job_description,
              job_title: _.job_title,
              qualifying_questions,
              remuneration_from: _.remuneration_from,
              remuneration_to: _.remuneration_to,
              show_company_gallery: _.show_company_gallery,
              skills: [{ skills, skills_custom }],
              type: _.type,
              work_environment: _.work_environment,
            };
            if (isJobCreated)
              API_CALLS.JOBS.updateJob(createResponse.id, createUpdateRequest);
            else API_CALLS.JOBS.createJob(createUpdateRequest);
          };

          //#region Manual updation of form
          if (questions.length === 0 && _.add_qualifying_question)
            addQuestion();
          if (!_.add_external_apply_URL) {
            const { touched } = formik.getFieldMeta("external_apply");
            touched && formik.setFieldTouched("external_apply", false);
          }
          //#endregion

          const externalApplyLabel =
            jobCreateFields.add_external_apply_URL?.label;
          const Additional = (
            <>
              <FormFields.Checkbox {...jobCreateFields.show_company_gallery} />
              <div>
                <FormFields.Checkbox
                  {...jobCreateFields.add_external_apply_URL}
                  label={
                    _.add_external_apply_URL
                      ? externalApplyLabel + "*"
                      : externalApplyLabel
                  }
                  onChangeHandler={(value) => setAddExternalApplyURL(value)}
                />
                {_.add_external_apply_URL && (
                  <FormFields.Input {...jobCreateFields.external_apply} />
                )}
              </div>
              <CreateJobStyles.QualifierContainer>
                <FormFields.Switch
                  {...jobCreateFields.add_qualifying_question}
                />
                <FormFieldCommon.AdditionalLabel
                  content="(max 5 questions)"
                  className="component-additional-label"
                />
              </CreateJobStyles.QualifierContainer>
            </>
          );

          const nextClickHandler = () => {
            dispatch(actions.createJob.setCreateNovaJobForm(_));
            setSubmitClicked(true);

            //#region Formik Fields additional conditions
            let newJobCreateFields = { ...jobCreateFields };
            const { external_apply } = newJobCreateFields;
            external_apply.isRequired = _.add_external_apply_URL ? true : false;
            if (!_.add_qualifying_question)
              newJobCreateFields = removeAllQuestions();
            //#endregion

            FormikHelpers.formikSubmitHandler({
              formFields: { ...newJobCreateFields, external_apply },
              formik,
              onSubmitHandler,
              idPrefix,
            });
          };

          return (
            <CreateJobStyles.Container>
              <CreateJobStyles.CreateJobFormContainer>
                <Row>
                  <Col md={6} lg={5} xxl={4} className="left-side">
                    <FormFields.Input {...jobCreateFields?.job_title} />
                    <FormFields.CountrySelector {...jobCreateFields.country} />
                    <FormFields.CitySearch {...jobCreateFields.city} />
                    <FormFields.Dropdown
                      {...jobCreateFields.work_environment}
                    />
                    <FormFields.Dropdown {...jobCreateFields.type} />
                    <FormFields.Dropdown
                      {...jobCreateFields.industry}
                      {...{
                        options: industryOptions,
                        onInputChangeHandler: onIndustryChangeHandler,
                      }}
                    />
                    <FormFields.Dropdown
                      {...jobCreateFields.skills}
                      {...{
                        options: skillOptions,
                        onInputChangeHandler: onSkillsChangeHandler,
                      }}
                    />
                    <div>
                      <FormFieldCommon.Label content="Minimum Experience (Years)*" />
                      <Row>
                        <Col xs={6} md={6} xl={5}>
                          <FormFields.Input
                            {...jobCreateFields.experience_from}
                          />
                        </Col>
                        <Col xs={6} md={6} xl={5}>
                          <FormFields.Input
                            {...jobCreateFields.experience_to}
                          />
                        </Col>
                      </Row>
                    </div>
                    <Row>
                      <Col>
                        <FormFieldCommon.Label content="Compensation*" />
                        <Row>
                          <Col xs={6} md={6} xl={5}>
                            <FormFields.CurrencySelector
                              {...jobCreateFields.remuneration_from}
                            />
                          </Col>
                          <Col xs={6} md={6} xl={5}>
                            <FormFields.CurrencySelector
                              {...jobCreateFields.remuneration_to}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <FormFields.Dropdown
                      {...jobCreateFields.compensation_type}
                    />
                    <FormFields.Input {...jobCreateFields.department} />
                    {screen.md && Additional}
                  </Col>
                  <Col className="d-none d-lg-block" lg={1} />
                  <Col md={6} className="right-side">
                    <Row>
                      <Col>
                        <FormFields.RichTextArea
                          {...jobCreateFields.job_description}
                          minHeight={screen.md ? 700 : 300}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm={12}
                    className="left-side"
                    style={{ marginTop: "24px" }}
                  >
                    {!screen.md && Additional}
                  </Col>
                </Row>
                {_.add_qualifying_question && questionComponent}
              </CreateJobStyles.CreateJobFormContainer>
              <CreateJobStyles.NextButtonWrapper>
                <Buttons
                  label={isJobCreated ? "Update" : "Next"}
                  variant="primary"
                  isDisable={disableButton}
                  handleClick={nextClickHandler}
                  width="fit-content"
                />
                {createError && (
                  <FormFieldCommon.Error
                    content={`Error while creating job: ${createError}`}
                  />
                )}
              </CreateJobStyles.NextButtonWrapper>
            </CreateJobStyles.Container>
          );
        }}
      </Formik>
    </CreateJobStep1Context.Provider>
  );
};
