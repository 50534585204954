import { ColumnInstance } from "react-table";
import { ICONS } from "src/assets/custom_Icons";
import { TableProps } from "./Receipts";
import { Container, PaymentStatus } from "./ReceiptsStyle";
import { Typography } from "src/components/Basic";
import { Cards } from "src/assets/custom_Icons/cards";
import { API_CALLS } from "src/API_CALLS";

export const customColumnCell = (column: ColumnInstance) => {
  return ["Icon"].includes(column.Header as string)
    ? ""
    : column.render("Header");
};

const CardIcon = {
  visa: <Cards.Visa />,
  amex: <Cards.Amex />,
  discover: <Cards.Discover />,
  mastercard: <Cards.Mastercard />,
};

export const tableColumns: any = [
  { Header: "Campaign", accessor: "campaign_id" },
  { Header: "Date", accessor: "transaction_date" },
  { Header: "Amount", accessor: "amount" },
  {
    Header: "Payment Method",
    accessor: (value: TableProps.Receipt) => {
      return (
        <div className="d-flex gap-2">
          {CardIcon[value.card_type as keyof typeof CardIcon]}
          <Container>
            <Typography
              content={value.card_type + " • " + value.last4digits}
              color="black85"
              styleName="text1"
            />
          </Container>
        </div>
      );
    },
  },
  {
    Header: "Payment Status",
    accessor: (value: TableProps.Receipt) => {
      return (
        <PaymentStatus isPaid={value.status === "succeeded"}>
          {value.status === "succeeded" ? "paid" : "not paid"}
        </PaymentStatus>
      );
    },
  },
  {
    Header: "Icon",
    accessor: (value: TableProps.Receipt) =>
      value.status === "succeeded" ? (
        <div
          role="button"
          onClick={() =>
            API_CALLS.RECEIPTS.downloadReceipt(
              value.charge_id,
              value.fingerprint
            )
          }
        >
          <ICONS.DownloadIcon />
        </div>
      ) : (
        <></>
      ),
  },
];
export const tableColumnsDesktop: any = [
  { Header: "Campaign", accessor: "campaign_id" },
  { Header: "Date", accessor: "transaction_date" },
  { Header: "Amount", accessor: "amount" },
  {
    Header: "Payment Status",
    accessor: (value: TableProps.Receipt) => {
      return (
        <PaymentStatus isPaid={value.status === "succeeded"}>
          {value.status === "succeeded" ? "paid" : "not paid"}
        </PaymentStatus>
      );
    },
  },
  {
    Header: "Icon",
    accessor: (value: TableProps.Receipt) =>
      value.status === "succeeded" ? (
        <div
          role="button"
          onClick={() =>
            API_CALLS.RECEIPTS.downloadReceipt(
              value.charge_id,
              value.fingerprint
            )
          }
        >
          <ICONS.DownloadIcon />
        </div>
      ) : (
        <></>
      ),
  },
];

export const tableColumnsMobile: any = [
  {
    Header: "Campaign",
    accessor: (value: TableProps.Receipt) => {
      return (
        <Container>
          <span>{value.campaign_id}</span>
          <span className="d-flex gap-3">
            <span className="receipt-amount">{value.amount}</span>
            <PaymentStatus isPaid={value.status === "succeeded"}>
              {value.status === "succeeded" ? "paid" : "not paid"}
            </PaymentStatus>
          </span>
        </Container>
      );
    },
  },
  { Header: "Date", accessor: "transaction_date" },
  {
    Header: "Icon",
    accessor: (value: TableProps.Receipt) =>
      value.status === "succeeded" ? (
        <div
          role="button"
          onClick={() =>
            API_CALLS.RECEIPTS.downloadReceipt(
              value.charge_id,
              value.fingerprint
            )
          }
        >
          <ICONS.DownloadIcon />
        </div>
      ) : (
        <></>
      ),
  },
];
