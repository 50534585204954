import { Row } from "react-bootstrap";

import { CheckoutStyles } from "./Styles";
import { CheckoutSummary } from "./1-CheckoutSumary";
import { CheckoutCards } from "./2-CheckoutCards";
import { useAppSelector } from "src/Redux";

export const CheckOut = () => {
  const { isDarkTheme } = useAppSelector((store) => store.common);

  return (
    <CheckoutStyles.CheckoutContainer isDarkTheme={isDarkTheme}>
      <Row style={{ width: "100%", margin: 0 }}>
        <CheckoutSummary />
        <CheckoutCards />
      </Row>
    </CheckoutStyles.CheckoutContainer>
  );
};
