import { useEffect, useState } from "react";
import { Field, FieldProps, FormikValues } from "formik";
import { Editor, SyntheticEvent } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import "../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  getHTMLContentFromEditorState,
  getInitialContentState_WithHTML,
  getPlainTextFromEditorState,
  getRichTextAreaHasError,
} from "./richTextEditor.helpers";
import { FormFieldCommon } from "../../FormFieldsCommon";
import { FormFieldsProps } from "../../FormFields";
import { RichTextAreaContainer } from "./richTextAreaStyles";
import { richTextAreaUtils } from "./utils";

type RichTextAreaHasErrorProps = {
  formik: FormikValues;
  editorState: EditorState;
  fieldName: string;
  textCount?: number;
  textLimit?: number;
  description?: string;
};

export const RichTextArea = (props: FormFieldsProps.RichTextarea) => {
  const {
    fieldName,
    idPrefix,
    isRequired,
    label,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    minHeight = 300,
    customValidation,
    showLabel = true,
    showTextCount = false,
    defaultValue = "",
    textLimit,
  } = props;

  const [isFocused, setFocused] = useState(false);
  const [editorStateInitiated, setEditorStateInitiated] = useState(false);
  const editorStateContent = getInitialContentState_WithHTML("");
  const [editorState, setEditorState] = useState(editorStateContent);

  useEffect(() => {
    if (!defaultValue) return;
    const editorStateContent = getInitialContentState_WithHTML(defaultValue);
    setEditorState(EditorState.moveFocusToEnd(editorStateContent));
  }, [defaultValue]);

  return (
    <Field id={`${idPrefix}_${fieldName}`} name={fieldName} key={fieldName}>
      {({ form, meta }: FieldProps) => {
        const { touched, value = "", error } = meta;
        const { setFieldValue, setFieldTouched } = form;

        const htmlContent = getHTMLContentFromEditorState(editorState);
        const plainText = getPlainTextFromEditorState(editorState);
        const textCount = plainText.length;

        if (!editorStateInitiated && value) {
          const editorStateContent = getInitialContentState_WithHTML(value);
          setEditorState(EditorState.moveFocusToEnd(editorStateContent));
          setEditorStateInitiated(true);
        }

        //#region Errors
        //Error validation
        const richTextAreaHasErrorProps: RichTextAreaHasErrorProps = {
          formik: form,
          editorState,
          fieldName,
          textCount,
          textLimit,
        };

        //Default errors
        const errorConditions = isRequired && touched && !isFocused;
        const formikError = error ? true : false;
        const defaultError = getRichTextAreaHasError(richTextAreaHasErrorProps);
        let hasError = errorConditions && (formikError || defaultError);
        let errorLabel = error || `${label || fieldName} is required`;

        //textLimit errors
        const textLimitErrorConditions = isRequired
          ? isRequired && touched
          : touched;
        const isTextLimitExceeded = textLimit && textCount > textLimit;
        if (isTextLimitExceeded) {
          hasError = textLimitErrorConditions;
          errorLabel = `${label || fieldName} text limit exceeded`;
        }

        //Custom Validation
        if (customValidation?.validation && !hasError) {
          hasError = errorConditions && customValidation.validation(plainText);
          errorLabel = customValidation.validationMessage;
        }

        const showErrorLabel = hasError && errorLabel;
        //#endregion

        //#region Handlers
        const getHTMLContent = () => (textCount > 0 ? htmlContent : "");
        const updateFieldValue = () => {
          setFieldValue(fieldName, getHTMLContent());
          setFieldValue(fieldName + "_plain", plainText);
        };
        const handleBlur = (event: SyntheticEvent) => {
          event.preventDefault();
          setFieldTouched(fieldName, true);
          updateFieldValue();
          setFocused(false);
        };
        const onEditorStateChange = (EditorstateFromProps: EditorState) => {
          setEditorState(EditorstateFromProps);
        };
        //#endregion

        return (
          <RichTextAreaContainer
            containerWidth="100%"
            value={value}
            focus={isFocused}
            hasError={hasError}
            minHeight={minHeight}
          >
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <Editor
              onChange={updateFieldValue}
              placeholder={htmlContent.startsWith("<p") ? placeholder : ""}
              wrapperClassName={`description-editor ${
                isFocused && "description-editor--focus"
              }`}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              onFocus={() => setFocused(true)}
              stripPastedStyles={true}
              onBlur={handleBlur}
              toolbar={richTextAreaUtils.toolbar}
            />
            {showTextCount && (
              <FormFieldCommon.Label
                className="richtextarea-text-count"
                content={`${textCount}/${textLimit}`}
              />
            )}
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </RichTextAreaContainer>
        );
      }}
    </Field>
  );
};
