import { Grid } from "antd";
import clsx from "clsx";
import { Formik, FormikValues } from "formik";
import { useLocation } from "react-router-dom";

import { verifyFormFields, idPrefix } from "./utils";
import { Buttons, FormFields, Typography } from "src/components/Basic";
import { useAppSelector } from "src/Redux";
import { FormikHelpers } from "src/components/Basic/FormFields/FormikFields/FormikFields.helpers";
import { API_CALLS } from "src/API_CALLS";
import { VerifyStyle } from "./TeamManagementStyle";

const TeamMemberDetailForm = () => {
  const screen = Grid.useBreakpoint();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const { common, settings } = useAppSelector((state) => state);
  const { isDarkTheme } = common;
  const { unverifiedMemberDetails, teamManagementError } = settings;

  const onSubmitHandler = (values: FormikValues) => {
    API_CALLS.TEAM_OVERVIEW.addMemberCompany({
      token: token as string,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      password: values.password,
      verify_password: values.verify_password,
    });
  };
  return (
    <VerifyStyle.DetailsContainer>
      <div
        className={clsx("d-flex flex-column ", screen.lg ? "gap-2" : "gap-3")}
      >
        <Typography
          content={`Your Organization, ${unverifiedMemberDetails.company_name} invited you to join their team!`}
          styleName="subtitle3"
          color={isDarkTheme ? "grey_subtitle2" : "black100"}
        />
        <Typography
          content="Please confirm your Name and Surname below and set your password."
          styleName="text_13pt"
          color={isDarkTheme ? "grey_subtitle2" : "black100"}
        />
      </div>
      <div className="d-flex justify-content-center">
        <img
          src={`${process.env.REACT_APP_NOVA_BASE_URL}/static/company_images/${unverifiedMemberDetails.company_logo}`}
          alt=""
          width="200"
        />
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          first_name: unverifiedMemberDetails.first_name,
          last_name: unverifiedMemberDetails.last_name,
          email: unverifiedMemberDetails.email,
          password: "",
          verify_password: "",
        }}
        onSubmit={() => {}}
      >
        {(formik) => {
          return (
            <>
              <FormFields.Input {...verifyFormFields.first_name} />
              <FormFields.Input {...verifyFormFields.last_name} />
              <FormFields.Input {...verifyFormFields.email} />
              <FormFields.Password {...verifyFormFields.password} />
              <FormFields.Password {...verifyFormFields.verify_password} />
              {teamManagementError && (
                <VerifyStyle.ErrorLabel>
                  {teamManagementError}
                </VerifyStyle.ErrorLabel>
              )}
              <VerifyStyle.ButtonContainer>
                <Buttons
                  handleClick={() => {
                    FormikHelpers.formikSubmitHandler({
                      formFields: verifyFormFields,
                      formik,
                      onSubmitHandler,
                      idPrefix,
                    });
                  }}
                  label="Join"
                  variant="primary"
                />
              </VerifyStyle.ButtonContainer>
            </>
          );
        }}
      </Formik>
    </VerifyStyle.DetailsContainer>
  );
};

export default TeamMemberDetailForm;
