import styled from "styled-components";

import { theme } from "src/components/Theme";

export const CandidateTabHeaderContainer = styled.div`
  display: flex;
  gap: 15px;
  width: fit-content;
  background: ${theme.colors.black150};
  padding: 13px 32px 13px 29px;
  border-radius: 10px;
  margin: 0px 12px;
  @media (min-width: 1440px) {
    gap: 32px;
    margin: 0px 30px;
  }
  @media (max-width: 991px) {
    display: none;
  }
  .dark-theme & {
    background: ${theme.colors.black170};
  }
`;
export const CountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  padding-top: 1px; 
  width: 15px;
  border-radius: 100%;
  color: ${theme.colors.white100};
  background: ${theme.colors.primary_default};
  ${theme.text.pipelineNavCount}
`;

export const NavbarHeadingContainer = styled.div`
  display: flex;
  gap: 6px;
  cursor: pointer;
  label {
    cursor: pointer;
  }
  .dark-theme & label {
    color: ${theme.colors.white10};
  }
  &.not-show {
    display: none;
  }
`;
