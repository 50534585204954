import { useContext } from "react";
import styled from "styled-components";
import { DateRangePicker, DefinedRange, RangeKeyDict } from "react-date-range";

import { theme } from "src/components/Theme";
import { useAppSelector } from "src/Redux";
import { AppContext } from "src/App";
import { DatepickerButtons } from "./DatepickerButtons";
import { LinearChartContext } from "..";

export const DatePicker = () => {
  const { range, rangeChangeHandler } = useContext(LinearChartContext);
  const { isSmallTabletOrSmall, isTablet } = useContext(AppContext);
  const { isDarkTheme } = useAppSelector((store) => store.common);

  const clickHandler = (item: RangeKeyDict) => {
    rangeChangeHandler(item[range.key || "selection"]);
  };

  return (
    <Container className={isDarkTheme ? "dark-theme" : ""}>
      {!isSmallTabletOrSmall && (
        <DateRangePicker
          onChange={clickHandler}
          showPreview={true}
          moveRangeOnFirstSelection={false}
          months={isTablet ? 1 : 2}
          ranges={[range]}
          direction="horizontal"
          maxDate={new Date()}
          calendarFocus="backwards"
        />
      )}
      {isSmallTabletOrSmall && (
        <MobileContainer>
          <DefinedRange onChange={clickHandler} ranges={[range]} />
        </MobileContainer>
      )}
      <DatepickerButtons />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 66px;
  right: 26px;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  background: ${theme.colors.white_pure};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  @media (min-width: 768px) {
    top: 58px;
  }
  .dark-theme & {
    background: ${theme.colors.black110};
  }
`;
const MobileContainer = styled.div`
  .dark-theme & {
    .rdrDefinedRangesWrapper {
      border-radius: 3px;
      border: 1px solid ${theme.colors.black130};
    }
  }
`;
