import { IconContainer } from "../JobStyle";
import { IJobs } from "../Job";
import { JobColumn } from "./JobColumn";
import { OptionsColumn } from "./OptionsColumn";
import { CampaignStatus } from "./CampaignStatus";
import { View } from "./View";
import { Default } from "./Default";

export const customRowCell = (props: IJobs.CustomCell) => {
  const { column, value } = props.cell;

  switch (column.Header) {
    case "UserPhoto":
      return (
        <IconContainer
          className="column"
          src={`data:image/png;base64,${value}`}
        />
      );
    case "Job":
      return <JobColumn className="column job-column" {...props} />;
    case "Campaign Status":
      return <CampaignStatus className="column job-column" {...props} />;
    case "User":
    case "View":
      return <View className="column job-column" {...props} />;
    case "Options":
      return <OptionsColumn className="column" {...props} />;
    default:
      return <Default className="column job-column" {...props} />;
  }
};
