import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const LinksAndMessages = () => (
  <>
    <Points.Heading pointNumber="9." heading="Links and Messages" />
    <Points.Indent>
      <>
        <POINT_9_1 />
        <POINT_9_2 />
        <POINT_9_3 />
      </>
    </Points.Indent>
  </>
);

//#region 9.1
const POINT_9_1 = () => (
  <>
    <Points.SubHeading
      pointNumber="9.1."
      heading={`Data Messages between You and Ditto Media`}
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="9.1.1." Content={POINT_9_1_1} />
        <Points.Span2 pointNumber="9.1.2." Content={POINT_9_1_2} />
        <Points.Span2 pointNumber="9.1.3." Content={POINT_9_1_3} />
        <Points.Span2 pointNumber="9.1.4." Content={POINT_9_1_4} />
      </>
    </Points.Indent>
  </>
);
const POINT_9_1_1 = (
  <Points.Content>
    <Text.Span
      content={`Data messages, including email messages, you send to us will be considered as received only when we acknowledge or responded to these messages.`}
    />
  </Points.Content>
);
const POINT_9_1_2 = (
  <Points.Content>
    <Text.Span
      content={`Data messages we send to you will be regarded as received when the data message enters your email sever inbox and is capable of being retrieved and processed by you.`}
    />
  </Points.Content>
);
const POINT_9_1_3 = (
  <Points.Content>
    <Text.Span
      content={`We reserve the right not to respond to any email or other data message that contains obscene, threatening, defamatory or otherwise illegal, unlawful, or inappropriate content, and to take appropriate action against the sender of such email or data message if necessary.`}
    />
  </Points.Content>
);
const POINT_9_1_4 = (
  <Points.Content>
    <Text.Span
      content={`Messages sent over the internet cannot be guaranteed to be completely secure as they can be intercepted, lost, or corrupted. We are therefore not responsible for the accuracy or safety of any message sent by email or over the internet.`}
    />
  </Points.Content>
);
//#endregion

//#region 9.2
const POINT_9_2 = () => (
  <>
    <Points.SubHeading
      pointNumber="9.2."
      heading={`Hyperlinks, Deep Links, Framing`}
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="9.2.1." Content={POINT_9_2_1} />
        <Points.Span2 pointNumber="9.2.2." Content={POINT_9_2_2} />
        <Points.Span2 pointNumber="9.2.3." Content={POINT_9_2_3} />
      </>
    </Points.Indent>
  </>
);
const POINT_9_2_1 = (
  <Points.Content>
    <Text.Span content={`Nova may include links to other websites (`} />
    <Text.Bold content={`"other sites"`} />
    <Text.Span
      content={`). Ditto Media does not own or endorse these other sites and is not responsible for the information, material, products, or services contained on or accessible through these other sites. Any hyperlinks do not imply any endorsement, agreement on or support of the content or products of these other sites.`}
    />
  </Points.Content>
);
const POINT_9_2_2 = (
  <Points.Content>
    <Text.Span
      content={`Ditto Media does not own the content on any other site which may be shown on Nova. Should the owner of any content showcased on Nova want the content to be removed, please contact us to request the removal of such content.`}
    />
  </Points.Content>
);
const POINT_9_2_3 = (
  <Points.Content>
    <Text.Span
      content={`Your access and use of the other sites remain solely at your own risk and on the terms set by the operator of any other site.`}
    />
  </Points.Content>
);
//#endregion

//#region 9.3
const POINT_9_3 = () => (
  <>
    <Points.SubHeading
      pointNumber="9.3."
      heading={`Advertising And Sponsorships on Nova`}
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="9.3.1." Content={POINT_9_3_1} />
        <Points.Span2 pointNumber="9.3.2." Content={POINT_9_3_2} />
      </>
    </Points.Indent>
  </>
);
const POINT_9_3_1 = (
  <Points.Content>
    <Text.Span
      content={`Nova may contain advertising and sponsorship. Advertisers and sponsors are responsible for ensuring that material displayed on Nova complies with all applicable laws and regulations. Displayed advertising does not indicate any endorsement, agreement on or support of the content or products of these advertisements by Ditto Media.`}
    />
  </Points.Content>
);
const POINT_9_3_2 = (
  <Points.Content>
    <Text.Span
      content={`Ditto Media, its shareholders, directors, employees, partners, affiliates, and agents accordingly exclude, to the maximum extent permitted in law, any responsibility or liability for any error or inaccuracy appearing in advertising or sponsorship material.`}
    />
  </Points.Content>
);
//#endregion
