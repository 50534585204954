import { useAppSelector } from "src/Redux";

const MoonRoketIcon = () => {
  const { isDarkTheme } = useAppSelector((state) => state.common);

  return (
    <svg
      width="450"
      height="955"
      viewBox="0 0 450 955"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_0_1)">
        <rect width="450" height="955" fill="#323251" />
        <path
          d="M-24 877C80.9341 877 166 791.934 166 687C166 582.066 80.9341 497 -24 497C-128.934 497 -214 582.066 -214 687C-214 791.934 -128.934 877 -24 877Z"
          fill={isDarkTheme ? "#4a4a69" : "white"}
        />
        <path
          d="M265.581 914.06C420.369 914.06 545.851 788.578 545.851 633.79C545.851 479.001 420.369 353.52 265.581 353.52C110.792 353.52 -14.6895 479.001 -14.6895 633.79C-14.6895 788.578 110.792 914.06 265.581 914.06Z"
          fill={isDarkTheme ? "#4a4a69" : "white"}
        />
        <path
          d="M631.59 964.56C833.738 964.56 997.61 800.687 997.61 598.54C997.61 396.392 833.738 232.52 631.59 232.52C429.443 232.52 265.57 396.392 265.57 598.54C265.57 800.687 429.443 964.56 631.59 964.56Z"
          fill={isDarkTheme ? "#4a4a69" : "white"}
        />
      </g>
      <path
        d="M165.811 421C265.498 421 346.311 337.725 346.311 235C346.311 132.275 265.498 49 165.811 49C66.1231 49 -14.6895 132.275 -14.6895 235C-14.6895 337.725 66.1231 421 165.811 421Z"
        fill="#7A839B"
      />
      <path
        d="M118.92 521.641L107.531 604.037L143.953 561.196L176.538 567.724L155.684 507.7L118.92 521.641Z"
        fill="#24D5B8"
      />
      <path
        d="M334.08 521.641L345.468 604.037L309.047 561.196L276.455 567.724L297.316 507.7L334.08 521.641Z"
        fill="#24D5B8"
      />
      <ellipse
        cx="226.78"
        cy="577.357"
        rx="18.5678"
        ry="85.2315"
        fill="#FFD747"
      />
      <path
        d="M226.249 297.363C184.089 297.363 149.917 379.693 149.917 481.252C149.917 519.501 164.232 555.016 172.526 584.437H279.964C288.258 555.016 302.573 519.501 302.573 481.252C302.573 379.693 268.401 297.363 226.249 297.363Z"
        fill="#1E96F2"
      />
      <path
        d="M226.074 440.9C241.573 440.9 254.137 424.337 254.137 403.906C254.137 383.474 241.573 366.912 226.074 366.912C210.575 366.912 198.011 383.474 198.011 403.906C198.011 424.337 210.575 440.9 226.074 440.9Z"
        fill="#0C5E9D"
      />
      <path
        d="M166.803 565.49C168.849 572.048 170.815 578.373 172.526 584.437H279.964C281.675 578.373 283.641 572.04 285.687 565.49H166.803Z"
        fill="#68BCFC"
      />
      <g clip-path="url(#clip1_0_1)">
        <path
          d="M237.506 505.669C237.506 499.401 233.625 466.687 227.48 464.743C226.875 464.496 226.205 464.496 225.594 464.743C219.426 466.593 215.538 499.387 215.538 505.669C213.659 533.639 213.062 548.77 216.601 570.067C219.295 586.287 222.819 602.579 226.409 605.451V605.611C226.409 605.611 226.482 605.553 226.511 605.531C226.548 605.56 226.584 605.589 226.613 605.611V605.451C230.203 602.586 233.727 586.294 236.421 570.067C239.96 548.77 239.363 533.639 237.484 505.669H237.506Z"
          fill="#24D5B8"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_1">
          <rect width="450" height="955" fill="white" />
        </clipPath>
        <clipPath id="clip1_0_1">
          <rect
            width="24.9682"
            height="141.049"
            fill="white"
            transform="translate(214.038 464.562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MoonRoketIcon;
