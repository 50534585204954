import { AUTH } from "./AUTH";
import { JOBS } from "./JOBS";
import { COMMON } from "./COMMON";
import { COMPANY_SETTINGS } from "./COMPANY_SETTINGS";
import { TEAM_OVERVIEW } from "./TEAM_OVERVIEW";
import { CANDIDATE_SETTINGS } from "./CANDIDATE_SETTINGS";
import { STRIPE } from "./STRIPE";
import { RECEIPTS } from "./RECEIPTS";
import { EMAIL_NOTIFICATION } from "./EMAIL_NOTIFICATION";
import { MESSAGE_TEMPLATE } from "./MESSAGE_TEMPLATE";
import { PIPELINE } from "./PIPELINE";
import { ONBOARDING } from "./ONBOARDING";
import { DASHBOARD } from "./DASHBOARD";
import { REPORTS } from "./REPORTS";
import { REPORTS_CARD_GROUP } from "./REPORTS_CARD_GROUP";
import { NOTIFICATION } from "./NOTIFICATION";
import { CAMPAIGN } from "./CAMPAIGN";

export const API_CALLS = {
  AUTH,
  JOBS,
  COMMON,
  COMPANY_SETTINGS,
  TEAM_OVERVIEW,
  CANDIDATE_SETTINGS,
  MESSAGE_TEMPLATE,
  STRIPE,
  RECEIPTS,
  EMAIL_NOTIFICATION,
  ONBOARDING,
  PIPELINE,
  DASHBOARD,
  REPORTS,
  REPORTS_CARD_GROUP,
  NOTIFICATION,
  CAMPAIGN,
};
