import styled from "styled-components";
import { Link } from "react-router-dom";

import { ICONS } from "src/assets/custom_Icons";
import { ROUTES } from "src/utils/ROUTES";

const DesktopLogo = () => {
  return (
    <LogoContainer className="main-sidebar-header active">
      <Link className="header-logo active" to={ROUTES.HOME}>
        <div className="main-logo  desktop-logo">
          <ICONS.NovaBlack />
        </div>

        <div className="main-logo  desktop-dark">
          <ICONS.NovaWhite />
        </div>
      </Link>
    </LogoContainer>
  );
};
export default DesktopLogo;
export const LogoContainer = styled.div`
  height: 64px;
  padding: 8px 20px;
  width: 240px;
  background: #fff;
  border-bottom: 1px solid #ededf5;
  border-right: 0px !important;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  -webkit-transition: left 320ms ease, width 320ms ease;
  -o-transition: left 320ms ease, width 320ms ease;
  transition: left 320ms ease, width 320ms ease;
  outline: none;
  position: unset;
`;
