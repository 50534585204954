import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";

import { theme } from "../../Theme";
import { IButton, Variants, variantProperties } from "./Buttons";
import "./Buttons.css";

const Buttons: React.FC<IButton.IProps> = ({
  variant,
  label,
  handleClick,
  isDisable = false,
  width,
}) => {
  const variantData: Variants = {
    primary: {
      className: "btn-primary",
      backgroundhovercolor: theme.colors.primary_hover,
      backgrounddisablecolor: theme.colors.primary_disable,
      backgroundcolor: theme.colors.primary_default,
    },
    secondary: {
      className: "btn-secondary",
      backgroundhovercolor: theme.colors.secondary_hover,
      backgroundcolor: theme.colors.secondary_default,
      backgrounddisablecolor: theme.colors.secondary_disable,
    },
    success: {
      className: "btn-success",
      backgroundhovercolor: theme.colors.success_hover,
      backgroundcolor: theme.colors.success_default,
      backgrounddisablecolor: theme.colors.success_disable,
    },
    info: {
      className: "btn-info",
      backgroundhovercolor: theme.colors.info_hover,
      backgroundcolor: theme.colors.info_default,
      backgrounddisablecolor: theme.colors.info_disable,
    },
    warning: {
      className: "btn-warning",
      backgroundhovercolor: theme.colors.warning_hover,
      backgroundcolor: theme.colors.warning_default,
      backgrounddisablecolor: theme.colors.warning_disable,
    },
    danger: {
      className: "btn-danger",
      backgroundhovercolor: theme.colors.danger_hover_click,
      backgroundcolor: theme.colors.danger_default,
      backgrounddisablecolor: theme.colors.danger_disable,
    },
    light: {
      className: "btn-light",
      backgroundhovercolor: theme.colors.light_hover,
      backgroundcolor: theme.colors.light_default,
      backgrounddisablecolor: theme.colors.light_disable,
    },
    select: {
      className: "btn-select",
      backgroundhovercolor: theme.colors.primary_default,
      backgroundcolor: "transparent",
      backgrounddisablecolor: theme.colors.light_disable,
      bordercolor: theme.colors.primary_default,
    },
    addPromotion: {
      className: "btn-add-promotion",
      backgroundhovercolor: `rgba(30, 150, 242, 0.25)`,
      backgroundcolor: `rgba(30, 150, 242, 0.25)`,
      backgrounddisablecolor: theme.colors.light_disable,
      bordercolor: theme.colors.primary_default,
    },
  };

  return (
    <ButtonContainer
      disabled={isDisable}
      {...variantData[variant]}
      variant=""
      className={` ${variantData[variant].className} ${
        isDisable ? "disabled" : ""
      }`}
      onClick={() => !isDisable && handleClick()}
      {...{ width }}
    >
      {label}
    </ButtonContainer>
  );
};

const ButtonContainer = styled(Button)<
  {
    backgroundhovercolor: string;
    backgrounddisablecolor: string;
    disabled: boolean;
    backgroundcolor: string;
  } & variantProperties
>`
  white-space: nowrap;
  background: ${({ backgrounddisablecolor, isDisable, backgroundcolor }) =>
    isDisable ? backgrounddisablecolor : backgroundcolor} !important;
  ${({ width }) => width && `width:${width}`};
  &:hover {
    background: ${({ backgroundhovercolor }) =>
      backgroundhovercolor} !important;
  }
  cursor: ${({ isDisable }) =>
    isDisable ? "not-allowed" : "pointer"} !important;
`;
export { Buttons };
