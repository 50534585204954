import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const Introduction = () => (
  <>
    <Points.Heading pointNumber="1." heading="Introduction" />
    <Points.Indent>
      <>
        <Points.Span pointNumber="1.1." Content={POINT_1_1} />
        <Points.Span pointNumber="1.2." Content={POINT_1_2} />
        <Points.Span pointNumber="1.3." Content={POINT_1_3} />
        <Points.Span pointNumber="1.4." Content={POINT_1_4} />
        <Points.Span pointNumber="1.5." Content={POINT_1_5} />
      </>
    </Points.Indent>
  </>
);

const POINT_1_1 = (
  <Points.Content>
    <Text.Span
      content={`Ditto Media provides a marketplace platform that acts as a job portal and media platform connecting jobseekers from Ditto Jobs (“`}
    />
    <Text.Bold content={`Jobseekers`} />
    <Text.Span
      content={`”) with employers using Nova to list and advertise available job listings within their company (“`}
    />
    <Text.Bold content={`Employers`} />
    <Text.Span content={`”) (collectively, the “`} />
    <Text.Bold content={`Services`} />
    <Text.Span content={`”).`} />
  </Points.Content>
);
const POINT_1_2 = (
  <Points.Content>
    <Text.Span content={`By accessing or using `} />
    <Text.Link content="https://www.nova" />
    <Text.Span
      content={` or any related platform or application (collectively, “Nova”), owned by Ditto Media Company (“`}
    />
    <Text.Bold content={`Ditto Media`} />
    <Text.Span
      content={`”) or any of its Services, you agree that you have read, understood, and agree to be bound to the terms and conditions contained herein (“`}
    />
    <Text.Bold content={`Terms`} />
    <Text.Span
      content={`). All rights in and to the content of Nova always remain expressly reserved by Ditto Media Company.`}
    />
  </Points.Content>
);
const POINT_1_3 = (
  <Points.Content>
    <Text.Span
      content={`These Terms explain the conditions applicable to how you will use Nova. Please read these Terms carefully before using Nova or our Services. We will assume you have read and understood these Terms if you continue to access or make use of our platform.`}
    />
  </Points.Content>
);
const POINT_1_4 = (
  <Points.Content>
    <Text.Span content={`Please pay specific attention to the`} />
    <Text.Bold content={` BOLD paragraphs `} />
    <Text.Span
      content={`of these Terms. These paragraphs limit the risk or liability of Ditto Media, constitutes an assumption of risk or liability by you, impose an obligation by you to indemnify Ditto Media or is an acknowledgement of any fact by you.`}
    />
  </Points.Content>
);
const POINT_1_5 = (
  <Points.Content>
    <Text.Span
      content={`The terms "user", “you" and “your” are used interchangeably in these Terms and refer to all persons accessing Nova or using the Services for any reason whatsoever. Accordingly, the terms “us”, “our” or “we” refers to Ditto Media or its possession.`}
    />
  </Points.Content>
);
