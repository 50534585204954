export const InsufficientData = () => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.82 53.8201C48.6918 57.7291 42.4473 59.8947 36 60.0001C15 60.0001 3 36.0001 3 36.0001C6.73167 29.0458 11.9074 22.9699 18.18 18.1801M29.7 12.7201C31.765 12.2367 33.8792 11.9951 36 12.0001C57 12.0001 69 36.0001 69 36.0001C67.1789 39.4069 65.0072 42.6143 62.52 45.5701M42.36 42.3601C41.5361 43.2443 40.5424 43.9535 39.4385 44.4454C38.3345 44.9373 37.1427 45.2019 35.9343 45.2232C34.7258 45.2445 33.5255 45.0222 32.4048 44.5695C31.2842 44.1169 30.2662 43.4432 29.4115 42.5885C28.5569 41.7339 27.8832 40.7159 27.4305 39.5952C26.9779 38.4746 26.7556 37.2742 26.7769 36.0658C26.7982 34.8574 27.0627 33.6656 27.5546 32.5616C28.0465 31.4576 28.7558 30.464 29.64 29.6401"
      stroke="#4EC2F0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 3L69 69"
      stroke="#4EC2F0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
