import { useEffect, useMemo, useState } from "react";
import { Grid } from "antd";
import clsx from "clsx";

import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { Typography } from "src/components/Basic";
import { notificataIcons } from "./utils";
import {
  Container,
  NotificationDetail,
  NotificationPageContainer,
  PaginationButton,
} from "./NotificationStyle";

const Notification = () => {
  const { isDarkTheme } = useAppSelector((state) => state.common);
  const { notificationList } = useAppSelector((state) => state.notification);
  const dispatch = useAppDispatch();
  const screen = Grid.useBreakpoint();
  const [page, setPage] = useState(1);
  const notification_start = page * 10 - 10;
  const notification_end = page * 10;

  useEffect(() => {
    dispatch(actions.common.setPageLoading(false));
  }, []);

  const notificationPagewise = useMemo(() => {
    return notificationList.slice(notification_start, notification_end);
  }, [page, notificationList]);

  return (
    <NotificationPageContainer>
      <Typography
        content="NOTIFICATION"
        styleName="heading5"
        color={isDarkTheme ? "black10" : "black80"}
      />
      <Container>
        <div className={clsx("d-flex mb-3", !screen.lg && "ms-1")}>
          <Typography
            content="Notifications"
            styleName="termsTitle"
            color={isDarkTheme ? "grey_subtitle1" : "black80"}
            className="dropdown-title"
          />
          <span className="badge badge-pill badge-info ms-auto my-auto float-end  py-1 px-2">
            <Typography
              content="Mark All Read"
              styleName="activityDateText"
              color="white_pure"
            />
          </span>
        </div>
        <Typography
          content="View all your notifications here."
          styleName="text1"
          color={isDarkTheme ? "grey_subtitle2" : "black70"}
          className={clsx(!screen.lg && "ms-1")}
        />
        <NotificationDetail>
          {notificationPagewise.map(({ icon, date, text }) => (
            <div
              className={clsx(
                "d-flex justify-content-between align-items-start",
                screen.lg ? "gap-3" : "gap-2"
              )}
            >
              <div className="d-flex gap-3 align-items-start">
                <div>{notificataIcons[icon]}</div>
                <h5
                  className="notification-label mb-0"
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </div>
              <div className="notification-subtext">{date}</div>
            </div>
          ))}
        </NotificationDetail>
        <div className="d-flex justify-content-end mt-4 mx-4 align-items-center">
          <div className="page-notification-count">{`${
            notification_start + 1
          }-${
            notificationPagewise.length === 10
              ? notification_end
              : notificationList.length
          } of ${notificationList.length}`}</div>
          <PaginationButton
            className={clsx(page > 1 ? "page-enable" : "page-disable")}
            variant=""
            onClick={() => {
              setPage(1);
            }}
            disabled={page === 1}
          >
            {" |< "}
          </PaginationButton>
          <PaginationButton
            className={clsx(page > 1 ? "page-enable" : "page-disable")}
            variant=""
            onClick={() => {
              setPage(page - 1);
            }}
            disabled={page === 1}
          >
            {" < "}
          </PaginationButton>
          <PaginationButton
            className={clsx(
              Math.ceil(notificationList.length / 10) === page
                ? "page-disable"
                : "page-enable"
            )}
            variant=""
            onClick={() => {
              setPage(page + 1);
            }}
            disabled={Math.ceil(notificationList.length / 10) === page}
          >
            {" > "}
          </PaginationButton>
          <PaginationButton
            className={clsx(
              Math.ceil(notificationList.length / 10) === page
                ? "page-disable"
                : "page-enable"
            )}
            variant=""
            onClick={() => {
              setPage(Math.ceil(notificationList.length / 10));
            }}
            disabled={Math.ceil(notificationList.length / 10) === page}
          >
            {" >| "}
          </PaginationButton>
        </div>
      </Container>
    </NotificationPageContainer>
  );
};

export default Notification;
