import * as dateFn from "date-fns";

const today = new Date();

const startOfToday = dateFn.startOfDay(today).getTime();
const endOfToday = dateFn.endOfDay(today).getTime();
const startOfYesterday = dateFn.startOfDay(dateFn.addDays(today, -1)).getTime();
const endOfYesterday = dateFn.endOfDay(dateFn.addDays(today, -1)).getTime();
const startOfWeek = dateFn.startOfWeek(today).getTime();
const endOfWeek = dateFn.endOfWeek(today).getTime();
const startOfLastWeek = dateFn.startOfWeek(dateFn.addDays(today, -7)).getTime();
const endOfLastWeek = dateFn.endOfWeek(dateFn.addDays(today, -7)).getTime();
const startOfMonth = dateFn.startOfMonth(today).getTime();
const endOfMonth = dateFn.endOfMonth(today).getTime();
const startOfLastMonth = dateFn
  .startOfMonth(dateFn.addMonths(today, -1))
  .getTime();
const endOfLastMonth = dateFn.endOfMonth(dateFn.addMonths(today, -1)).getTime();

const addLeadingZero = (value: number) => (value < 10 ? `0${value}` : value);
const getDateInAPIFormat = (date: Date | null | undefined) => {
  if (!date) return "";

  const year = date.getFullYear();
  const month = addLeadingZero(date.getMonth() + 1);
  const day = addLeadingZero(date.getDate());

  return `${year}-${month}-${day}`;
};
const getDateFromAPIFormat = (date: string) => {
  if (!date) return;
  return new Date(date);
};
const getStartOfMonth = () => getDateInAPIFormat(new Date(startOfMonth));
const getEndOfMonth = () => getDateInAPIFormat(new Date(endOfMonth));

export const DATE = {
  startOfToday,
  endOfToday,
  startOfYesterday,
  endOfYesterday,
  startOfWeek,
  endOfWeek,
  startOfLastWeek,
  endOfLastWeek,
  startOfMonth,
  endOfMonth,
  startOfLastMonth,
  endOfLastMonth,
  getDateInAPIFormat,
  getDateFromAPIFormat,
  getStartOfMonth,
  getEndOfMonth,
};
