import { API_INSTANCES } from "src/API_INSTANCES";
import { actions } from "src/Redux/slices";
import store from "src/Redux";
import { ERROR_CODES } from "./utils";

const dispatch = store.dispatch;

const getCardGroupData = () => {
  Promise.all([
    API_INSTANCES.REPORTS.getActiveJobs(),
    API_INSTANCES.REPORTS.getUnreadCandidates(),
    API_INSTANCES.REPORTS.getJobViews(),
    API_INSTANCES.REPORTS.getApplications(),
  ])
    .then(([active_jobs, unread, views, applications]) => {
      dispatch(
        actions.reports.setCardGroupData({
          active_jobs: active_jobs.data.active_jobs,
          unread: unread.data.unread,
          views: views.data.views,
          applications: applications.data.applications,
        })
      );
    })
    .catch((error) => {
      const status_code = error.response?.status;
      if (status_code === ERROR_CODES.NO_RESULTS_ERROR) {
        dispatch(actions.reports.setInitialCardGroupData());
      }
    })
    .finally(() => {
      dispatch(actions.reports.setCardGroupAPICallsCompleted(true));
    });
};

export const REPORTS_CARD_GROUP = { getCardGroupData };
