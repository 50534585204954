const SettingsWarningIcon = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <g clipPath="url(#clip0_714_1038)">
          <path
            d="M10 1.66658C5.39764 1.66658 1.66668 5.39754 1.66668 9.99992C1.66668 14.6023 5.39764 18.3333 10 18.3333C14.6024 18.3333 18.3333 14.6023 18.3333 9.99992C18.3333 5.39754 14.6024 1.66658 10 1.66658Z"
            stroke="#4EC2F0"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 13.3333L10 9.99992"
            stroke="#4EC2F0"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 6.66675L9.99167 6.66675"
            stroke="#4EC2F0"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_714_1038">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(20 20) rotate(-180)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  };
  
  export default SettingsWarningIcon;