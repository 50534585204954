import { createContext, useEffect, useState } from "react";

import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { LinearChart } from "./LinearChart";
import { ReportsPageProps } from "../Reports";
import { API_CALLS } from "src/API_CALLS";
import { HELPERS } from "src/Common";

export const LinearChartContext = createContext(
  {} as ReportsPageProps.LinearChartContext
);

const getDatesFromRange = (range: ReportsPageProps.DatepickerRange) => {
  const start_date = HELPERS.DATE.getDateInAPIFormat(range.startDate);
  const end_date = HELPERS.DATE.getDateInAPIFormat(range.endDate);

  return { start_date, end_date };
};

export const LinearCharts = ({
  type,
  setHeight = false,
}: ReportsPageProps.LinearCharts) => {
  const { reports } = useAppSelector((store) => store);
  const dispatch = useAppDispatch();

  const reportAction = actions.reports;
  const data: ReportsPageProps.LinearChartData = {
    views: {
      title: "Views",
      requestAction: reportAction.setViewsChartRequest,
      response: reports.viewsChartResponse,
      apiCall: API_CALLS.REPORTS.getViewsChart,
      requestData: reports.viewsChartRequest,
      errorCode: reports.viewsChartErrorCode,
    },
    applications: {
      title: "Applications",
      requestAction: reportAction.setApplicationsChartRequest,
      response: reports.applicationsChartResponse,
      apiCall: API_CALLS.REPORTS.getApplicationsChart,
      requestData: reports.applicationsChartRequest,
      errorCode: reports.applicationsChartErrorCode,
    },
    "jobs-created": {
      title: "Jobs Created",
      requestAction: reportAction.setJobsCreatedChartRequest,
      response: reports.jobsCreatedChartResponse,
      apiCall: API_CALLS.REPORTS.getJobsCreatedChart,
      requestData: reports.jobsCreatedChartRequest,
      errorCode: reports.jobsCreatedChartErrorCode,
    },
  };
  const { requestAction, apiCall, requestData } = data[type];
  const range: ReportsPageProps.DatepickerRange = {
    startDate: HELPERS.DATE.getDateFromAPIFormat(requestData.start_date),
    endDate: HELPERS.DATE.getDateFromAPIFormat(requestData.end_date),
    key: "selection",
  };

  //#region States
  const [initialAPICallCompleted, setInitialAPICallCompleted] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  //#endregion

  //#region UseEffects
  useEffect(() => {
    if (initialAPICallCompleted) return;
    apiCall(requestData);
    setInitialAPICallCompleted(true);
  }, [initialAPICallCompleted]);
  //#endregion

  //#region Handlers
  const rangeChangeHandler = (newRange: ReportsPageProps.DatepickerRange) => {
    dispatch(requestAction(getDatesFromRange(newRange)));
  };
  //#endregion

  return (
    <LinearChartContext.Provider
      value={{
        range,
        rangeChangeHandler,
        type,
        data,
        setHeight,
        showDatePicker,
        setShowDatePicker,
      }}
    >
      <LinearChart />
    </LinearChartContext.Provider>
  );
};
