import styled from "styled-components";

import { Description } from "./0-Description";
import { WhoWeAre } from "./1-WhoWeAre";
import { WhatWeCollect } from "./2-WhatWeCollect";
import { HowWeCollect } from "./3-HowWeCollect";
import { HowWeUse } from "./4-HowWeUse";
import { PurposeOfUse } from "./5-PurposeOfUse";
import { DisclosureOfData } from "./6-DisclosureOfData";
import { Cookies } from "./7-Cookies";
import { InternationalTransfers } from "./8-InternationalTransfers";
import { DataSecurity } from "./9-DataSecurity";
import { DataRetention } from "./10-DataRetention";
import { YourLegalRights } from "./11-YourLegalRights";
import { Glossary } from "./12-Glossary";

const content = `Nova (Pty) Ltd. (reg: 2023/000000/00) (“ Nova ”) adheres to the highest standards of protecting your personal information when we process it by virtue of your use of our Services, your use of our website https://www.nova/ or any of its related blogs, websites, applications or platforms (collectively, “the Website”), or by providing us with your personal information in any other way (such as emailing us or telephoning us). As such, we have created this specific and detailed Privacy Policy for you to read and appreciate exactly how we safeguard your personal information and respect your privacy (“Policy”).`;
const normalPoints = `
  Please note that Ditto Jobs is a private limited liability company duly registered and operating in accordance with the laws of South Africa.
For more information regarding your personal information lawfully stored or used by Ditto Jobs, please contact legal@dittohire.com who will gladly assist.
Not all terms are necessarily defined in order.
Please ensure that you read all the provisions below, and our other Ditto Jobs rules and policies which may apply from time to time and made available to you, to understand all of your, and our, rights and duties.
Please use the following links to jump to the relevant sections described in this Privacy Policy:
  `;
const titleLinks = `Important information and who we are
The data we collect about you
How is your personal data collected
How we use your personal data
Disclosures of your personal data
International transfers
Data security
Data retention
Your legal rights
Glossary`;

export { content, normalPoints, titleLinks };

export const PrivacyPolicy = () => {
  return (
    <Container>
      <Description />
      <WhoWeAre />
      <WhatWeCollect />
      <HowWeCollect />
      <HowWeUse />
      <PurposeOfUse />
      <DisclosureOfData />
      <Cookies />
      <InternationalTransfers />
      <DataSecurity />
      <DataRetention />
      <YourLegalRights />
      <Glossary />
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
