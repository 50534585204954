import { theme } from "src/components/Theme";
import styled from "styled-components";

const Container = styled.div`
  padding: 20px 10px 24px 10px;
  @media (min-width: 768px) {
    padding: 20px;
    padding-bottom: 56px;
  }
`;

//#region Index
const PageContainer = styled.div`
  background: ${theme.colors.page_background};
  padding: 0 8px 20px 8px;

  @media screen and (min-width: 768px) {
    padding: 0 20px 60px 20px;
  }
  @media (min-width: 992px) {
    padding: 0 20px 94px 20px;
  }
  @media (min-width: 1350px) {
    padding: 0 125px 94px 125px;
  }
`;
const PageHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 12px;
  @media (min-width: 768px) {
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
    padding: 0;
  }
`;

const CreateJobContainer = styled.div`
  border-radius: 5px;
`;
const CreateJobSubtitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px 16px 28px 16px;

  @media (min-width: 768px) {
    padding-left: 20;
  }
`;
const CreateJobContentWrapper = styled.div``;
const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: end;
  @media (min-width: 768px) {
    padding-bottom: 12px;
    width: 100%;
    gap: 24px;
  }
`;
const StepHeading = styled.div`
  label {
    cursor: pointer;
  }
`;
//#endregion

//#region JobCreate Form
const CreateJobFormContainer = styled.div`
  .form-group {
    margin-bottom: 0px;
  }
  .left-side {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .right-side {
    margin-top: 32px;
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
`;
const QualifierContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
`;
const NextButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: end;
  padding-top: 52px;
  padding-right: 4px;
  @media (min-width: 768px) {
    padding-right: 10px;
  }
`;
const AdvertiseButtonWrapper = styled.div`
  position: absolute;
  right: 12px;
  bottom: 20px;

  @media (min-width: 576px) {
    padding-right: 0px;
    right: 52px;
    bottom: 39px;
  }
`;
const BackButton = styled.div`
  display: flex;
  border-radius: 50%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  height: 26px;
  width: 26px;
  cursor: pointer;

  @media (min-width: 768px) {
    gap: 8px;
    height: auto;
    width: auto;
    border-radius: 0;
    align-items: center;
    background-color: none;
  }
`;
const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
`;
//#endregion

export const CreateJobStyles = {
  StepHeading,
  BackButton,
  Container,
  PageContainer,
  PageHeadingWrapper,
  CreateJobContainer,
  CreateJobSubtitle,
  CreateJobContentWrapper,
  CreateJobFormContainer,
  QualifierContainer,
  NextButtonWrapper,
  StepsContainer,
  AdvertiseButtonWrapper,
  HeadingContainer,
};

//#region Qualification
const QualificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
`;
const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  background: ${({ theme }) => theme.colors.light_default};
  box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);
  border-radius: 5px;
  padding: 24px 20px;
`;
const DeleteIconContainer = styled.div`
  width: fit-content;
  position: absolute;
  right: 20px;
  cursor: pointer;
`;
export const QualificationStyles = {
  QualificationContainer,
  FormContainer,
  DeleteIconContainer,
};
//#endregion
