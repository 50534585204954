import { useState } from "react";
import { Formik } from "formik";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Grid } from "antd";

import { Typography } from "src/components/Basic";
import Input from "../Components/input";
import { signInValidationSchema } from "../Components/Utils";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { API_CALLS } from "src/API_CALLS";
import { SignInStyles } from "./SignInStyles";
import { ErrorLabel } from "../Components/style";
import { LoginProps } from "src/Redux/slices";
import { ICONS } from "src/assets/custom_Icons";
import { ROUTES } from "src/utils/ROUTES";
import { IInput } from "../Components/input/Input";
import { FormikHelpers } from "src/components/Basic/FormFields/FormikFields/FormikFields.helpers";

export const SignIn = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const redirectURL = searchParams.get("redirectURL");
  const { login, OTP } = useAppSelector((store) => store);
  const { errorMessageFromAPI, isAPICallInProgress } = login;
  const { OTPVerificationSuccess } = OTP;
  const screen = Grid.useBreakpoint();
  const [submitClicked, setSubmitClicked] = useState(false);

  return (
    <>
      {OTPVerificationSuccess && (
        <SignInStyles.EmailConfirmedContainer>
          <Typography
            content="Your email has been confirmed."
            styleName="emailConfirmedText"
            color="black80"
          />
          <ICONS.SuccessCheck />
        </SignInStyles.EmailConfirmedContainer>
      )}
      <div className="d-flex justify-content-center">
        <Typography
          content="Sign In"
          styleName={screen.lg ? "authTitle" : "authTitleMobile"}
          color={screen.lg ? "black_pure" : "primary_default"}
        />
      </div>
      <Formik
        initialValues={{} as LoginProps.FormValues}
        validationSchema={signInValidationSchema}
        onSubmit={(values) => {
          dispatch(actions.login.setFormValues(values));
          dispatch(actions.login.setAPICallInProgress(true));
          API_CALLS.AUTH.login(navigate, redirectURL || ROUTES.HOME);
        }}
      >
        {(formik) => {
          const onChangeHandler = () => {
            errorMessageFromAPI &&
              dispatch(actions.login.setErrorMessageFromAPI(""));
            dispatch(actions.login.setAPICallInProgress(false));
          };

          const idPrefix = "sign-in";
          const username: IInput.IProps = {
            idPrefix,
            fieldName: "username",
            placeholder: "Email address",
            isRequired: true,
            onChangeHandler,
          };
          const password: IInput.IProps = {
            idPrefix,
            fieldName: "password",
            placeholder: "Password",
            isRequired: true,
            InputType: "password" as IInput.inputType,
            onChangeHandler,
            showErrorMessage: formik.errors.password ? true : false,
          };
          const onKeyDownHandler = (e: React.KeyboardEvent) => {
            const isEnterKey = e.key === "Enter" || e.keyCode === 13;
            if (isEnterKey) formik.submitForm();
          };

          return (
            <SignInStyles.InputFieldWrapper>
              <SignInStyles.InputContainer>
                <Input {...username} />
                <div>
                  <Input {...password} onKeyDownHandler={onKeyDownHandler} />
                  {errorMessageFromAPI && (
                    <ErrorLabel>{errorMessageFromAPI}</ErrorLabel>
                  )}
                </div>
              </SignInStyles.InputContainer>
              <SignInStyles.ButtonWrapper
                disabled={
                  submitClicked &&
                  (isAPICallInProgress ||
                    !formik.isValid ||
                    errorMessageFromAPI)
                    ? true
                    : false
                }
                type="submit"
                onClick={() => {
                  formik.setTouched({ username: true, password: true });
                  formik.validateForm();
                  const requiredFieldNameWithNoValue =
                    FormikHelpers.getRequiredFieldNameWithNoValue(
                      { username, password },
                      formik.values
                    );
                  const isFormUnTouched =
                    Object.keys(formik.touched).length === 0;
                  const isNoErrors =
                    isFormUnTouched || Object.keys(formik.errors).length === 0;
                  const isValidForm =
                    !requiredFieldNameWithNoValue && isNoErrors;
                  !isValidForm &&
                    FormikHelpers.setFocusOnErrorNode(
                      idPrefix + requiredFieldNameWithNoValue
                    );
                  isValidForm && formik.submitForm();

                  setSubmitClicked(true);
                }}
              >
                <Typography
                  content="Sign In"
                  styleName="tableButton"
                  color="white_pure"
                />
              </SignInStyles.ButtonWrapper>
            </SignInStyles.InputFieldWrapper>
          );
        }}
      </Formik>
      <SignInStyles.Container>
        <NavLink to={"/auth/forgot"}>
          <Typography
            content="Forgotten Password?"
            styleName="forgotPassword"
            color="primary_default"
          />
        </NavLink>
        <div className="d-flex gap-1">
          <Typography
            content="Don't have an account?"
            styleName="createAccount"
            color="black60"
          />{" "}
          <NavLink to={"/auth/register"}>
            <Typography
              content="Create an Account"
              styleName="createAccount"
              color="black200"
            />
          </NavLink>
        </div>
      </SignInStyles.Container>
    </>
  );
};
