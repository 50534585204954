import { ApexOptions } from "apexcharts";

import { theme } from "src/components/Theme";

const yAxisTitle = {
  text: "Growth",
  style: {
    color: "#adb5be",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
    cssClass: "apexcharts-yaxis-label",
  },
};
const axisTicks = {
  show: true,
  borderType: "solid",
  color: "rgba(119, 119, 142, 0.05)",
  offsetX: 0,
  offsetY: 0,
};
const options: ApexOptions = {
  chart: {
    height: 280,
    type: "line",
    zoom: { enabled: false },
    dropShadow: {
      enabled: true,
      enabledOnSeries: undefined,
      top: 5,
      left: 0,
      blur: 3,
      color: "#000",
      opacity: 0.1,
    },
  },
  dataLabels: { enabled: false },
  legend: {
    position: "top",
    horizontalAlign: "left",
    offsetX: -15,
    fontWeight: "bold",
  },
  stroke: { curve: "smooth", width: 3 },
  grid: { borderColor: "#f2f6f7" },
  colors: [theme.colors.success_default],

  yaxis: { title: yAxisTitle },
  xaxis: {
    labels: { format: "ddd d,MMM" },

    axisBorder: {
      show: true,
      color: "rgba(119, 119, 142, 0.05)",
      offsetX: 0,
      offsetY: 0,
    },
    axisTicks,
  },
};

export const ChartWithDateFilterUtils = {
  options,
  yAxisTitle,
  axisTicks,
};
