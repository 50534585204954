import { useContext } from "react";
import ReactEcharts from "echarts-for-react";

import { PagesCommon } from "../../Common";
import { ReportsPageProps } from "../Reports";
import { AppContext } from "src/App";

export const HalfDoughnutChart = ({
  option,
  title,
  hasNoResultsError,
}: ReportsPageProps.HalfDougnnutChart) => {
  const { isSmallTabletOrSmall } = useContext(AppContext);
  return (
    <PagesCommon.Styles.ChartContainer setHeight={!isSmallTabletOrSmall}>
      <PagesCommon.Components.ChartTitle title={title} />
      {hasNoResultsError ? (
        <PagesCommon.Components.InsufficientData />
      ) : (
        <ReactEcharts className="half-doughnut" option={option} />
      )}
    </PagesCommon.Styles.ChartContainer>
  );
};
