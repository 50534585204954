type IconProps = { fillColor?: string };
export const TriangleArrowUp = ({ fillColor }: IconProps) => (
  <svg
    width="9"
    height="5"
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 4.5L9 4.5L4.50559 0.5L0 4.5Z" fill={fillColor || "#222020"} />
  </svg>
);

export const TriangleArrowDown = ({ fillColor }: IconProps) => (
  <svg
    width="9"
    height="5"
    viewBox="0 0 9 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0.500001L3.49691e-07 0.5L4.49441 4.5L9 0.500001Z"
      fill={fillColor || "#222020"}
    />
  </svg>
);
