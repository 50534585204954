import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const ResponsibilitiesAndWarranties = () => (
  <>
    <Points.Heading
      pointNumber="7."
      heading="Responsibilities and Warranties for using Nova"
    />
    <Points.Indent>
      <>
        <POINT_7_1 />
        <Points.Bold pointNumber="7.2" Content={POINT_7_2} />
        <Points.Bold pointNumber="7.3" Content={POINT_7_3} />
        <Points.Bold pointNumber="7.4" Content={POINT_7_4} />
      </>
    </Points.Indent>
  </>
);

//#region 7.1
const POINT_7_1 = () => (
  <>
    <Points.SubHeading
      pointNumber="7.1."
      heading={`By using Nova and/or the Services, you warrant that:`}
      needUnderline={false}
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="7.1.1." Content={POINT_7_1_1} />
        <Points.Span2 pointNumber="7.1.2." Content={POINT_7_1_2} />
        <Points.Span2 pointNumber="7.1.3." Content={POINT_7_1_3} />
        <Points.Span2 pointNumber="7.1.4." Content={POINT_7_1_4} />
        <Points.Span2 pointNumber="7.1.5." Content={POINT_7_1_5} />
        <Points.Span2 pointNumber="7.1.6." Content={POINT_7_1_6} />
        <Points.Span2 pointNumber="7.1.7." Content={POINT_7_1_7} />
        <Points.Span2 pointNumber="7.1.8." Content={POINT_7_1_8} />
        <Points.Span2 pointNumber="7.1.9." Content={POINT_7_1_9} />
        <Points.Span2 pointNumber="7.1.10." Content={POINT_7_1_10} />
        <Points.Span2 pointNumber="7.1.11." Content={POINT_7_1_11} />
        <div>
          <Points.Span2 pointNumber="7.1.12." Content={POINT_7_1_12} />
          <Points.Span2 pointNumber="" Content={POINT_FOOTER} />
        </div>
      </>
    </Points.Indent>
  </>
);
const POINT_7_1_1 = (
  <Points.Content>
    <Text.Span
      content={`you have read and agreed to these Terms and will use Nova and the Services in accordance with them;`}
    />
  </Points.Content>
);
const POINT_7_1_2 = (
  <Points.Content>
    <Text.Span
      content={`you expressly understand and agree that Ditto Media is only a facilitating party, and any liability stemming from the advertising of a job listing lies with you as the Employer, and not with Ditto Media;`}
    />
  </Points.Content>
);
const POINT_7_1_3 = (
  <Points.Content>
    <Text.Span
      content={`you understand that Nova and/or Ditto Media cannot guarantee the quality of any Jobseeker application and is not responsible for the quality or content thereof;`}
    />
  </Points.Content>
);
const POINT_7_1_4 = (
  <Points.Content>
    <Text.Span
      content={`you have not made any misrepresentations and the information provided in the registration process about you, your company and/or your status is true, accurate and complete in every aspect;`}
    />
  </Points.Content>
);
const POINT_7_1_5 = (
  <Points.Content>
    <Text.Span
      content={`you are above the age of 18 (eighteen) years old and have the legal capacity to understand, agree with and be bound with these Terms;`}
    />
  </Points.Content>
);
const POINT_7_1_6 = (
  <Points.Content>
    <Text.Span
      content={`you lawfully possess and submit all information to Nova and/or Ditto Media for the use of it or the Services;`}
    />
  </Points.Content>
);
const POINT_7_1_7 = (
  <Points.Content>
    <Text.Span
      content={`you will not post, upload, replicate or transmit any abusive content on Nova that is or could reasonably be considered to be threatening, harassing, defamatory, abusive, racist, sexist, discriminatory, in breach of confidence, in breach of privacy or restrict any user in any way from properly using Nova or Ditto Jobs;`}
    />
  </Points.Content>
);
const POINT_7_1_8 = (
  <Points.Content>
    <Text.Span
      content={`you will not send any unsolicited electronic messages or use any software, routine or device to interfere or attempt to interfere electronically or manually with the operation or functionality of Nova including but not limited to uploading or making available files containing corrupt data or viruses via whatever means or deface, alter or interfere with the front end ‘look and feel’ of Nova or the underlying software code;`}
    />
  </Points.Content>
);
const POINT_7_1_9 = (
  <Points.Content>
    <Text.Span
      content={`you will not infringe the intellectual property or other rights of any third party or Nova or transmit content that you do not own or do not have the right to publish or distribute;`}
    />
  </Points.Content>
);
const POINT_7_1_10 = (
  <Points.Content>
    <Text.Span
      content={`you will not use Nova for any commercial purpose other than as expressly provided for by Ditto Media herein;`}
    />
  </Points.Content>
);
const POINT_7_1_11 = (
  <Points.Content>
    <Text.Span
      content={`you will not use Nova to breach any applicable law or regulation or perform or encourage any illegal activity including (without limitation) promoting or facilitating money laundering or financial crimes; and/or`}
    />
  </Points.Content>
);
const POINT_7_1_12 = (
  <Points.Content>
    <Text.Span
      content={`you will not facilitate or assist any third party to do any of the above,`}
    />
  </Points.Content>
);
const POINT_FOOTER = (
  <Points.Content>
    <Text.Span content="failing which, such action will automatically and immediately be deemed to be a material breach of these Terms, allowing us to manifest all our rights in the case of breach, including but not limited to denying you access to Nova/Services, reporting your actions to an applicable authority, demanding specific performance and/or suing you for damages." />
  </Points.Content>
);
//#endregion

//#region 7.2
const POINT_7_2 = (
  <Points.Content>
    <Text.Bold
      content={`Employers accept and understand that all liability related to the offering, advertisement and/or provision of any job listing lies strictly with them as the advertiser of that job.`}
    />
  </Points.Content>
);
const POINT_7_3 = (
  <Points.Content>
    <Text.Span
      content={`Nova is only available on compatible devices connected to the internet. It is your responsibility to obtain these devices and any connectivity necessary to use Nova. `}
    />
    <Text.Bold
      content={`We do not guarantee that Nova, or any portion thereof, will function on any particular hardware or device.`}
    />
  </Points.Content>
);
const POINT_7_4 = (
  <Points.Content>
    <Text.Bold
      content={`Without prejudice to any of our other rights (whether at law or otherwise), we reserve the right to deny you access to Nova where we believe (in our reasonable discretion) that you are in breach of any of these Terms.`}
    />
  </Points.Content>
);
//#endregion
