import { IIconProps } from "./customIcons";

const BagIcon = ({
  fill = "#AEB7C6",
  height = 10,
  width = 11,
}: IIconProps.Iprops) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6 2.10526V1.05263H4.4V2.10526H6.6ZM1.1 3.15789V8.94737H9.9V3.15789H1.1ZM9.9 2.10526C10.5105 2.10526 11 2.57368 11 3.15789V8.94737C11 9.53158 10.5105 10 9.9 10H1.1C0.4895 10 0 9.53158 0 8.94737L0.00549999 3.15789C0.00549999 2.57368 0.4895 2.10526 1.1 2.10526H3.3V1.05263C3.3 0.468421 3.7895 0 4.4 0H6.6C7.2105 0 7.7 0.468421 7.7 1.05263V2.10526H9.9Z"
        fill={fill}
      />
    </svg>
  );
};

export default BagIcon;
