import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import "./createJob.css";
import { TypographyLink } from "src/components/Basic";
import {
  CreateJobProps,
  actions,
  useAppDispatch,
  useAppSelector,
} from "src/Redux";
import { CreateJobStyles } from "./Styles";
import { ICONS } from "src/assets/custom_Icons";
import { theme } from "src/components/Theme";
import { AppContext } from "src/App";
import { ROUTES } from "src/utils/ROUTES";

export const BackButton = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const { createJob, common, campaign } = useAppSelector((store) => store);
  const { stepNumber, isEditFlow } = createJob;
  const { isBoostedSelected } = campaign;
  const { isDarkTheme } = common;

  const { setStepNumber } = actions.createJob;
  const { setBoostedSelected } = actions.campaign;

  const { isTabletOrBig } = useContext(AppContext);

  const backClickHandler = () => {
    if (isEditFlow) {
      navigate(ROUTES.JOBS);
      dispatch(actions.createJob.reset());
      dispatch(actions.campaign.reset());
      return;
    }

    if (stepNumber === 2 && isBoostedSelected)
      dispatch(setBoostedSelected(false));
    else dispatch(setStepNumber((stepNumber - 1) as CreateJobProps.stepNumber));
  };

  return stepNumber > 1 ? (
    <CreateJobStyles.BackButton onClick={backClickHandler}>
      <ICONS.BackArrowIcon
        fill={isDarkTheme ? theme.colors.black40 : theme.colors.black100}
      />
      {isTabletOrBig && (
        <TypographyLink
          styleName="jobCheckoutDropdown"
          color={isDarkTheme ? "black40" : "black100"}
          content="back"
          underLineNeeded={false}
        />
      )}
    </CreateJobStyles.BackButton>
  ) : (
    <></>
  );
};
