import { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Field, FieldProps } from "formik";

import { FormFieldCommon } from "../FormFieldsCommon";
import { theme } from "../../../Theme";
import { FormFieldsProps } from "../FormFields";
import { FIELDS } from "../FormFieldsStyles/common";

export const DropdownField = ({
  fieldName,
  idPrefix,
  options,
  onChangeHandler,
  value,
  defaultValue,
}: FormFieldsProps.Dropdown) => {
  return (
    <DropdownContainer Error={false} disabled={false}>
      <Select
        value={value}
        id={`${idPrefix}_${fieldName}`}
        name={fieldName}
        onChange={onChangeHandler}
        options={options}
        classNamePrefix="formselect"
        defaultValue={defaultValue}
        styles={{
          input: (baseStyles) => {
            return { ...baseStyles, color: theme.colors.black80 };
          },
        }}
      />
    </DropdownContainer>
  );
};

const getDropdownDefaultValue = (
  value: string | number,
  options: FormFieldsProps.option[]
) => options.find((option) => option.value === value);
export const getMultipleDropdownDefaultValue = (
  value: string[] | number[],
  options: FormFieldsProps.option[]
) => {
  const valueMap = {} as { [key: string | number]: boolean };
  value.forEach((val) => (valueMap[val] = true));
  const result = options.filter((option) => valueMap[option.value] === true);
  return result;
};

export const Dropdown = (props: FormFieldsProps.Dropdown) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    disabled = false,
    allowMultiple = false,
    allowSearch = true,
    options,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    onChangeHandler,
    openMenuOnFocus = true,
    defaultValue,
    showLabel = true,
    onInputChangeHandler,
    multipleSelectionLimit,
    showStarAfterLabel = true,
  } = props;

  const [isFocused, setFocused] = useState(false);
  const [isDefaultSet, setIsDefaulSet] = useState(false);
  const [fieldPlaceholder, setFieldPlaceholder] = useState(placeholder);
  const [closeMenuOnSelect, setCloseMenuOnSelect] = useState(
    allowMultiple && multipleSelectionLimit ? false : true
  );
  let newDefaultValue: any = null;

  return (
    <Field name={fieldName} key={fieldName}>
      {({ form, meta, field }: FieldProps) => {
        const { setFieldValue, setFieldTouched } = form;
        const { touched, value, error } = meta;
        const { onBlur } = field;

        if (!isDefaultSet) {
          if (!value && defaultValue)
            setFieldValue(fieldName, defaultValue.value);
          else if (value) {
            if (allowMultiple)
              newDefaultValue = getMultipleDropdownDefaultValue(value, options);
            else newDefaultValue = getDropdownDefaultValue(value, options);
          }
          setIsDefaulSet(true);
        }

        const hasError =
          !isFocused && isRequired && touched && (!value || value.length === 0);
        const errorLabel = error || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        const additionalProps = allowMultiple ? { value } : {};
        const animatedComponents = makeAnimated();

        return (
          <DropdownContainer Error={hasError} disabled={disabled}>
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${
                  isRequired && showStarAfterLabel ? "*" : ""
                }`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <Select
              value={value && { label: value, value }}
              id={`${idPrefix}_${fieldName}`}
              name={fieldName}
              components={animatedComponents}
              closeMenuOnSelect={closeMenuOnSelect}
              onFocus={() => {
                setFieldPlaceholder("");
                setFocused(true);
              }}
              onChange={(selectedItem) => {
                onChangeHandler && onChangeHandler(selectedItem?.value);
                //#region Single selection
                if (!allowMultiple) {
                  setFieldValue(fieldName, selectedItem?.value);
                  return;
                }
                //#endregion

                //#region Multiple selection
                if (!multipleSelectionLimit) {
                  setFieldValue(fieldName, selectedItem);
                  setCloseMenuOnSelect(true);
                  return;
                }
                if (selectedItem.length <= multipleSelectionLimit) {
                  setFieldValue(fieldName, selectedItem);
                  setCloseMenuOnSelect(false);
                }
                if (selectedItem.length === multipleSelectionLimit - 1) {
                  setCloseMenuOnSelect(true);
                }
                //#endregion
              }}
              onInputChange={(inputValue) => {
                onInputChangeHandler && onInputChangeHandler(inputValue);
              }}
              onBlur={(e) => {
                setFieldTouched(fieldName, true);
                setFieldPlaceholder(placeholder);
                setFocused(false);
                onBlur(e);
              }}
              options={options}
              classNamePrefix="formselect"
              className="dropdown"
              isSearchable={allowSearch}
              placeholder={
                <FormFieldCommon.Placeholder content={fieldPlaceholder} />
              }
              isMulti={allowMultiple}
              isDisabled={disabled}
              styles={{
                input: (baseStyles) => {
                  return { ...baseStyles, color: theme.colors.black80 };
                },
                multiValueLabel: (baseStyles) => {
                  return { ...baseStyles, color: theme.colors.black80 };
                },
              }}
              defaultValue={newDefaultValue || defaultValue}
              controlShouldRenderValue={true}
              openMenuOnFocus={openMenuOnFocus}
              isClearable={false}
              {...additionalProps}
            />
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </DropdownContainer>
        );
      }}
    </Field>
  );
};

const DropdownContainer = styled.div<{ disabled: boolean; Error: boolean }>`
  ${({ Error }) =>
    Error ? FIELDS.ERROR_FORMSELECT_CONTROL : FIELDS.FORMSELECT_CONTROL};
`;
