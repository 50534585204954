import { Form } from "react-bootstrap";
import { Field, FieldProps } from "formik";
import styled from "styled-components";

import { FormFieldCommon } from "../FormFieldsCommon";
import { FormFieldsProps } from "../FormFields";
import { FIELDS } from "../FormFieldsStyles/common";
import { REGEX } from "../REGEX";
import { useState } from "react";
import { useAppSelector } from "src/Redux";

export const Input = (props: FormFieldsProps.Input) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    disabled = false,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    inputType = "text",
    defaultValue = "",
    showLabel = true,
    textLimit,
    onChangeHandler,
    onBlurHandler,
    defaultToZero = false,
  } = props;

  const [isFocused, setFocused] = useState(false);
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const { isDarkTheme } = useAppSelector((store) => store.common);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta, form }: FieldProps) => {
        const { onChange, value, onBlur } = field;
        const { touched, error } = meta;
        const { setFieldValue } = form;

        const errorConditions = isRequired && !isFocused && touched;
        let hasError =
          errorConditions &&
          ((error ? true : false) ||
            value === undefined ||
            value?.length === 0);
        let errorLabel = error || `${label || fieldName} is required`;

        if (inputType === "email") {
          const isValidEmail = REGEX.EMAIL.test(value);
          if (!isValidEmail) hasError = errorConditions && !isValidEmail;
          errorLabel = "Please enter valid email";
        } else if (inputType === "URL") {
          const isValidURL = REGEX.WEBSITE.test(value);
          if (!isValidURL) hasError = !isValidURL;
          errorLabel = "Please enter valid URL";
        } else if (!isDefaultSet && inputType === "number") {
          const parsedVal = parseFloat(defaultValue || value);
          setFieldValue(
            fieldName,
            isNaN(parsedVal) ? (defaultToZero ? 0 : "") : parsedVal
          );
          setIsDefaultSet(true);
        }

        const onChangeNumber = (e: React.ChangeEvent<any>) => {
          const value = e.target.value;
          if (!value) {
            onChange(e);
            onChangeHandler && onChangeHandler(value);
            return;
          }
          const newNumber = Number.parseFloat(value);
          if (!isNaN(newNumber)) {
            setFieldValue(fieldName, newNumber);
            onChangeHandler && onChangeHandler(newNumber);
          }
        };

        const showErrorLabel = hasError && errorLabel ? true : false;

        const getChangeHandler = (e: React.ChangeEvent<any>) => {
          if (inputType === "number") return onChangeNumber(e);

          const defaultChangeHandler = (e: React.ChangeEvent<any>) => {
            onChangeHandler && onChangeHandler(e.target.value);
            onChange(e);
          };
          return defaultChangeHandler(e);
        };

        return (
          <InputContainer {...{ isDarkTheme, disabled, Error: hasError }}>
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <Form.Control
              id={`${idPrefix}_${fieldName}`}
              type="text"
              className="form-control"
              name={fieldName}
              placeholder={placeholder}
              onChange={(e) =>
                textLimit
                  ? e.target.value?.length <= textLimit
                    ? getChangeHandler(e)
                    : () => {}
                  : getChangeHandler(e)
              }
              value={value}
              disabled={disabled}
              onFocus={() => setFocused(true)}
              onBlur={(e) => {
                setFocused(false);
                onBlur(e);
                onBlurHandler && onBlurHandler();
              }}
            />
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </InputContainer>
        );
      }}
    </Field>
  );
};

const InputContainer = styled.div<{
  disabled: boolean;
  Error: boolean;
  isDarkTheme: boolean;
}>`
  ${({ Error, disabled }) => {
    if (disabled) return FIELDS.DISABLED_FORM_CONTROL;
    return Error ? FIELDS.ERROR_INPUT_FORM_CONTROL : FIELDS.INPUT_FORM_CONTROL;
  }};
`;
