import { useContext } from "react";
import { TypographyLink } from "src/components/Basic";
import { theme } from "src/components/Theme";
import styled from "styled-components";
import { TermsAndConditionsContext } from ".";
import { ContentProps } from "./Contents";

export const HeadingToggleButtons = () => {
  const { contentType, setContentType } = useContext(TermsAndConditionsContext);

  return (
    <Container className="toggle-buttons">
      <ButtonsWrapper>
        <Button
          onClick={() => setContentType("terms_and_conditions")}
          selected={contentType === "terms_and_conditions"}
        >
          <TypographyLink
            content="Terms & Conditions"
            styleName="termsHeadingButton"
            color={
              contentType === "terms_and_conditions"
                ? "primary_default"
                : "blue_grey_80"
            }
            underLineNeeded={false}
          />
        </Button>
        <Button
          onClick={() => setContentType("privacy_policy")}
          selected={contentType === "privacy_policy"}
        >
          <TypographyLink
            content="Privacy Policy"
            styleName="termsHeadingButton"
            color={
              contentType === "privacy_policy"
                ? "primary_default"
                : "blue_grey_80"
            }
            underLineNeeded={false}
          />
        </Button>
      </ButtonsWrapper>
    </Container>
  );
};

const Container = styled.div`
  background: ${theme.colors.white_pure};
  padding: 7px;
  border-radius: 5px;
  margin-bottom: 24px;
  @media (min-width: 768px) {
    margin-bottom: 42px;
  }
`;
const ButtonsWrapper = styled.div`
  width: fit-content;
  margin: auto;
`;
const Button = styled.button<ContentProps.contentTypeButtonProps>`
  margin: auto;
  border: 0;
  box-shadow: none;
  background: ${({ selected }) =>
    selected ? "rgba(30, 150, 242, 0.20)" : "transparent"};
  border-radius: 5px;
  height: 38px;
  padding: 10.5px 20px;
`;
