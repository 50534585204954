import { candidateActions, candidateReducer } from "./Candidate";
import { createJobActions, createJobReducer } from "./CreateJob";
import { dashboardActions, dashboardReducer } from "./Dashboard";
import { loginActions, loginReducer } from "./Login";
import { OTPActions, OTPReducer } from "./OTP";
import { pipelinePhaseActions, pipelinePhaseReducer } from "./PipelinePhase";
import { registerActions, registerReducer } from "./Register";
import { reportsActions, reportsReducer } from "./Reports";
import { activeJobActions, activeJobReducer } from "./ActiveJobs";
import { commonActions, commonReducer } from "./Common";
import { settingsActions, settingsReducer } from "./Settings";
import { stripeActions, stripeReducer } from "./Stripe";
import { onboardingActions, onboardingReducer } from "./Onboarding";
import { campaignActions, campaignReducer } from "./Campaign";
import {
  messageTemplateActions,
  messageTemplateReducer,
} from "./MessageTemplate";
import { notificationActions, notificationReducer } from "./Notification";

export type { ReportsProps } from "./Reports/Reports";
export type { RegisterProps } from "./Register/Register";
export type { LoginProps } from "./Login/Login";
export type { OTPProps } from "./OTP/OTP";
export type { CreateJobProps } from "./CreateJob/CreateJob";
export type { CommonProps } from "./Common/Common";
export type { StripeProps } from "./Stripe/Stripe";
export type { OnboardingProps } from "./Onboarding/Onboarding";
export type { NotificationsProps } from "./Notification/Notification";
export type { CampaignProps } from "./Campaign/Campaign";

export const actions = {
  login: loginActions,
  OTP: OTPActions,
  reports: reportsActions,
  register: registerActions,
  pipelinePhase: pipelinePhaseActions,
  candidate: candidateActions,
  createJob: createJobActions,
  common: commonActions,
  settings: settingsActions,
  activeJob: activeJobActions,
  dashboard: dashboardActions,
  messageTemplate: messageTemplateActions,
  onboarding: onboardingActions,
  notification: notificationActions,
  stripe: stripeActions,
  campaign: campaignActions,
};
export const reducer = {
  login: loginReducer,
  OTP: OTPReducer,
  reports: reportsReducer,
  register: registerReducer,
  pipelinePhase: pipelinePhaseReducer,
  candidate: candidateReducer,
  createJob: createJobReducer,
  common: commonReducer,
  settings: settingsReducer,
  activeJob: activeJobReducer,
  dashboard: dashboardReducer,
  messageTemplate: messageTemplateReducer,
  onboarding: onboardingReducer,
  notification: notificationReducer,
  stripe: stripeReducer,
  campaign: campaignReducer,
};
