import { Modal } from "react-bootstrap";
import { theme } from "src/components/Theme";
import styled from "styled-components";

export const ButtonContainer = styled.div`
  button {
    font-weight: 600;
  }
  .dark-theme .modal-footer &:first-child button.btn-light {
    background: ${theme.colors.light_default} !important;
    color: ${theme.colors.black90};
  }
`;
export const ModalFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 23px 25px 16px;
  gap: 16px;
  @media (max-width: 991px) {
    padding: 23px 4px 16px;
  }
`;
export const Container = styled.div`
  margin: 26px 0px;
  display: flex;
  justify-content: center;
  .dark-theme & label{
    color:${theme.colors.black10}
  }
`;
export const ModalHeader = styled(Modal.Header)`
  .dark-theme & .modal-title label {
    color: ${theme.colors.black50};
  }
`;
