import * as yup from "yup";

import { REGEX } from "src/components/Basic/FormFields";
import { FormFieldsProps } from "../../../Basic/FormFields/FormFields";
import { CreateJobComponentProps } from "../CreateJob";
import { CreateJobProps } from "src/Redux";

//#region JobCreate Fields

const idPrefix = "create-job";
const currencyTypeFieldName = "base_currency";

const job_title: FormFieldsProps.Input = {
  fieldName: "job_title",
  idPrefix,
  isRequired: true,
  label: "Job title",
};
const country: FormFieldsProps.CountrySelector = {
  fieldName: "country",
  idPrefix,
  isRequired: true,
  label: "Country",
  citySearchName: "city",
  updateReduxCountryValue: true,
  currencyTypeFieldName,
};
const city: FormFieldsProps.CitySearch = {
  fieldName: "city",
  countrySelectorName: "country",
  idPrefix,
  isRequired: true,
  label: "City",
};
const work_environment: FormFieldsProps.Dropdown = {
  fieldName: "work_environment",
  idPrefix,
  isRequired: true,
  label: "Workplace Type",
  options: [
    { label: "On-Site", value: "On-Site" },
    { label: "Hybrid", value: "Hybrid" },
    { label: "Remote", value: "Remote" },
  ],
  allowSearch: false,
};
const type: FormFieldsProps.Dropdown = {
  fieldName: "type",
  idPrefix,
  isRequired: true,
  label: "Employment Type",
  options: [
    { label: "Full-Time", value: "Full-Time" },
    { label: "Part-Time", value: "Part-Time" },
    { label: "Freelance", value: "Freelance" },
    { label: "Contract", value: "Contract" },
    { label: "Temporary", value: "Temporary" },
    { label: "Internship", value: "Internship" },
    { label: "Volunteer", value: "Volunteer" },
    { label: "Other", value: "Other" },
  ],
  allowSearch: false,
};
const industry: FormFieldsProps.Dropdown = {
  fieldName: "industry",
  idPrefix,
  isRequired: true,
  label: "Industry* (max. 3)",
  placeholder: "Industry",
  options: [],
  allowMultiple: true,
  multipleSelectionLimit: 3,
  showStarAfterLabel: false,
};
const skills: FormFieldsProps.Dropdown = {
  fieldName: "skills",
  idPrefix,
  isRequired: true,
  label: "Skills Required* (max. 10)",
  placeholder: "Skills Required",
  options: [],
  allowMultiple: true,
  multipleSelectionLimit: 10,
  showStarAfterLabel: false,
};
const experience_from: FormFieldsProps.Input = {
  label: "Experience From",
  showLabel: false,
  fieldName: "experience_from",
  idPrefix,
  isRequired: true,
  inputType: "number",
  placeholder: "From",
};
const experience_to: FormFieldsProps.Input = {
  label: "Experience To",
  showLabel: false,
  fieldName: "experience_to",
  idPrefix,
  isRequired: true,
  inputType: "number",
  placeholder: "To",
};
const remuneration_from: FormFieldsProps.CurrencySelector = {
  label: "Remuneration From",
  showLabel: false,
  fieldName: "remuneration_from",
  idPrefix,
  isRequired: true,
  placeholder: "From",
  currencyTypeFieldName,
};
const remuneration_to: FormFieldsProps.CurrencySelector = {
  label: "Remuneration To",
  showLabel: false,
  fieldName: "remuneration_to",
  idPrefix,
  isRequired: true,
  placeholder: "To",
  currencyTypeFieldName,
};
const compensation_type: FormFieldsProps.Dropdown = {
  fieldName: "compensation_type",
  idPrefix,
  isRequired: true,
  options: [
    { label: "Hourly", value: "Hourly" },
    { label: "Daily", value: "Daily" },
    { label: "Weekly", value: "Weekly" },
    { label: "Monthly", value: "Monthly" },
    { label: "Annually", value: "Annually" },
  ],
  label: "Compensation Type",
};
const department: FormFieldsProps.Input = {
  fieldName: "department",
  idPrefix,
  isRequired: true,
  label: "Department",
};
const show_company_gallery: FormFieldsProps.Checkbox = {
  fieldName: "show_company_gallery",
  idPrefix,
  isRequired: false,
  label: "Include Company Gallery",
  helpText: { title: "title", info: "Lorem ipsum dolar" },
};
const add_external_apply_URL: FormFieldsProps.Checkbox = {
  fieldName: "add_external_apply_URL",
  idPrefix,
  isRequired: false,
  label: "Add External Apply URL",
  helpText: { title: "title", info: "Lorem ipsum dolar" },
};
const external_apply: FormFieldsProps.Input = {
  fieldName: "external_apply",
  idPrefix,
  isRequired: true,
  placeholder: "External URL",
  inputType: "URL",
};
const add_qualifying_question: FormFieldsProps.Switch = {
  fieldName: "add_qualifying_question",
  idPrefix,
  isRequired: false,
  label: "Add Qualifiers",
};
const validateNoPhoneEmailExternalLink: FormFieldsProps.customValidation = {
  validation: (value) => {
    if (!value) return false;
    const containsEmail = REGEX.CONTAINS_EMAIL.test(value);
    const containsPhone = REGEX.CONTAINS_PHONE_NUMBER.test(value);
    const containsExternalLink = REGEX.EXTERNAL_LINK.test(value);
    return containsEmail || containsPhone || containsExternalLink;
  },
  validationMessage:
    "Telephone numbers, email addresses, and external links not allowed. Please refer to our posting guidelines for more information.",
};
const job_description: FormFieldsProps.RichTextarea = {
  fieldName: "job_description",
  idPrefix,
  isRequired: true,
  label: "Description",
  customValidation: validateNoPhoneEmailExternalLink,


};

const initialJobCreateFields: CreateJobComponentProps.jobCreateFields = {
  job_title,
  country,
  city,
  work_environment,
  type,
  industry,
  skills,
  experience_from,
  experience_to,
  remuneration_from,
  remuneration_to,
  compensation_type,
  department,
  show_company_gallery,
  job_description,
  add_external_apply_URL,
  external_apply,
  add_qualifying_question,
};
//#endregion

//#region InitialValues
const initialData: CreateJobProps.CreateNovaJobForm = {
  advertised_type: "0",
  city: { label: "New York, NY", value: "2623053" },
  country: {
    alpha2: "us",
    alpha3: "usa",
    flag: "🇺🇸",
    id: "us",
    ioc: "usa",
    name: "United States",
  },
  department: "Comp sci",
  experience_from: 3,
  experience_to: 5,
  industry: [
    { label: "Administrative", value: 3 },
    { label: "Accounting", value: 2 },
    { label: "Advertising", value: 4 },
  ],
  job_description: "<p>desc</p>\n",
  job_title: "React dev",
  qualifying_questions: [],
  remuneration_from: 1200,
  remuneration_to: 1500,
  show_company_gallery: false,
  skills: [
    { label: "Ability to Meet Deadlines", value: 2 },
    { label: "Account Management", value: 4 },
    { label: "Accounting", value: 6 },
  ],
  type: "Contract",
  work_environment: "On-Site",
  base_currency: { label: "USD", value: "$" },
  add_external_apply_URL: false,
  add_qualifying_question: false,
  compensation_type: "Monthly",
};
//#endregion
//#region Validation schema
const countrySchema = yup.object().shape({
  id: yup.string().required("Country Id is required"),
  name: yup.string().required("Country name is required"),
  flag: yup.string().required("Country flag is required"),
  alpha2: yup.string().required("Country alpha2 code is required"),
  alpha3: yup.string().required("Country alpha3 code is required"),
  ioc: yup.string().required("Country IOC code is required"),
});
const citySchema = yup.object().shape({
  label: yup.string().required("City label is required"),
  value: yup.string().required("City value is required"),
});
const jobCreateValidationSchema = yup.object().shape({
  job_title: yup.string().required("Job title is required"),
  country: countrySchema,
  city: citySchema,
  work_environment: yup.string().required("Workplace Type is required"),
  type: yup.string().required("Employment Type is required"),
  industry: yup
    .array()
    .required("Industry is required")
    .min(1, "At least one industry must be selected"),
  skills: yup
    .array()
    .required("Skills are required")
    .min(1, "At least one skill must be selected"),
  experience_from: yup
    .number()
    .required("Experience from is required")
    .min(0, "Experience from must be a positive number")
    .max(50, "Experience from must not exceed 50"),
  experience_to: yup
    .number()
    .required("Experience to is required")
    .min(
      yup.ref("experience_from"),
      "Experience to must be greater than experience from"
    )
    .max(50, "Experience from must not exceed 50"),
  remuneration_from: yup
    .number()
    .required("Remuneration from is required")
    .min(0, "Remuneration from must be a positive number")
    .max(999999999, "Remuneration from must not exceed 999999999"),
  remuneration_to: yup
    .number()
    .required("Remuneration to is required")
    .min(
      yup.ref("remuneration_from"),
      "Remuneration to must be greater than Remuneration from"
    )
    .max(999999999, "Remuneration to must not exceed 999999999"),
  department: yup.string().required("Department is required"),
  job_description: yup.string().required("Description is required"),
});
//#endregion

//#region Qualifying question
const responseTypeOptions = [
  { label: "Yes/No", value: "yesno" },
  { label: "Numeric", value: "numeric" },
];
const idealAnswerOptions = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const qualifyingQuestion: FormFieldsProps.Textarea = {
  fieldName: "qualifying_question",
  idPrefix,
  isRequired: true,
  label: "Qualifying Question",
  placeholder: "Ask a question...",
  textLimit: 100,
  showTextCount: true,
};
const responseType: FormFieldsProps.Dropdown = {
  fieldName: "response_type",
  idPrefix,
  isRequired: false,
  label: "Response Type",
  options: responseTypeOptions,
  defaultValue: responseTypeOptions[0],
  allowSearch: false,
};
const idealAnswerYesNo: FormFieldsProps.Dropdown = {
  fieldName: "ideal_answer",
  idPrefix,
  isRequired: false,
  label: "Ideal Answer",
  options: idealAnswerOptions,
  placeholder: " ",
  defaultValue: idealAnswerOptions[0],
  allowSearch: false,
};
const idealAnswerNumeric: FormFieldsProps.Input = {
  fieldName: "ideal_answer",
  idPrefix,
  isRequired: false,
  label: "Ideal Answer (Minimum)",
  placeholder: " ",
  inputType: "number",
  defaultToZero: true,
};
const rejectCriteria: FormFieldsProps.Checkbox = {
  fieldName: "reject_criteria",
  idPrefix,
  label: "Reject applicant if criteria is not met",
  isRequired: false,
  needLabelPlaceholder: true,
};

const QualifyingQuestionFields = {
  qualifyingQuestion,
  responseType,
  idealAnswerYesNo,
  idealAnswerNumeric,
  rejectCriteria,
};
//#endregion

export const jobCreateUtils = {
  idPrefix,
  initialJobCreateFields,
  initialData,
  QualifyingQuestionFields,
  jobCreateValidationSchema,
};
