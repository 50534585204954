const basepath = process.env.REACT_APP_NOVA_API;
const retoolBasepath = process.env.REACT_APP_NOVA_API_RETOOL;

const login = basepath + "/login";
const logout = basepath + "/logout";
const register = basepath + "/nova-register";

const checkUserVerifiedAfterLogin = basepath + "/nova-register/is-verified";
const otpVerificationAfterRegister = basepath + "/nova-register/verify";
const otpVerificationAfterLogin = basepath + "/nova-register/authed-verify";
const jobOverview = basepath + "/jobs/overview";

const company = basepath + "/company";
const getPipelineApplicants = basepath + "/pipeline-applicants";
const pipelineOverview = basepath + "/pipeline";
const candidateOverview = basepath + "/candidate-overview";
const candidateOverviewMessage = basepath + "/candidate-overview/message";
const candidateExperience = basepath + "/candidate-experience";
const candidateFiles = basepath + "/candidate-files";
const candidateQualifiers = basepath + "/candidate-qualifiers";
const candidateComments = basepath + "/pipeline-comments";
const jobDashboard = basepath + "/job-dashboard";
const updateIsRead = basepath + "/pipeline/toggle-read-status";
const updateCandidateReview = basepath + "/pipeline/application/rating";
const clearCandidateReview = basepath + "/pipeline/application/clear_rating";

const getJob = basepath + "/jobs";
const createJob = basepath + "/jobs";
const getChartData = basepath + "/jobs/cpc";
const getBillingProducts = basepath + "/billing/products";
const getCurrency = basepath + "/currency";
const getSkills = basepath + "/skills/";
const getIndustries = basepath + "/industries/";
const getCities = basepath + "/cities";
const getCityById = basepath + "/city";

const companyProfileSettings = basepath + "/settings/company-profile";
const companyProfileLogo = basepath + "/settings/company-profile/images-logo";
const companyProfileCover = basepath + "/settings/company-profile/images-cover";
const companyProfileUploadGallery =
  basepath + "/settings/company-profile/gallery-upload";
const companyProfileGallery = basepath + "/settings/company-profile/gallery";
const updateGalleryPhotoCaption =
  basepath + "/settings/company-profile/gallery-caption";
const updateGalleryPhotoPosition =
  basepath + "/settings/company-profile/gallery-position";

const companyTeamOverview = basepath + "/settings/team/overview";
const companyTeamMemberActivate = basepath + "/settings/team/member-activate";
const companyTeamMemberDeactivate =
  basepath + "/settings/team/member-deactivate";
const companyTeamMemberVerify = basepath + "/settings/team/verify";
const addMemberCompany = basepath + "/settings/team/member_company";
const addTeamMember = basepath + "/settings/team/add_member";
const updateTeamMember = basepath + "/settings/team/member-update";
const deleteInvitedMember = basepath + "/settings/team/delete_temp_member";
const updateInvitedMember = basepath + "/settings/team/edit_temp_member";

const myProfile = basepath + "/settings/my-profile";
const myProfilePicture = basepath + "/settings/my-profile/picture";
const myProfileUpdatePassword =
  basepath + "/settings/my-profile/update-password";

const messageTemplateTag = basepath + "/settings/tags";
const messageTemplate = basepath + "/settings/templates";
const stripeCreateSetupIntent = basepath + "/billing/create-setup-intent";
const getStripeCard = basepath + "/billing/list-cards";
const deleteStripeCard = basepath + "/billing/delete-card";
const defaultStripeCard = basepath + "/billing/set-default-card";

const receipts = basepath + "/billing/receipts";
const downloadReceipts = basepath + "/billing/download-receipt";

const emailNotificationSettings = basepath + "/settings/email";
const toggleTheme = basepath + "/settings/toggle-theme";

const createPaymentIntent = basepath + "/billing/create-payment-intent";

const onboardingVerify = retoolBasepath + "/onboarding/verify";
const onboardingRegister = retoolBasepath + "/onboarding/register";

//#region campaign
const notifications = basepath + "/company/notifications";
const createSingleCampaign = basepath + "/campaign/single/create";
const createBoostedCampaign = basepath + "/campaign/programmatic/create";
const updateBoostedCampaign = basepath + "/campaign/programmatic/update";
const getCampaignProducts = basepath + "/campaign/products";
const getCampaignProduct = basepath + "/campaign";
//#endregion

//#region reports
const activeJobs = basepath + "/reports/active-jobs";
const jobViews = basepath + "/reports/views";
const applications = basepath + "/reports/applications";
const unreadCandidates = basepath + "/reports/unread";

const viewsChart = basepath + "/reports/views-date";
const applicationsChart = basepath + "/reports/applications-date";
const jobsCreatedChart = basepath + "/reports/jobs-created";

const workSituationChart = basepath + "/reports/work-situation";
const employmentTypeChart = basepath + "/reports/employment-type";

const advertisingMixChart = basepath + "/reports/advertising-mix";

const hiringProgress = basepath + "/reports/hiring-progress";
//#endregion

export const API_PATHS = {
  login,
  logout,
  register,

  otpVerificationAfterRegister,
  otpVerificationAfterLogin,
  checkUserVerifiedAfterLogin,
  jobOverview,

  company,
  getPipelineApplicants,
  pipelineOverview,
  candidateOverview,
  candidateExperience,
  candidateFiles,
  candidateQualifiers,
  candidateComments,
  jobDashboard,

  getJob,
  createJob,
  getChartData,
  getBillingProducts,
  getCurrency,
  getSkills,
  getIndustries,
  getCities,
  getCityById,
  companyProfileSettings,
  companyProfileLogo,
  companyProfileCover,
  companyProfileGallery,
  updateGalleryPhotoCaption,
  updateGalleryPhotoPosition,
  companyProfileUploadGallery,
  companyTeamOverview,
  companyTeamMemberDeactivate,
  companyTeamMemberActivate,
  companyTeamMemberVerify,
  addMemberCompany,
  addTeamMember,
  updateTeamMember,
  deleteInvitedMember,
  updateInvitedMember,
  myProfile,
  myProfilePicture,
  myProfileUpdatePassword,
  messageTemplateTag,
  messageTemplate,
  receipts,
  downloadReceipts,
  emailNotificationSettings,
  toggleTheme,

  createPaymentIntent,
  updateIsRead,
  updateCandidateReview,

  stripeCreateSetupIntent,
  getStripeCard,
  deleteStripeCard,
  defaultStripeCard,
  candidateOverviewMessage,
  clearCandidateReview,

  onboardingVerify,
  onboardingRegister,

  notifications,
  createSingleCampaign,
  createBoostedCampaign,
  updateBoostedCampaign,
  getCampaignProducts,
  getCampaignProduct,

  activeJobs,
  jobViews,
  applications,
  unreadCandidates,
  viewsChart,
  applicationsChart,
  workSituationChart,
  employmentTypeChart,
  advertisingMixChart,
  jobsCreatedChart,
  hiringProgress,
};
