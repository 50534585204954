import React from "react";
import { MultiSelect } from "react-multi-select-component";
import clsx from "clsx";

import { IconContainer, MultiSelectContainer } from "./CandidateListStyle";
import { Typography } from "src/components/Basic";
import { IPipeLine } from "../PipeLine";
import { ICONS } from "src/assets/custom_Icons";

const customOptionRenderer = ({
  checked,
  option,
  onClick,
  disabled,
}: IPipeLine.ICustomOptionRenderer) => {
  return (
    <div className="d-flex align-items-center justify-content-between ">
      <div className="d-flex align-items-center gap-2 ">
        <input
          type="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
        />
        <span className="checkmark"></span>
        <div className="option-label-rating">{option.label}</div>
      </div>
      {option.icon}
    </div>
  );
};

const customValueRenderer = (selectedOption: any) => {
  return (
    <>
      {selectedOption.length === 4 ? (
        <Typography content="All" color="black100" styleName="tableButton" />
      ) : selectedOption.length > 0 ? (
        selectedOption.map((option: IPipeLine.RatingOption) => (
          <IconContainer
            issmall={selectedOption.length === 3}
            className={clsx(option.label === "Unrated" && "unrated")}
          >
            {option.icon}
          </IconContainer>
        ))
      ) : (
        <Typography content="Rating" color="black100" styleName="tableButton" />
      )}
    </>
  );
};

const options: IPipeLine.RatingOption[] = [
  {
    label: "Unrated",
    value: 0,
    icon: <ICONS.HappyRatingIcon color="black_pure" />,
  },
  {
    label: "Good fit",
    value: 3,
    icon: <ICONS.HappyRatingIcon color="success_default" />,
  },
  {
    label: "Maybe",
    value: 2,
    icon: <ICONS.NormalRatingIcon color="warning_default" />,
  },
  {
    label: "Not a fit",
    value: 1,
    icon: <ICONS.SadRatingIcon color="danger_default" />,
  },
];

const RatingDropDown: React.FC<IPipeLine.IRatingDropDown> = ({
  selected,
  setSelected,
}) => {
  return (
    <MultiSelectContainer>
      <MultiSelect
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy="Select"
        disableSearch={true}
        overrideStrings={{
          selectSomeItems: "Rating",
          selectAll: "All",
        }}
        ItemRenderer={customOptionRenderer}
        valueRenderer={customValueRenderer}
        ClearIcon={<div></div>}
        ArrowRenderer={() => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="4"
            viewBox="0 0 10 4"
            fill="none"
          >
            <path
              d="M9.36035 7.15256e-07L0.360352 -7.15493e-08L4.85476 4L9.36035 7.15256e-07Z"
              fill="#222020"
            />
          </svg>
        )}
      />
    </MultiSelectContainer>
  );
};

export default RatingDropDown;
