import { FormFieldsProps } from "src/components/Basic/FormFields";
export const idPrefix = "candidate-profile";

const subject: FormFieldsProps.Input = {
  placeholder: "Subject",
  label: "Subject",
  fieldName: "subject",
  idPrefix,
  isRequired: true,
};
const message: FormFieldsProps.RichTextarea = {
  fieldName: "message",
  idPrefix,
  label: "Message",
  isRequired: true,
  placeholder: "Message",
};

export const candidateProfileFields = { subject, message };
