import { useEffect } from "react";

import { ICONS } from "src/assets/custom_Icons";
import { Typography } from "src/components/Basic";
import CareerAccordian from "./Accordion/CareerAccordian";
import { useAppSelector } from "src/Redux";
import EducationAccordion from "./Accordion/EducationAccordion";
import { API_CALLS } from "src/API_CALLS";
import {
  AccordianContainer,
  ExperienceContainer,
  HeadingContainer,
  IconContainer,
  ExperienceNotAddedContainer,
} from "./ExperienceStyle";

const Experience = () => {
  const { careerHistory, educationHistory, selectedCandidate } = useAppSelector(
    (state) => state.candidate
  );

  useEffect(() => {
    if (
      careerHistory.length === 0 &&
      educationHistory.length === 0 &&
      selectedCandidate.section_experience
    )
      API_CALLS.PIPELINE.getCandidateExperience(selectedCandidate.candidate_id);
  }, []);

  return (
    <ExperienceContainer>
      <HeadingContainer className="heading">
        <Typography
          content="Experience"
          styleName="candidateNameEmailOpen"
          color="primary_default"
        />
      </HeadingContainer>
      {careerHistory?.length <= 0 && educationHistory?.length <= 0 ? (
        <>
          <ExperienceNotAddedContainer className="no-data-msg">
            <Typography
              content={
                selectedCandidate.section_experience
                  ? "Loading..."
                  : "No Experience added."
              }
              styleName="text1"
              color="black80"
            />
          </ExperienceNotAddedContainer>
          <IconContainer>
            <ICONS.BriefCaseIconWithVerticalLine />
          </IconContainer>
        </>
      ) : (
        <>
          <AccordianContainer>
            <div className="heading">
              <Typography
                content="Career History"
                styleName="subtitle3"
                color="black80"
              />
            </div>
            {careerHistory?.length > 0 ? (
              <CareerAccordian accordianData={careerHistory} />
            ) : (
              <HeadingContainer className="no-data-msg">
                <Typography
                  content="No Career History added."
                  styleName="text1"
                  color="black80"
                />
              </HeadingContainer>
            )}
          </AccordianContainer>
          <AccordianContainer>
            <div className="mt-3 heading">
              <Typography
                content="Education History"
                styleName="subtitle3"
                color="black80"
              />
            </div>
            {educationHistory?.length > 0 ? (
              <EducationAccordion accordianData={educationHistory} />
            ) : (
              <HeadingContainer className="no-data-msg">
                <Typography
                  content="No Education History added."
                  styleName="text1"
                  color="black80"
                />
              </HeadingContainer>
            )}
          </AccordianContainer>
        </>
      )}
    </ExperienceContainer>
  );
};

export default Experience;
