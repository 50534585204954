import styled, { keyframes } from "styled-components";
import { theme } from "src/components/Theme";

const CandidateOverviewSkeleton = () => {
  return (
    <FlexBox flexDirection="column">
      <FlexBox padding="20px 24px 32px 19px" justifyContent="space-between">
        <FlexBox gap="24px">
          <Skeleton width="180px" height="180px" radius="6px" />
          <FlexBox flexDirection="column" padding="12px 0 0 0">
            <FlexBox flexDirection="column" gap="4px">
              <Skeleton width="152px" height="28px" />
              <Skeleton width="152px" height="24px" />
            </FlexBox>
            <FlexBox flexDirection="column" gap="4px" padding="8px 0 16px">
              {Array.from({ length: 3 }, (_, key) => (
                <Skeleton width="192px" key={key} />
              ))}
            </FlexBox>
            <Skeleton width="152px" />
          </FlexBox>
        </FlexBox>
        <FlexBox flexDirection="column" justifyContent="space-between">
          <FlexBox gap="22px" alignItems="center" padding="10px 0 0 0">
            <Skeleton width="45px" height="45px" radius="100%" hide={true} />
            <Skeleton width="130px" height="37px" />
          </FlexBox>
          <FlexBox gap="22px" alignItems="center" justifyContent="end">
            <Skeleton width="132px" />
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <TabHeaderSkeleton />
      <FlexBox padding="0 0 76px 38px" flexDirection="column" gap="36px">
        <Container>
          <FlexBox gap="16px" flexDirection="column">
            <Skeleton width="116px" height="28px" />
            <FlexBox gap="4px" flexDirection="column">
              {Array.from({ length: 5 }, (_, key) => (
                <Skeleton width="192px" key={key} />
              ))}
            </FlexBox>
          </FlexBox>
          <Skeleton width="99px" height="37px" margin="28px 0 0" />
        </Container>
        <FlexBox flexDirection="column" gap="4px">
          <Skeleton width="116px" height="28px" />
          <Skeleton width="312px" height="26px" />
        </FlexBox>
        <FlexBox flexDirection="column" gap="4px">
          <Skeleton width="156px" height="28px" />
          <Skeleton width="52px" />
        </FlexBox>
        <FlexBox flexDirection="column" gap="4px">
          <Skeleton width="116px" height="28px" />
          <Skeleton width="312px" height="26px" />
        </FlexBox>
        {Array.from({ length: 3 }, (_, key) => (
          <FlexBox gap="32px" key={key}>
            {Array.from({ length: 2 }, (_, key) => (
              <FlexBox gap="4px" flexDirection="column" key={key}>
                <Skeleton width="160px" height="24px" />
                <Skeleton width="52px" />
              </FlexBox>
            ))}
          </FlexBox>
        ))}
      </FlexBox>
    </FlexBox>
  );
};

export default CandidateOverviewSkeleton;

type SkeletonProps = {
  width: string;
  height?: string;
  radius?: string;
  padding?: string;
  margin?: string;
  hide?: boolean;
};
type FlexBoxProps = {
  gap?: string;
  flexDirection?: string;
  justifyContent?: string;
  alignItems?: string;
  padding?: string;
  margin?: string;
};

const shimmer = keyframes`
  0% {
    background-position: 200%;
  }
  100% {
    background-position: -200%;
  }
`;

const FlexBox = styled.div<FlexBoxProps>`
  display: flex;
  gap: ${({ gap }) => gap && gap};
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "row"};
  justify-content: ${({ justifyContent }) => justifyContent && justifyContent};
  align-items: ${({ alignItems }) => alignItems && alignItems};
  padding: ${({ padding }) => padding && padding};
  margin: ${({ margin }) => margin && margin};
`;

const Container = styled.div`
  display: flex;
  gap: 60px;
  @media (min-width: 992px) and (max-width: 1439px) {
    align-items: flex-end;
  }
`;

const Skeleton = styled.div<SkeletonProps>`
  width: ${({ width }) => width};
  height: ${({ height }) => (height ? height : "20px")};
  border-radius: ${({ radius }) => (radius ? radius : "4px")};
  background: linear-gradient(
    90deg,
    ${theme.colors.pipeline_skeleton} 33%,
    ${theme.colors.pipeline_skeleton_overlay} 66%,
    ${theme.colors.pipeline_skeleton} 100%
  );
  background-size: 200% 100%;
  animation: ${shimmer} 2s infinite;
  padding: ${({ padding }) => padding && padding};
  margin: ${({ margin }) => margin && margin};
  @media (min-width: 992px) and (max-width: 1439px) {
    display: ${({ hide }) => hide && "none"};
  }
  .dark-theme & {
    background: ${theme.colors.black120};
    background: linear-gradient(
      90deg,
      ${theme.colors.black120} 33%,
      ${theme.colors.black130} 66%,
      ${theme.colors.black120} 100%
    );
    background-size: 200% 100%;
    animation: ${shimmer} 2s infinite;
  }
`;

const TabHeaderSkeleton = styled.div`
  width: 660px;
  height: 42px;
  border-radius: 4px;
  margin: 0 0 16px 24px;
  background: linear-gradient(
    90deg,
    ${theme.colors.pipeline_skeleton} 33%,
    ${theme.colors.pipeline_skeleton_overlay} 66%,
    ${theme.colors.pipeline_skeleton} 100%
  );
  background-size: 200% 100%;
  animation: ${shimmer} 2s infinite;
  @media (min-width: 992px) and (max-width: 1439px) {
    width: 581px;
    margin: 0 auto 16px;
  }
  .dark-theme & {
    background: linear-gradient(
      90deg,
      ${theme.colors.black120} 33%,
      ${theme.colors.black130} 66%,
      ${theme.colors.black120} 100%
    );
    background-size: 200% 100%;
    animation: ${shimmer} 2s infinite;
  }
`;
