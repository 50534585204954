import styled from "styled-components";
import { Typography } from "../Typography";
import "./FormFieldsStyles/formFields-dark.css";
import "./FormFieldsStyles/formFields.css";
import { InfoIcon } from "./Icons/InfoIcon";
import { Popover, OverlayTrigger, Button } from "react-bootstrap";

type TitleProps = {
  content: string;
  className?: string;
};
const Label = ({ content, className = "" }: TitleProps) => (
  <Typography
    className={`input-title ${className}`}
    content={content}
    styleName="inputLabel"
    color="black80"
  />
);
const AdditionalLabel = ({ content, className = "" }: TitleProps) => (
  <Typography
    className={`input-title ${className}`}
    content={content}
    styleName="inputGeneral"
    color="black80"
  />
);
const LabelPlaceHolder = () => <div style={{ height: "29px" }} />;
const Placeholder = ({ content, className = "" }: TitleProps) => (
  <Typography
    className={`input-placeholder ${className}`}
    content={content}
    styleName="inputGeneral"
    color="grey_placeholder"
  />
);
const Error = ({ content, className = "" }: TitleProps) => (
  <Typography
    className={`input-error-label ${className}`}
    content={content}
    styleName="text_11pt_02"
    color="red100"
  />
);
const Success = ({ content, className = "" }: TitleProps) => (
  <Typography
    className={className}
    content={content}
    styleName="text_11pt_02"
    color="success_default"
  />
);

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 13px;
`;
type infoProps = { helpText: { title: string; info: string } };
const Info = ({ helpText }: infoProps) => {
  return (
    <InfoContainer id={helpText.title}>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement="bottom"
        overlay={
          <Popover
            style={{ margin: "0px" }}
            className="popover-head-primary bs-popover-bottom"
          >
            <Popover.Header as="h3" className="popover-headers">
              {helpText.title}
            </Popover.Header>
            <Popover.Body style={{ margin: "0px" }}>
              {helpText.info}
            </Popover.Body>
          </Popover>
        }
      >
        <Button variant="" className="btn btn-primary">
          <InfoIcon />
        </Button>
      </OverlayTrigger>
    </InfoContainer>
  );
};
const InfoContainer = styled.div`
  .btn-primary {
    background: transparent !important;
    border: 0;
    padding: 0;
  }
`;

export const FormFieldCommon = {
  Label,
  AdditionalLabel,
  Placeholder,
  Error,
  Success,
  LabelPlaceHolder,
  InfoWrapper,
  Info,
};
