import { useContext } from "react";

import { Typography } from "src/components/Basic";
import { CreateJobAdvertiseCommon } from "./Common";
import { AdvertiseStyles } from "./Styles";
import { AdvertiseUtils } from "./Utils";
import { AppContext } from "src/App";
import { AdvertiseContext } from ".";

export const TabletAdvertCardsTitle = () => {
  const { isTablet } = useContext(AppContext);
  const { handleCardClick, isStandardOpen, isPremiumOpen, isBoostedOpen } =
    useContext(AdvertiseContext);
  const { boosted, premium, standard } = AdvertiseUtils.points;
  const { AdvertiseTitle, AdvertisePrice } = CreateJobAdvertiseCommon;

  return (
    <AdvertiseStyles.CardList>
      <AdvertiseStyles.CardListItem
        onClick={() => handleCardClick("standard")}
        advertType="standard"
        isOpen={isStandardOpen}
        className="advertise-card"
      >
        <AdvertiseTitle title={standard.title} isOpen={isStandardOpen} />
        <AdvertisePrice
          price={standard.price.price}
          isOpen={isStandardOpen}
          isStriked={standard.price.isStriked}
        />
        {(isTablet || !isStandardOpen) && (
          <AdvertiseStyles.AdvertsLeftTitle>
            <Typography
              content="3 Free Adverts Left"
              styleName="jobPill"
              color="white_pure"
            />
          </AdvertiseStyles.AdvertsLeftTitle>
        )}
      </AdvertiseStyles.CardListItem>
      <AdvertiseStyles.CardListItem
        onClick={() => handleCardClick("premium")}
        advertType="premium"
        isOpen={isPremiumOpen}
        className="advertise-card"
      >
        <AdvertiseTitle title={premium.title} isOpen={isPremiumOpen} />
        <AdvertisePrice price={premium.price.price} isOpen={isPremiumOpen} />
      </AdvertiseStyles.CardListItem>
      <AdvertiseStyles.CardListItem
        onClick={() => handleCardClick("boosted")}
        advertType="boosted"
        isOpen={isBoostedOpen}
        className="advertise-card"
      >
        <AdvertiseTitle title={boosted.title} isOpen={isBoostedOpen} />
        {(isTablet || !isBoostedOpen) && (
          <AdvertiseStyles.SetCampaignBudgetTitle isOpen={isBoostedOpen}>
            <Typography
              content="Set your own campaign budgets"
              styleName="jobPill"
              color={isBoostedOpen ? "primary_default" : "white_pure"}
            />
          </AdvertiseStyles.SetCampaignBudgetTitle>
        )}
      </AdvertiseStyles.CardListItem>
    </AdvertiseStyles.CardList>
  );
};
