import { useEffect, useState } from "react";
import styled from "styled-components";

import { Table } from "./Table";
import { PagesCommon } from "src/components/pages/Common";
import { DoughnutChartUtils } from "./Utils";
import { store, ReportsProps, useAppSelector } from "src/Redux";
import { ReportsPageProps } from "../Reports";
import { DoughnutChart } from "./DoughnutChart";
import { API_CALLS } from "src/API_CALLS";
import { ERROR_CODES } from "src/API_CALLS/utils";

const updateBorderColor = (data: any[]) => {
  const { isDarkTheme } = store.getState().common;
  const borderColor = isDarkTheme ? "#2a2e3f" : "#fff";

  data.forEach((item) => {
    item.itemStyle = { ...item.itemStyle, borderColor };
  });
};

//#region Get Options
const getWorkSituationData = (
  response: ReportsProps.WorkSituationChartResponse,
  isThemeChanged: boolean
) => {
  const { workSituationDefaultData, workSituationDefaultTypes } =
    DoughnutChartUtils;
  const [hybrid, onsite, remote] = workSituationDefaultData as any[];
  let types = workSituationDefaultTypes;
  if (response.types && Object.keys(response.types).length > 0) {
    types = response.types;

    hybrid.value = types["Hybrid"].percentage;
    onsite.value = types["On-Site"].percentage;
    remote.value = types["Remote"].percentage;
  }

  isThemeChanged && updateBorderColor(workSituationDefaultData);
  return [{ ...hybrid }, { ...onsite }, { ...remote }];
};
const getEmploymentTypeData = (
  response: ReportsProps.EmploymentTypeChartResponse,
  isThemeChanged: boolean
) => {
  const { employmentTypeDefaultData, employmentTypeDefaultTypes } =
    DoughnutChartUtils;
  const [
    contract,
    freelance,
    fullTime,
    internship,
    other,
    partTime,
    temporary,
    volunteer,
  ] = employmentTypeDefaultData as any[];

  let types = employmentTypeDefaultTypes;
  if (response.types && Object.keys(response.types).length > 0) {
    types = response.types;
    contract.value = types["Contract"]?.percentage;
    freelance.value = types["Freelance"]?.percentage;
    fullTime.value = types["Full-Time"]?.percentage;
    internship.value = types["Internship"]?.percentage;
    other.value = types["Other"]?.percentage;
    partTime.value = types["Part-Time"]?.percentage;
    temporary.value = types["Temporary"]?.percentage;
    volunteer.value = types["Volunteer"]?.percentage;
  }

  isThemeChanged && updateBorderColor(employmentTypeDefaultData);
  return [
    { ...contract },
    { ...freelance },
    { ...fullTime },
    { ...internship },
    { ...other },
    { ...partTime },
    { ...temporary },
    { ...volunteer },
  ];
};
//#endregion

//#region Get Table Data
const getWorkSituationTableData = (
  response: ReportsProps.WorkSituationChartResponse
) => {
  if (!response.types) return [];
  const keys = Object.keys(response.types) as ReportsProps.workSituationKeys[];

  return keys.map((key) => {
    const value = response.types[key];
    return {
      color: DoughnutChartUtils.workSituationColors[key],
      label: key,
      value: value.value,
      percent: value.percentage,
    };
  });
};
const getEmploymentTypeTableData = (
  response: ReportsProps.EmploymentTypeChartResponse
) => {
  if (!response.types) return [];
  const keys = Object.keys(response.types) as ReportsProps.employmentTypeKeys[];

  return keys.map((key) => {
    const value = response.types[key];
    return {
      color: DoughnutChartUtils.employmentTypeColors[key],
      label: key,
      value: value.value,
      percent: value.percentage,
    };
  });
};
//#endregion

export const DoughnutCharts = ({
  chartType,
}: ReportsPageProps.DoughnutCharts) => {
  const { reports, common } = useAppSelector((store) => store);
  const {
    workSituationChartResponse,
    employmentTypeChartResponse,
    workSituationChartCompleted,
    employmentTypeChartCompleted,
    workSituationChartErrorCode,
    employmentTypeChartErrorCode,
  } = reports;
  const { isDarkTheme } = common;

  const [theme, setTheme] = useState(isDarkTheme);
  if (theme !== isDarkTheme) setTheme(isDarkTheme);

  const data: ReportsPageProps.DoughnutChartData = {
    "work-situation": {
      response: workSituationChartResponse,
      title: "Work Situation",
      getChartData: getWorkSituationData,
      getTableData: getWorkSituationTableData,
      defaultData: DoughnutChartUtils.workSituationDefaultData,
      apiCall: API_CALLS.REPORTS.getWorkSituationChart,
      apiCallCompleted: workSituationChartCompleted,
      errorCode: workSituationChartErrorCode,
    },
    "employment-type": {
      response: employmentTypeChartResponse,
      title: "Employment Type",
      getChartData: getEmploymentTypeData,
      getTableData: getEmploymentTypeTableData,
      defaultData: DoughnutChartUtils.employmentTypeDefaultData,
      apiCall: API_CALLS.REPORTS.getEmploymentTypeChart,
      apiCallCompleted: employmentTypeChartCompleted,
      errorCode: employmentTypeChartErrorCode,
    },
  };
  const {
    response,
    title,
    getChartData,
    getTableData,
    apiCall,
    apiCallCompleted,
    errorCode,
  } = data[chartType];

  useEffect(() => {
    if (apiCallCompleted) return;
    apiCall();
  }, [apiCallCompleted, apiCall]);

  const tableData = getTableData(response);
  const newData = getChartData(response, theme === isDarkTheme);
  const { options, series } = DoughnutChartUtils;

  const hasNoResultsError = ERROR_CODES.NO_RESULTS_ERROR === errorCode;
  return (
    <Container setHeight={hasNoResultsError}>
      <TitleWrapper>
        <PagesCommon.Components.ChartTitle title={title} />
      </TitleWrapper>
      {hasNoResultsError ? (
        <PagesCommon.Components.InsufficientData />
      ) : (
        <ChartAndTable>
          <ChartContainer>
            <DoughnutChart
              option={{
                ...options,
                series: [{ ...series[0], data: [...newData] }],
              }}
            />
          </ChartContainer>
          <Table values={tableData} />
        </ChartAndTable>
      )}
    </Container>
  );
};

const TitleWrapper = styled.div`
  padding-left: 24px;
`;
const ChartAndTable = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 576px) {
    flex-direction: row;
  }
`;
const Container = styled(PagesCommon.Styles.ChartContainer)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-left: 0;
  height: ${({ setHeight }) => (setHeight ? "267px" : "auto")};
`;
const ChartContainer = styled.div`
  height: 200px;
  width: 200px;
  margin: auto;
`;
