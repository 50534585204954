import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const TerminationOfUse = () => (
  <>
    <Points.Heading pointNumber="13." heading="Termination of Use" />
    <Points.Indent>
      <>
        <Points.Bold pointNumber="13.1." Content={POINT_13_1} />
        <Points.Span pointNumber="13.2." Content={POINT_13_2} />
        <Points.Span pointNumber="13.3." Content={POINT_13_3} />
        <Points.Span pointNumber="13.4." Content={POINT_13_4} />
      </>
    </Points.Indent>
  </>
);

const POINT_13_1 = (
  <Points.Content>
    <Text.Bold content="In addition to its other rights herein, Ditto Media reserves the right to restrict and/or terminate your profile and/or use of Nova if you breach any of these Terms, or for any other reason in its sole discretion provided that Ditto Media gives reasonable notice to you." />
  </Points.Content>
);
const POINT_13_2 = (
  <Points.Content>
    <Text.Span content="Depending on the severity of the breach, we may in our sole discretion, reinstate your account following restriction thereof. " />
  </Points.Content>
);
const POINT_13_3 = (
  <Points.Content>
    <Text.Span content="If you wish to terminate your agreement with us and these Terms, or end your use of Nova, you may do so by deregistering your profile with Nova, discontinuing your payment of a subscription fee, and ending your use of Nova/Services. Such deregistration from Nova will however not have any effect on the continued and comprehensive functioning or legitimacy of any lawful rights which we may have at the time of said termination." />
  </Points.Content>
);
const POINT_13_4 = (
  <Points.Content>
    <Text.Span content="If you cancel your agreement with these Terms and with Ditto Media, we will delete your profile within a reasonable period." />
  </Points.Content>
);
