import { useEffect } from "react";
import styled from "styled-components";

import { useAppSelector } from "src/Redux";
import { CardComponent } from "./CardComponent";
import { CardGroupProps } from "./CardGroup";
import { CardGroupUtils } from "./utils";
import { API_CALLS } from "src/API_CALLS";

//#region CardIcon
const CardIcon = ({ cardType }: CardGroupProps.CardIcon) => {
  const card = CardGroupUtils.CARDS[cardType];
  return (
    <CardIconContainer background={card.background}>
      {card.icon}
    </CardIconContainer>
  );
};
const CardIconContainer = styled.div<{ background: string }>`
  height: 40px;
  width: 40px;

  border-radius: 5px;
  background: ${({ background }) => background};
  display: flex;
  align-items: center;
  justify-content: center;
`;
//#endregion

export const CardGroup = ({ type }: CardGroupProps.IProps) => {
  const { cardGroupAPICallsCompleted, cardGroupData } = useAppSelector(
    (state) => state.reports
  );

  useEffect(() => {
    if (cardGroupAPICallsCompleted) return;

    API_CALLS.REPORTS_CARD_GROUP.getCardGroupData();
  }, [cardGroupAPICallsCompleted]);

  return (
    <Container type={type}>
      <Wrapper>
        <CardComponent
          icon={<CardIcon cardType="active_jobs" />}
          title="Live Jobs"
          totalCount={cardGroupData.active_jobs}
        />
        <CardComponent
          icon={<CardIcon cardType="unread_candidates" />}
          title="Unread Candidates"
          totalCount={cardGroupData.unread}
        />
      </Wrapper>
      <Wrapper>
        <CardComponent
          icon={<CardIcon cardType="views" />}
          title="Views"
          totalCount={cardGroupData.views}
          isShownLastWeek={true}
          isBottom={true}
        />
        <CardComponent
          icon={<CardIcon cardType="applications" />}
          title="Applications"
          totalCount={cardGroupData.applications}
          isShownLastWeek={true}
          isBottom={true}
        />
      </Wrapper>
    </Container>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
const Container = styled.div<CardGroupProps.IProps>`
  display: flex;
  gap: 20px;
  flex-direction: column;
  width: 100%;
  @media (min-width: 1200px) {
    flex-direction: ${({ type }) => (type === "full" ? "row" : "column")};
  }
`;
