import { Link } from "react-router-dom";
import { ICONS } from "../../../assets/custom_Icons";
import { ROUTES } from "src/utils/ROUTES";

const MobileLogo = () => {
  return (
    <div className="responsive-logo">
      <Link to={ROUTES.HOME} className="header-logo">
        <div className="mobile-logo logo-1">
          <ICONS.NovaBlack />
        </div>
        <div className="mobile-logo dark-logo-1">
          <ICONS.NovaWhite />
        </div>
      </Link>
    </div>
  );
};
export default MobileLogo;
