import React from "react";

import { Typography } from "src/components/Basic";
import { CandidateTabData } from "../../Utils";
import { ICandidateTabHeader } from "./CandidateTabHeader";
import {
  CandidateTabHeaderContainer,
  CountContainer,
  NavbarHeadingContainer,
} from "./CandidateTabHeaderStyle";
import { useAppSelector } from "src/Redux";

const CandidateTabHeader: React.FC<ICandidateTabHeader.IProps> = ({
  activeTab,
  setActiveTab,
}) => {
  const { selectedCandidate } = useAppSelector((state) => state.candidate);
  return (
    <CandidateTabHeaderContainer>
      <NavbarHeadingContainer
        onClick={() => setActiveTab("Summary")}
        style={{ width: "84px " }}
      >
        <Typography
          content="Summary"
          styleName={
            "Summary" === activeTab ? "pipelineActiveNav" : "pipelineDefaultNav"
          }
          color="black80"
        />
      </NavbarHeadingContainer>
      {CandidateTabData.map((tab, index) => (
        <NavbarHeadingContainer
          key={index}
          onClick={() => setActiveTab(tab.title)}
          style={{ width: `${tab.width}px` }}
          className={
            selectedCandidate.section_qualifiers === 0 && index === 4
              ? "not-show"
              : ""
          }
        >
          <Typography
            content={tab.title}
            styleName={
              tab.title === activeTab
                ? "pipelineActiveNav"
                : "pipelineDefaultNav"
            }
            color="black80"
          />
          <CountContainer>{selectedCandidate[tab.key]}</CountContainer>
        </NavbarHeadingContainer>
      ))}
    </CandidateTabHeaderContainer>
  );
};

export default CandidateTabHeader;
