import {
  Typography,
  TypographyLink,
  TypographySpan,
} from "src/components/Basic";
import { TermsProps } from "./Terms";

const color = "black80";
const Heading = ({ content }: TermsProps.Text) => (
  <Typography {...{ content, color, styleName: "termsContentHeading" }} />
);
const SubHeading = ({ content, needUnderline }: TermsProps.PointSubHeading) => (
  <Typography
    {...{ content, color, styleName: "termsContent" }}
    className={needUnderline ? "with-underline" : ""}
  />
);
const Span = ({ content }: TermsProps.Text) => (
  <TypographySpan {...{ content, color, styleName: "termsContent" }} />
);
const Div = ({ content }: TermsProps.Text) => (
  <Typography {...{ content, color, styleName: "termsContent" }} />
);
const SpanUnderline = ({ content }: TermsProps.Text) => (
  <TypographySpan
    className="with-underline"
    {...{ content, color, styleName: "termsContent" }}
  />
);
const Bold = ({ content }: TermsProps.Text) => (
  <TypographySpan {...{ content, color, styleName: "termsContentBold2" }} />
);
const Link = ({ content }: TermsProps.Text) => (
  <TypographyLink
    {...{ content, color: "primary_default", styleName: "termsContentLink" }}
  />
);
export const Text = {
  Heading,
  SubHeading,
  Span,
  Div,
  SpanUnderline,
  Bold,
  Link,
};
