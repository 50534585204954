import { ApexOptions } from "apexcharts";

export const option: ApexOptions = {
  chart: {
    height: 280,
    type: "line",
    zoom: {
      enabled: false,
    },
    dropShadow: {
      enabled: true,
      enabledOnSeries: undefined,
      top: 5,
      left: 0,
      blur: 3,
      color: "#000",
      opacity: 0.1,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    offsetX: -15,
    fontWeight: "bold",
  },
  stroke: {
    curve: "smooth",
    width: 3,
  },
  grid: {
    borderColor: "#f2f6f7",
  },
  colors: ["#24d5b8"],

  yaxis: {
    title: {
      text: "Growth",
      style: {
        color: "#adb5be",
        fontSize: "14px",
        fontFamily: "Inter",
        fontWeight: 700,
        cssClass: "apexcharts-yaxis-label",
      },
    },
  },
  xaxis: {
    labels: {
      formatter: function (value: string) {
        const date = new Date(value);
        return (
          date.toLocaleDateString("en-US", { weekday: "short" }) +
          "," +
          date.toLocaleDateString("en-US", { day: "numeric" }) +
          " " +
          date.toLocaleDateString("en-US", { month: "short" })
        );
      },
    },

    axisBorder: {
      show: true,
      color: "rgba(119, 119, 142, 0.05)",
      offsetX: 0,
      offsetY: 0,
    },
    axisTicks: {
      show: true,
      borderType: "solid",
      color: "rgba(119, 119, 142, 0.05)",
      offsetX: 0,
      offsetY: 0,
    },
  },
};
