import { CustomColorsProps } from "./ColorsProps";

const primaryColors: CustomColorsProps.primary = {
  primary_default: "#1E96F2",
  primary_hover: "#58B4FA",
  primary_disable: "#A0D5FC",
  primary10: "#D8EEFF",
  primary20: "#D2EAFC",
  primary100: "#72D2F9",
  primary150: "#1879C3",
  primary_diverging: "#3B82F6",
  primary200: "#C7E5FC",
  primary300: "#246298",
  primary110: "#8ECAF8",
};
const secondaryColors: CustomColorsProps.secondary = {
  secondary_default: "#F74F75",
  secondary_hover: "#ED345E",
  secondary_disable: "#F97B98",
  secondary_icon: "#FC7D9A",
};
const successColors: CustomColorsProps.success = {
  success_default: "#24D5B8",
  success_hover: "#44C6B0",
  success_disable: "#5BDFCA",
  success_20: "#D3F7F1",
  success500: "#38CAB3",
  success_outline: "#50DBC4",
};
const infoColors: CustomColorsProps.info = {
  info_default: "#4EC2F0",
  info_hover: "#69C3E7",
  info_disable: "#7AD1F4",
};
const warningColors: CustomColorsProps.warning = {
  warning_default: "#FFBD5A",
  warning_hover: "#F3B04B",
  warning_disable: "#FFCD83",
};
const dangerColors: CustomColorsProps.danger = {
  danger_default: "#F34343",
  danger_hover_click: "#DE2121",
  danger_disable: "#F67272",
};
const lightColors: CustomColorsProps.light = {
  light_default: "#F9F9FB",
  light_hover: "#DEDFE8",
  light_disable: "#D2D2D3",
  light_outline: "#F1F1FD",
};
const blackColors: CustomColorsProps.black = {
  black_pure: "#000000",
  black10: "#EDEDF5",
  black15: "#353A4B",
  black20: "#E2E5EE",
  black30: "#D5DCE8",
  black40: "#C2CAD8",
  black50: "#AEB7C6",
  black60: "#939DB5",
  black70: "#7A839B",
  black80: "#4A4A69",
  black85: "#383853",
  black90: "#323251",
  black100: "#222020",
  black110: "#2A2E3F",
  black120: "#383D52",
  black130: "#4A4F62",
  black140: "#3A4057",
  black150: "#EFECEC",
  black160: "#7987A1",
  black170: " #222530",
  black180: "#34394E",
  black190: "#3A3F54",
  black200: "#14112D",
  black210: "#294363",
  black220: " #415D7E",
  black_hover_click: "#DEDFE8",
  black300: "#1F1F25",
  black400: "#484863",
  nova_black: "#1E202D",
};
const greyColors: CustomColorsProps.grey = {
  grey_brown: "#565656",
  grey_brown_square_box: "#757675",
  grey_disable: "#EBEBEB",
  grey_disable_outline: "#CCCCCC",
  grey_icon: "#CCCCCC",
  grey_input_dropdown_hover: "#F0F4F8",
  grey_input_dropdown_hover_picked: "#5D6379",
  grey_input_dropdown_outline: "#D3D5D7",
  blue_grey: "#64728B",
  setting_grey: "#7E8288",
  grey_light: "#F1F3F5",
  grey10: "#BFC1D4",
  grey100: "#BFC8DE",
  grey150: "#A5A0B1",
  grey200: "#F7F7FC",
  grey110: "#D6DEED",
  grey20: "#EFF1F5",
  grey30: "#A9ABBD",
  grey40: "#7E8CA3",
  bigSquare_and_radio: "#CDD4E2",
  grey_placeholder: "#CFD4DD",
  placeholder_color: "#6E7385",
  grey_icon_bg: "#CBD5EC",
  grey_tag:"#E7E6E5",
  overlay: "#0606091F",
  page_background: "#F4F5F4",
  pipeline_skeleton: "#D9D9D9",
  pipeline_skeleton_overlay: "#f0f0f0",
  notification_hover: "#E2E6EA",
};
const pagination: CustomColorsProps.pagination = {
  pagination: "#596882",
  settings_icon: "#7E8288",
  blue_grey_80: "#4A4A69",
  grey_subtitle1: "#ACAFC1",
  grey_subtitle2: "#EAECF3",
  jobCheckoutCardNumber: "#252F40",
};
const whiteColors: CustomColorsProps.whiteColors = {
  white10: "#DBDCDE",
  white20: "#EDEDED",
  white30: "#F8F8F8",
  white40: "#C0C1C5",
  white_pure: "#FFFFFF",
  white100: "#faf9fa ",
  white200: "#D2EAFC",
  white300: "#F4F5F4",
};
const redColors: CustomColorsProps.redColors = { red100: "#E91145" };
const yellowColors: CustomColorsProps.yellowColors = { yellow10: "#FFFCF8" };
const darkThemeColors: CustomColorsProps.darkTheme = {
  dark_page_background: "#383d52",
  dark_input_background: "#2a2e3f",
  dark_input_label: "#CFDAEC",
  dark_form_background: "#2a2e3f",
  dark_page_content_title: "#ACAFC1",
  dark_page_content_subtitle: "#EAECF3",
  dark_input_text: "#C0C2C8",
  dark_input_placeholder: "#767884",

  dark_primary_default: "#1e96f2",
  dark_input_background_dim: "#aab0b8",
  dark_input_border: "#484863",
  dark_input_hover_border: "#256a5f",
  dark_input_error_border: "#ed345e",
  dark_dropdown_option_bg: "#3f4252",
  dark_dropdown_option_text: " white",
  dark_dropdown_option_selected_bg: "#5d6379",
  dark_dropdown_option_hover_bg: "#4a4f62",
  dark_dropdown_option_hover_text: " white",
  dark_dropdown_option_color: "#6e7385",
  dark_richtextarea_toolbar: "#bfc8de",
};
const text: CustomColorsProps.text = { text_grey: "#697386" };
const neutral: CustomColorsProps.neutralColors = {
  neutral50: "#737373",
  neutral70: "#404040",
};

export const colors: CustomColorsProps.props = {
  ...primaryColors,
  ...secondaryColors,
  ...successColors,
  ...infoColors,
  ...warningColors,
  ...dangerColors,
  ...lightColors,
  ...blackColors,
  ...greyColors,
  ...pagination,
  ...whiteColors,
  ...redColors,
  ...yellowColors,
  ...darkThemeColors,
  ...text,
  ...neutral,
};

export const colorsObject = {
  primaryColors,
  secondaryColors,
  successColors,
  infoColors,
  warningColors,
  dangerColors,
  lightColors,
  blackColors,
  greyColors,
  pagination,
  whiteColors,
  redColors,
  yellowColors,
  darkThemeColors,
  text,
  neutral,
};
