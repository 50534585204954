import { useContext } from "react";
import styled from "styled-components";

import { theme } from "src/components/Theme";
import { Typography } from "src/components/Basic";
import { AppContext } from "src/App";

import { Header } from "./Header";
import { TermsAndConditionsContent } from "./Contents";
import { Footer } from "./Footer";
import { Backbutton } from "./Contents/BackButton";

export const TermsAndConditions = () => {
  const { isTabletOrBig } = useContext(AppContext);
  return (
    <Container className="terms_and_conditions">
      <Header />
      <PageHeading>
        <Typography
          content="LEGAL"
          styleName="termsPageHeading"
          color="black80"
        />
        {!isTabletOrBig && <Backbutton />}
      </PageHeading>
      <TermsAndConditionsContent />
      {isTabletOrBig && <Footer />}
    </Container>
  );
};

const Container = styled.div`
  min-height: 100vh;
  height: 100%;
  background-color: ${theme.colors.page_background};
  padding: 104px 8px 8px 8px;
  @media (min-width: 576px) {
    padding-bottom: 28px;
  }
  @media (min-width: 768px) {
    padding-bottom: 83px;
  }
`;
const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0 12px;
  @media (min-width: 576px) {
    padding-left: 20px;
  }
  @media (min-width: 768px) {
    padding-left: 40px;
  }
  @media (min-width: 1200px) {
    padding-left: 123px;
  }
`;
