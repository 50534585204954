import styled from "styled-components";
import { theme } from "src/components/Theme";

export const SettingContainer = styled.div<{ isShow: boolean }>`
  padding: 40px 13px 0px;
  width: 992px;
  margin: auto;
  @media (min-width: 1440px) {
    width: 1440px !important;
    padding: 40px 127px 0px 120px;
    position: relative;
  }
  @media (min-width: 1200px) {
    width: 1200px;
  }
  .back-button {
    display: none;
  }
  @media (max-width: 991px) {
    width: 100%;
    padding: 20px 0px 27px;
    background: ${({ isShow }) => isShow && theme.colors.white_pure};
    & > .settings-heading {
      margin: 0 10px 12px 12px;
    }
    .dark-theme & {
      background: ${theme.colors.black110};
    }
    .back-button {
      display: ${({ isShow }) => (isShow ? "none" : "flex")};
      cursor: pointer;
      label {
        cursor: pointer;
      }
      .dark-theme & {
        label {
          color: ${theme.colors.white10};
        }
        svg path {
          fill: ${theme.colors.white10};
        }
      }
    }
  }
  .dark-theme & > div > label {
    @media (min-width: 992px) {
      color: ${theme.colors.black10};
    }
  }
`;
export const Container = styled.div<{ isheadershow: boolean }>`
  display: flex;
  gap: 24px;
  margin: 40px 0px 0px 3px;
  @media (max-width: 991px) {
    flex-direction: column;
    margin: 0px;
    gap: 0px;
    & > div:first-child {
      display: ${({ isheadershow }) => (isheadershow ? "flex" : "none")};
      width:100%;
    }
    & > div:last-child {
      display: ${({ isheadershow }) => (isheadershow ? "none" : "flex")};
    }
  }
`;
