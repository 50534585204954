import { Form, InputGroup } from "react-bootstrap";
import { Field, FieldProps } from "formik";
import "react-datepicker/dist/react-datepicker.css";

import { FormFieldsProps } from "../../FormFields";
import { FormFieldCommon } from "../../FormFieldsCommon";
import { FieldIcons } from "../../Icons";
import { InputWithIncrementButtonContainer } from "./inputWithIncrementButtonStyle";

export const InputWithIncrementButton = (
  props: FormFieldsProps.InputWithIncrementButton
) => {
  const {
    idPrefix,
    fieldName,
    label,
    disabled = false,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    allowNegativeNumbers = false,
    showLabel = true,
    valueSuffix,
  } = props;

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, form }: FieldProps<number>) => {
        const { setFieldValue } = form;
        const { value = 0, onBlur } = field;

        const increment = () =>
          setFieldValue(fieldName, parseInt(value.toString()) + 1);
        const decrement = () => {
          if (value === 0) {
            allowNegativeNumbers &&
              setFieldValue(fieldName, parseInt(value.toString()) - 1);
            return;
          }
          setFieldValue(fieldName, parseInt(value.toString()) - 1);
        };

        return (
          <InputWithIncrementButtonContainer disabled={disabled}>
            {showLabel && label && <FormFieldCommon.Label content={label} />}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <InputGroup className="input-group reactdate-pic">
              <InputGroup.Text
                className="input-field-icon input-field-icon-left"
                onClick={decrement}
              >
                <FieldIcons.Decrement />
              </InputGroup.Text>
              <Form.Control
                id={`${idPrefix}_${fieldName}`}
                type="text"
                className="form-control"
                name={fieldName}
                placeholder={placeholder}
                onChange={(e) => {
                  const prevValue = value;
                  const newNumber = Number.parseInt(e.target.value);
                  if (newNumber !== prevValue) {
                    if (newNumber < 0) {
                      allowNegativeNumbers &&
                        setFieldValue(fieldName, newNumber || 0);
                      return;
                    }
                    setFieldValue(fieldName, newNumber || 0);
                  }
                }}
                value={`${value} ${valueSuffix}`}
                disabled={disabled}
                onBlur={onBlur}
              />
              <InputGroup.Text
                className="input-field-icon input-field-icon-right"
                onClick={increment}
              >
                <FieldIcons.Increment />
              </InputGroup.Text>
            </InputGroup>
          </InputWithIncrementButtonContainer>
        );
      }}
    </Field>
  );
};
