import { StripeProps } from "src/Redux";
import instance from "src/axios/axios-user";
import { API_PATHS } from "src/utils/API_PATHS";

const createPaymentIntent = (values: StripeProps.createPaymentIntent) =>
  instance.post(API_PATHS.createPaymentIntent, JSON.stringify(values));

const createSetupIntent = () => instance.get(API_PATHS.stripeCreateSetupIntent);

const getStripeCard = () => instance.get(API_PATHS.getStripeCard);

const deleteStripeCard = (id: string) =>
  instance.delete(`${API_PATHS.deleteStripeCard}/${id}`);

const defaultStripeCard = (id: string) =>
  instance.put(`${API_PATHS.defaultStripeCard}/${id}`);

export const STRIPE = {
  createPaymentIntent,
  createSetupIntent,
  getStripeCard,
  deleteStripeCard,
  defaultStripeCard,
};
