import { EChartsOption, PieSeriesOption } from "echarts";

import { theme } from "src/components/Theme";
import { ReportsProps } from "src/Redux";

const initialTypeValue = { percentage: 0, value: 0 };

const COLORS = [
  "#D0BCFF",
  "#6BFBE3",
  "#FC7D9A",
  "#FF9F5A",
  "#A855F7",
  "#3C82F6",
  "#20BDBD",
  "#FACC15",
];

const series: EChartsOption["series"] = [
  {
    cursor: "default",
    tooltip: { trigger: "none" },
    name: "Work Situation",
    type: "pie",
    radius: ["57px", "77px"],
    center: ["97px", "101px"],
    avoidLabelOverlap: false,
    label: {
      show: false,
      position: "center",
      fontFamily: "Poppins, Sans-serif",
      color: theme.colors.setting_grey,
    },
    emphasis: {
      label: {
        show: true,
        fontSize: 16,
        fontWeight: "bold",
      },
    },
    labelLine: { show: false },
    data: [],
  },
];
const options: EChartsOption = {
  tooltip: { trigger: "none" },
  series,
};

const workSituationColors: ReportsProps.WorkSituationChartColors = {
  Hybrid: COLORS[0],
  "On-Site": COLORS[1],
  Remote: COLORS[2],
};
const workSituationDefaultTypes: ReportsProps.WorkSituationChartResponse["types"] =
  {
    "On-Site": initialTypeValue,
    Hybrid: initialTypeValue,
    Remote: initialTypeValue,
  };
const itemStyle = { borderWidth: 2, borderColor: "white" };
const workSituationDefaultData: PieSeriesOption["data"] = [
  {
    value: 0,
    name: "Hybrid",
    itemStyle: { ...itemStyle, color: workSituationColors.Hybrid },
  },
  {
    value: 0,
    name: "On-site",
    itemStyle: { ...itemStyle, color: workSituationColors["On-Site"] },
  },
  {
    value: 0,
    name: "Remote",
    itemStyle: { ...itemStyle, color: workSituationColors.Remote },
  },
];

const employmentTypeColors: ReportsProps.EmploymentTypeChartColors = {
  Contract: COLORS[0],
  Freelance: COLORS[1],
  "Full-Time": COLORS[2],
  Internship: COLORS[3],
  Other: COLORS[4],
  "Part-Time": COLORS[5],
  Temporary: COLORS[6],
  Volunteer: COLORS[7],
};
const employmentTypeDefaultTypes: ReportsProps.EmploymentTypeChartResponse["types"] =
  {
    Contract: initialTypeValue,
    Freelance: initialTypeValue,
    "Full-Time": initialTypeValue,
    Internship: initialTypeValue,
    Other: initialTypeValue,
    "Part-Time": initialTypeValue,
    Temporary: initialTypeValue,
    Volunteer: initialTypeValue,
  };
const employmentTypeDefaultData: PieSeriesOption["data"] = [
  {
    value: 0,
    name: "Contract",
    itemStyle: { ...itemStyle, color: employmentTypeColors.Contract },
  },
  {
    value: 0,
    name: "Freelance",
    itemStyle: { ...itemStyle, color: employmentTypeColors.Freelance },
  },
  {
    value: 0,
    name: "Full-Time",
    itemStyle: { ...itemStyle, color: employmentTypeColors["Full-Time"] },
  },

  {
    value: 0,
    name: "Internship",
    itemStyle: { ...itemStyle, color: employmentTypeColors.Internship },
  },
  {
    value: 0,
    name: "Other",
    itemStyle: { ...itemStyle, color: employmentTypeColors.Other },
  },
  {
    value: 0,
    name: "Part-Time",
    itemStyle: { ...itemStyle, color: employmentTypeColors["Part-Time"] },
  },

  {
    value: 0,
    name: "Temporary",
    itemStyle: { ...itemStyle, color: employmentTypeColors.Temporary },
  },
  {
    value: 0,
    name: "Volunteer",
    itemStyle: { ...itemStyle, color: employmentTypeColors.Volunteer },
  },
];

export const DoughnutChartUtils = {
  workSituationColors,
  workSituationDefaultTypes,
  workSituationDefaultData,

  employmentTypeColors,
  employmentTypeDefaultTypes,
  employmentTypeDefaultData,

  series,
  options,
};
