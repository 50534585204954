const AngelDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
    >
      <path
        d="M0.516294 0.548005C0.952294 0.102005 1.55929 0.0670052 2.09229 0.548005L6.00029 4.29501L9.90829 0.548005C10.4413 0.0670052 11.0493 0.102005 11.4823 0.548005C11.9183 0.993005 11.8903 1.74501 11.4823 2.16301C11.0763 2.58101 6.78729 6.66501 6.78729 6.66501C6.57029 6.88801 6.28529 7.00001 6.00029 7.00001C5.71529 7.00001 5.43029 6.88801 5.21129 6.66501C5.21129 6.66501 0.924294 2.58101 0.516294 2.16301C0.108294 1.74501 0.0802941 0.993005 0.516294 0.548005Z"
        fill="#CCCCCC"
      />
    </svg>
  );
};

export default AngelDown;
