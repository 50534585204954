import { IIconProps } from "./customIcons";

export const DropdownArrowDown = ({
  height,
  width,
  fill,
}: IIconProps.Iprops) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "10"}
    height={height || "5"}
    viewBox="0 0 10 5"
    fill="none"
  >
    <path
      d="M4.85714 5L0.403296 0.499999L9.31099 0.5L4.85714 5Z"
      fill={fill || "#596882"}
    />
  </svg>
);

export const DropdownArrowUp = (props: IIconProps.Iprops) => (
  <div style={{ transform: "rotate(180deg)" }}>
    <DropdownArrowDown {...props} />
  </div>
);
