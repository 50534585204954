import { IDropdownProps } from "../Components/Dropdown/dropdown";
import { IInput } from "../Components/input/Input";

const first_name: IInput.IProps = {
  fieldName: "first_name",
  placeholder: "First name",
  isRequired: true,
};
const last_name: IInput.IProps = {
  fieldName: "last_name",
  placeholder: "Last name",
  isRequired: true,
};
const email: IInput.IProps = {
  fieldName: "email",
  placeholder: "Email",
  isRequired: true,
};
const password: IInput.IProps = {
  fieldName: "password",
  placeholder: "Password",
  isRequired: true,
  InputType: "password",
  showPasswordMeter: true,
};
const company_name: IInput.IProps = {
  fieldName: "company_name",
  placeholder: "Company name",
  isRequired: true,
};
const company_website: IInput.IProps = {
  fieldName: "company_website",
  placeholder: "Website",
  isRequired: true,
  InputType: "URL",
};
const companySizeOptions = [
  { label: "1-10", value: "1-10" },
  { label: "11-50", value: "11-50" },
  { label: "51-200", value: "51-200" },
  { label: "201-500", value: "201-500" },
  { label: "501-1000", value: "501-1000" },
  { label: "1001-5000", value: "1001-5000" },
  { label: "5001+", value: "5001+" },
];
const size: IDropdownProps.IProps = {
  fieldName: "size",
  options: companySizeOptions,
  idPrefix: "register",
  isRequired: true,
  placeholder: "Company Size",
};

const formFields = {
  first_name,
  last_name,
  email,
  password,
  company_name,
  company_website,
  size,
};
export const RegisterUtils = { formFields };
