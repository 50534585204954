import { useEffect } from "react";

import { ICONS } from "src/assets/custom_Icons";
import { Typography } from "src/components/Basic";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { API_CALLS } from "src/API_CALLS";
import {
  File,
  FileContainer,
  FilesListContainer,
  FilesMainContainer,
  HeadingContainer,
  Icon,
  IconContainer,
} from "./FilesStyle";

const Files = () => {
  const { files, selectedCandidate, selectedFile } = useAppSelector(
    (state) => state.candidate
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (files.length === 0 && selectedCandidate.section_files) {
      API_CALLS.PIPELINE.getCandidateFiles(selectedCandidate.candidate_id);
    }
  }, []);

  return (
    <FilesMainContainer>
      <HeadingContainer>
        <Typography
          content="FILES"
          styleName="candidateNameEmailOpen"
          color="primary_default"
        />
      </HeadingContainer>
      <FilesListContainer>
        {files?.length > 0 ? (
          files?.map((file, index) => (
            <FileContainer key={index}>
              <File
                onClick={() => {
                  if (selectedFile.id === file.id) {
                    dispatch(actions.candidate.setIsFilePreview(true));
                    return;
                  }
                  API_CALLS.PIPELINE.getCandidateResume({ id: file.id, file });
                }}
              >
                <ICONS.PdfIcon />
                <Typography
                  content={file.name}
                  styleName="fileNameText"
                  color="black80"
                />
              </File>
              <Icon
                onClick={() =>
                  API_CALLS.PIPELINE.downloadFile(file.id, file.name)
                }
              >
                <ICONS.FileDownloadIcon />
              </Icon>
            </FileContainer>
          ))
        ) : (
          <>
            <HeadingContainer className="no-data-msg">
              <Typography
                content={
                  selectedCandidate.section_files
                    ? "Loading..."
                    : "No files added."
                }
                styleName="text1"
                color="black80"
              />
            </HeadingContainer>
            <IconContainer>
              <ICONS.PaperClip />
            </IconContainer>
          </>
        )}
      </FilesListContainer>
    </FilesMainContainer>
  );
};

export default Files;
