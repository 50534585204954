import { IIconProps } from "./customIcons";

export const RangeSlider = ({ fill }: IIconProps.Iprops) => {
  <svg
    width="72"
    height="71"
    viewBox="0 0 72 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      opacity="0.75"
      width="72"
      height="71"
      rx="35.5"
      fill={fill || "#FFCD83"}
    />
    <rect
      x="18"
      y="18"
      width="36"
      height="35"
      rx="17.5"
      fill={fill || "#FFBD5A"}
    />
  </svg>;
};
