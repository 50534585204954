const BuildingIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 13H13V15H11V13ZM11 9H13V11H11V9ZM11 5H13V7H11V5Z"
        fill="#7A839B"
      />
      <path d="M7 13H9V15H7V13ZM7 9H9V11H7V9ZM7 5H9V7H7V5Z" fill="#7A839B" />
      <path
        d="M3.5 1V19H10.5V17H5.5V3H14.5V17H10.5V19H16.5V1H3.5Z"
        fill="#7A839B"
      />
    </svg>
  );
};

export default BuildingIcon;
