import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";

import { ICONS } from "src/assets/custom_Icons";
import { theme } from "src/components/Theme";
import { CardGroupProps } from "./CardGroup";

const CARDS: CardGroupProps.Cards = {
  active_jobs: {
    background: "rgba(78, 194, 240,0.25)",
    icon: <ICONS.BriefCase color="info_default" />,
    title: "Active Jobs",
  },
  applications: {
    background: "rgba(255, 189, 90,0.25)",
    icon: <ICONS.UserIcon color="warning_default" />,
    title: "Applications",
  },
  unread_candidates: {
    background: "rgba(252, 125, 154,0.25)",
    icon: (
      <FontAwesomeIcon
        icon={faEyeSlash}
        style={{ color: theme.colors.secondary_icon }}
        size="lg"
      />
    ),
    title: "Unread Candidates",
  },
  views: {
    background: "rgba(36, 213, 184,0.25)",
    icon: (
      <FontAwesomeIcon
        icon={faEye}
        style={{ color: theme.colors.success_default }}
        size="lg"
      />
    ),
    title: "Views",
  },
};

export const CardGroupUtils = { CARDS };
