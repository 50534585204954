import { Button, Dropdown } from "react-bootstrap";
import { theme } from "src/components/Theme";
import styled from "styled-components";

export const RowCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  label {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  .user-icon {
    color: ${theme.colors.setting_grey};
  }
`;
export const DropDownContainer = styled(Dropdown.Toggle)`
  background: transparent !important;
  border: none;

  &::after {
    display: none;
  }
  &:hover {
    background: transparent !important;
    border: none;
  }
`;
export const DropDownMenuContainer = styled(Dropdown.Menu)`
  margin: 0px;
  border: 1px solid ${theme.colors.black10};
  border-radius: 5px !important;
  inset: auto !important;
  transform: translate(-125px, 0px) !important;
  .dropdown-item:hover {
    background: ${theme.colors.grey_input_dropdown_hover} !important;
  }
  .dropdown-item {
    background: ${theme.colors.white_pure} !important;
  }
  &::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: ${theme.colors.white_pure};
    transform: rotate(45deg);
    top: 0;
    right: 10px;
    z-index: -1;
    border: 1px solid ${theme.colors.black10};
  }
  & label {
    cursor: pointer;
  }
  .dark-theme & {
    border: 1px solid ${theme.colors.black130};
    .dropdown-item {
      background: ${theme.colors.black120} !important;
    }
    &:after {
      border: 1px solid ${theme.colors.black130};
    }
  }
`;

export const IconContainer = styled.div<{ src: string; iscover: boolean }>`
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: ${({ iscover }) => (iscover ? "auto" : "cover")};
  width: 48px;
  height: 48px;
  border: 2px solid ${theme.colors.white_pure};
  border-radius: 30px;
  background-color: ${({ iscover }) =>
    iscover ? theme.colors.info_default : theme.colors.primary_default};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.white_pure};
  ${theme.text.photoText}
`;
export const PaginationButton = styled(Button)`
  color: ${theme.colors.black_pure};
  font-weight: 500;
  font-size: 18px;
  border: none;
  &:disabled {
    color: ${theme.colors.black50};
    border: none;
    cursor: not-allowed;
    pointer-events: visible;
  }
  &:active {
    border: none;
  }
`;
export const ContentWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: ${theme.colors.white_pure};
  box-shadow: 1px 1px 7px rgba(154, 154, 204, 0.1);
  border-radius: 5px;
  width: 100%;
  @media (max-width: 991px) {
    padding: 20px 6px 40px;
    gap: 16px;
  }
  .dark-theme & {
    background: ${theme.colors.black110};
  }
`;

export const Wrapper = styled.div`
  ${theme.text.text1}
  color: rgba(0, 0, 0, 0.54);
  .dark-theme & {
    color: ${theme.colors.setting_grey};
  }
`;
export const Table = styled.table`
  table-layout: fixed;
  .table-header {
    background: ${theme.colors.white30};
    th {
      padding: 10px;
      ${theme.text.authInputErrorMsg};
      white-space: nowrap;
    }
  }
  .table-body tr {
    &:nth-child(even) {
      background: ${theme.colors.white30};
    }
    &:nth-child(odd) {
      background: ${theme.colors.white20};
    }
    & td:first-child {
      @media (max-width: 991px) {
        width: 70px;
      }
    }
  }
  .dark-theme & {
    border: none;
    .table-header {
      background: ${theme.colors.black120};
      th {
        color: ${theme.colors.light_default} !important;
        svg path {
          fill: ${theme.colors.light_default};
        }
      }
    }
    .table-body tr td {
      border-top: none;
      @media (max-width: 991px) {
        border-bottom: 1px solid #484863;
      }
    }
    & > :not(:first-child) {
      @media (max-width: 991px) {
        border-top: none !important;
      }
    }
  }
`;

export const IconWrapper = styled.span`
  svg {
    transform: rotate(180deg);
  }
`;
export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  @media (max-width: 420px) {
    flex-direction: column-reverse;
  }
`;

export const CommonContainer = styled.div`
  @media (max-width: 991px) {
    padding: 0px 10px;
  }
  .dark-theme & label {
    color: ${theme.colors.grey10};
  }
`;
export const TableData = styled.tr<{ isdisable: boolean; isInvite: boolean }>`
  background: ${({ isInvite, isdisable }) =>
    isInvite
      ? `${theme.colors.white30} !important`
      : isdisable && `#1e96f21a !important`};
  & div label {
    color: ${({ isInvite, isdisable }) =>
      isInvite
        ? `${theme.colors.black50}`
        : isdisable && `${theme.colors.light_disable} !important`};
  }
  & svg path {
    fill: ${({ isInvite, isdisable }) =>
      isInvite
        ? `${theme.colors.black50}`
        : isdisable && `${theme.colors.light_disable} !important`};
  }
  &.member-invited:hover label {
    color: ${theme.colors.info_default};
  }
  &.member-invited:hover svg path {
    fill: ${theme.colors.info_default};
  }
  &.member-deactivated:hover svg path {
    fill: ${theme.colors.black50} !important;
  }
  &.member-deactivated:hover label {
    color: ${theme.colors.black50} !important;
  }
  &.member-deactivated:hover {
    background: #1e96f233 !important;
  }
`;
