import { toast } from "react-toastify";

import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions } from "src/Redux";

const dispatch = store.dispatch;

const getReceipts = () => {
  API_INSTANCES.RECEIPTS.getReceipts()
    .then((res) => dispatch(actions.settings.setReceiptData(res.data)))
    .catch((error) => console.log(error))
    .finally(() => dispatch(actions.common.setPageLoading(false)));
};

const downloadReceipt = (id: string, filename: string) => {
  toast.promise(API_INSTANCES.RECEIPTS.downloadReceipt(id), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(data?.data);
        downloadLink.download = `${filename}.pdf`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

export const RECEIPTS = { getReceipts, downloadReceipt };
