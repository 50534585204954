import { useContext } from "react";

import { BoostedCampaignContext } from "..";
import { BoostedCampaignStyles } from "../Styles";
import { CampaignInputs } from "./1-CampaignInputs";
import { BidData } from "./2-BidData";
import { Charts } from "./Charts";

export const BoostedCampaignContent = () => {
  const { bidType, bidValue } = useContext(BoostedCampaignContext);
  return (
    <BoostedCampaignStyles.MainContent>
      <CampaignInputs />
      <BidData />
      <Charts bidValue={bidValue} bidType={bidType} />
    </BoostedCampaignStyles.MainContent>
  );
};
