import styled from "styled-components";
import { Pagination } from "react-bootstrap";
import { theme } from "src/components/Theme";

export const CandidateListContainer = styled.div`
  .candidate-list {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid #fff;
    box-shadow: 1px 1px 5px 0px rgba(154, 154, 204, 0.5);
    gap: 1px;
    background: ${theme.colors.black20};
    @media (max-width: 991px) {
      background: ${theme.colors.white_pure};
    }
    .dark-theme & {
      background: ${theme.colors.black80};
      border-color: ${theme.colors.black80};
      box-shadow: 1px 1px 7px 0px rgba(154, 154, 204, 0.1);
    }
  }
`;
export const CandidateContainer = styled.div<{
  isEmailOpen: boolean;
  isSelected: boolean;
}>`
  display: flex;
  background: ${({ isEmailOpen }) =>
    isEmailOpen ? theme.colors.white_pure : theme.colors.grey_light};
  position: relative;
  padding: 14px;
  height: 68px;
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected &&
    ` border: 1px solid ${theme.colors.success_20};
      box-shadow: ${theme.Effects.candidateBoxShadow};
      filter: ${theme.Effects.candidateDropShadow};`};
  &:hover {
    border: 1px solid ${theme.colors.success_20};
    box-shadow: ${theme.Effects.candidateBoxShadow};
    filter: ${theme.Effects.candidateDropShadow};
  }
  label {
    cursor: pointer;
  }
  @media (max-width: 991px) {
    background: ${({ isEmailOpen }) =>
      isEmailOpen ? theme.colors.light_default : theme.colors.grey_light};
    &,
    &:hover {
      border: none;
      box-shadow: none;
      filter: none;
    }
    &:hover {
      background: ${theme.colors.grey_light};
      .dark-theme & {
        background: ${theme.colors.black180} !important;
      }
    }
  }
`;
export const JobTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .rating_icon {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .email-icon-open-candidatelist:hover svg path {
    stroke: ${theme.colors.black50};
  }
  .email-icon-candidatelist:hover svg path {
    fill: ${theme.colors.black50};
  }
`;
export const JobTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 175px;
`;
export const MainContainer = styled.div`
  padding: 12px 6px 32px 6px;
  background: ${theme.colors.white_pure};
  width: 344px;
  border-radius: 5px;
  @media (max-width: 991px) {
    width: auto;
    padding: 0px 6px 32px;
    min-height: 428px;
  }
  @media (min-width: 1440px) {
    padding: 16px 21px 59px 16px;
    width: 383px;
  }
  .dark-theme & {
    background: ${theme.colors.black110};
  }
  .latest-dropdown + .dropdown-menu.show {
    top: 10px !important;
    left: -70px !important;
  }
  .read-unread-dropdown + .dropdown-menu.show {
    top: 10px !important;
    left: -70px !important;
  }
  .show-dropdown + .dropdown-menu.show {
    top: 10px !important;
    left: -20px !important;
    &::after {
      left: 20% !important;
    }
  }
  .onbording-dropdown .dropdown-menu.show {
    top: 10px !important;
    left: -40px !important;
  }
`;
export const HeadingContainer = styled.div`
  display: flex;
  margin: 0px 1px 12px 2px;
  gap: 20px;
  flex-direction: column;
  @media (min-width: 1440px) {
    & > div:first-child {
      margin: 0px 8px 0px 9px;
    }
    gap: 25px;
  }
  @media (max-width: 991px) {
    margin: 0px 5px 12px 10px;
    gap: 15px;
  }
  .onbording-dropdown {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
  label {
    @media (max-width: 991px) {
      ${theme.text.questionairre}
      color: ${theme.colors.primary_default};
    }
  }
  .btn-label {
    font-weight: 600;
  }
  .show-dropdown {
    &,
    &:focus,
    &:active,
    &:hover {
      @media (min-width: 992px) {
        background: ${theme.colors.primary_default} !important;
        color: ${theme.colors.white_pure} !important;
      }
    }
  }
  .dark-theme & {
    label {
      @media (max-width: 991px) {
        color: ${theme.colors.primary_default};
      }
      color: ${theme.colors.black50};
    }
    .latest-dropdown,
    .read-unread-dropdown {
      &,
      &:focus,
      &:active,
      &:hover {
        background: ${theme.colors.black120} !important;
        color: ${theme.colors.white_pure} !important;
      }
    }
    .show-dropdown {
      &,
      &:focus,
      &:active,
      &:hover {
        @media (min-width: 992px) {
          background: ${theme.colors.black120} !important;
          color: ${theme.colors.white_pure} !important;
        }
      }
    }
  }
`;
export const Photo = styled.img`
  width: 40px;
  height: 40px;
  border: 4.28571px solid ${theme.colors.white_pure};
  box-shadow: ${theme.Effects.photoBorderShadow};
  border-radius: 8.57143px;
`;
export const PaginationItem = styled(Pagination.Item)`
  a {
    ${theme.text.tableButton}
  }
  &.indicator a {
    padding: 9px 16px;
    width: fit-content;
    margin: 0px;
    white-space: nowrap !important;
  }
  .dark-theme & {
    background: none !important;
  }
`;
export const PageItem = styled(Pagination.Item)`
  white-space: nowrap;
  &.indicator {
    a {
      background: transparent !important;
      border: none;
      color: ${theme.colors.black100};
      ${theme.text.tableButton}
    }
    &.indicator a {
      padding: 9px 16px;
      width: fit-content;
      margin: 0px;
    }
    &.indicator a:hover {
      background: transparent !important;
      border: none !important;
    }
  }
  &.disabled {
    cursor: not-allowed;
  }
  .dark-theme &.disabled a {
    color: ${theme.colors.white_pure} !important;
    opacity: 0.5;
  }
`;
export const Paginations = styled(Pagination)`
  display: flex;
  justify-content: center;
  margin-top: 31px;
`;

export const NoDataContainer = styled.div`
  height: calc(100vh - 330px);
  padding: 41px 0 0 20px;
  display: flex;
  flex-direction: column;
  gap: 67px;
  @media (min-width: 992px) {
    .eye-icon {
      display: none;
    }
  }
  .dark-theme & {
    label {
      color: ${theme.colors.black10};
    }
  }
`;
export const MultiSelectContainer = styled.div`
  max-width: 100px;
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border: 1px solid ${theme.colors.grey_brown_square_box};
    border-radius: 2px;
    background-color: ${theme.colors.white_pure};
    .dark-theme & {
      background-color: ${theme.colors.black130};
      border: 1px solid ${theme.colors.black70};
    }
  }
  input[type="checkbox"]:checked + .checkmark {
    background-color: ${theme.colors.primary_default};
    border: none;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input[type="checkbox"]:checked + .checkmark:after {
    display: block;
    top: 1px;
    left: 5px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  .dropdown-container {
    background: ${theme.colors.black10} !important;
    .dark-theme & {
      background: ${theme.colors.black120} !important;
    }
  }
  .dropdown-heading {
    background: ${theme.colors.light_default};
    border-radius: 5px;
    .dark-theme & {
      background: ${theme.colors.black120};
      &:hover {
        background: ${theme.colors.black120};
      }
    }
    &:hover {
      background: #dde0e9;
    }
    & > svg {
      margin-left: 5px;
      .dark-theme & path {
        fill: ${theme.colors.white_pure};
      }
    }
    @media (max-width: 1439px) {
      background: ${theme.colors.black10} !important;
      label {
        color: ${theme.colors.primary_default} !important;
        ${theme.text.activityTextDesc};
      }
      .dark-theme & {
        background: ${theme.colors.black120} !important;
        label {
          color: ${theme.colors.white_pure}!important;
        }
      }
    }
  }

  & .dropdown-content {
    width: 153px;
    z-index: 1000;
    left: -50px;
    .dark-theme & .happy_rating path {
      stroke: ${theme.colors.black50} !important;
    }
    .option-label-rating {
      ${theme.text.text_12pt};
      color: ${theme.colors.black80};
      .dark-theme & {
        color: ${theme.colors.white_pure};
      }
    }
  }
  & .clear-selected-button {
    display: none;
  }
  .dark-theme & .dropdown-content svg path {
    fill: ${theme.colors.black120};
  }
  .dark-theme & .dropdown-heading-value label {
    color: ${theme.colors.white_pure} !important;
  }
`;

export const IconContainer = styled.span<{
  issmall: boolean;
}>`
  svg {
    height: ${({ issmall }) => (issmall ? "15px" : "22px")};
    width: ${({ issmall }) => (issmall ? "15px" : "22px")};
  }
  .dark-theme & svg path {
    fill: ${theme.colors.black120};
  }
`;

export const DropDownGroup = styled.div`
  display: flex;
  gap: 8px;
  @media (max-width: 1439px) {
    .show-dropdown,
    .latest-dropdown,
    .read-unread-dropdown {
      width: 50px;
      text-transform: capitalize;
      border-radius: 3px;
      border: 1px solid ${theme.colors.black20};
      background: ${theme.colors.black10} !important;
      color: ${theme.colors.primary_default} !important;
      ${theme.text.activityTextDesc};
      .dark-theme & {
        background: ${theme.colors.black120} !important;
        border-color: ${theme.colors.black130} !important;
        color: ${theme.colors.white_pure}!important;
      }
      &:after {
        border-top: 0.4em solid ${theme.colors.black_pure};
        border-right: 0.4em solid transparent;
        border-left: 0.4em solid transparent;
        .dark-theme & {
          border-top-color: ${theme.colors.white_pure}!important;
        }
      }
    }
    .latest-dropdown {
      width: 73px;
    }
    .read-unread-dropdown {
      width: 81px;
    }
    .show-dropdown:hover,
    .show-dropdown:focus,
    .latest-dropdown:hover,
    .latest-dropdown:focus {
      color: ${theme.colors.primary_default} !important;
      background: ${theme.colors.black10} !important;
      .dark-theme & {
        background: ${theme.colors.black120} !important;
        color: ${theme.colors.white_pure}!important;
      }
    }
  }
`;

export const JobApplied = styled.div`
  position: absolute;
  top: 4px;
  right: 12px;
  color: ${theme.colors.black50};
  ${theme.text.text_10pt}
`;
