import React from "react";
import { Grid } from "antd";
import clsx from "clsx";

import { Typography } from "src/components/Basic";
import { SettingContainer, Container } from "./SettingStyle";
import {
  Overview,
  Receipts,
  SettingsTabHeader,
  CompanyProfileSettings,
  CandidateProfile,
  MessagingTemplates,
  EmailNotification,
  BillingInformation,
} from "src/components/Settings";
import { SettingsTabKey } from "src/components/Settings/Settings";
import { ICONS } from "src/assets/custom_Icons";
import { LOCAL_STORAGE_KEY } from "src/utils/LOCAL_STORAGE_KEYS";

const Settings = () => {
  const screen = Grid.useBreakpoint();
  const [isHeaderShow, setIsHeaderShow] = React.useState(true);
  const [activeTab, setActiveTab] = React.useState<SettingsTabKey>(
    (localStorage.getItem(
      LOCAL_STORAGE_KEY.ACTIVE_SETTINGS_TAB
    ) as SettingsTabKey) ?? "my-profile"
  );

  const settingContent = {
    "my-profile": <CandidateProfile />,
    "company-profile": <CompanyProfileSettings isShow={isHeaderShow} />,
    "team-overview": <Overview />,
    "email-notifications": <EmailNotification />,
    "messaging-templates": <MessagingTemplates />,
    receipts: <Receipts />,
    "billing-information": <BillingInformation />,
  };

  return (
    <div className={clsx(!screen.lg && "p-2")}>
      <SettingContainer isShow={isHeaderShow}>
        <div className="d-flex justify-content-between align-items-start settings-heading">
          <Typography
            content="SETTINGS"
            styleName={screen.lg ? "heading5" : "tableButton"}
            color={screen.lg ? "black90" : "primary_default"}
          />
          <div
            className=" back-button gap-1 align-items-center"
            onClick={() => setIsHeaderShow(true)}
          >
            <ICONS.LessThanIcon />
            <Typography content="back" styleName="backButton" color="black80" />
          </div>
        </div>
        <Container isheadershow={isHeaderShow}>
          <div>
            <SettingsTabHeader
              setActiveTab={setActiveTab}
              setIsHeaderShow={setIsHeaderShow}
              activeTab={activeTab}
            />
          </div>
          <div className="w-100">{settingContent[activeTab]}</div>
        </Container>
      </SettingContainer>
    </div>
  );
};

export default Settings;
