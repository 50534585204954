import { theme } from "src/components/Theme";
import styled from "styled-components";

export const CandidteProfileMainContainer = styled.div`
  padding: 20px 54px 34px 20px;
  width: 100%;
  background: ${theme.colors.white_pure};
  border-radius: 5px;
  box-shadow: 1px 1px 7px 0px rgba(154, 154, 204, 0.1);
  @media (min-width: 1440px) {
    padding: 20px 54px 123px 20px;
  }
  @media (min-width: 1200px) {
    padding: 20px 20px 123px 20px;
  }
  @media (max-width: 991px) {
    padding: 20px 12px 71px 12px;
  }
  .dark-theme & {
    background: ${theme.colors.black110};
  }
`;
export const HeadingDescriptionContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 1440px) {
    max-width: 633px;
  }
  @media (max-width: 991px) {
    padding: 0 4px;
  }
  .dark-theme & label {
    &:first-child {
      color: ${theme.colors.grey_subtitle1};
    }
    &:last-child {
      color: ${theme.colors.grey_subtitle2};
    }
  }
`;
export const DetailPhotoContainer = styled.div`
  padding: 40px 0px 36px 36px;
  display: flex;
  gap: 44px;
  width: 100%;
  @media (min-width: 1200px) {
    gap: 56px;
    padding: 40px 56px 36px 56px;
  }
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 20px;
    padding: 28px 0px 24px 0px;
  }
`;
export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 306px;
  @media (min-width: 1200px) {
    width: 501px;
  }
  @media (max-width: 991px) {
    width: 100%;
    gap: 20px;
  }
  & #my-profile_email:hover,
  .dark-theme & #my-profile_email:hover {
    box-shadow: none !important ;
  }
`;
export const ProfileImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background: ${theme.colors.grey200};
  border-radius: 5px;
  position: relative;
  box-shadow: rgba(21, 21, 21, 0.02) 0px 1px 4px,
    rgba(21, 21, 21, 0.08) 0px 8px 12px;
  img {
    border-radius: 5px;
  }
  @media (max-width: 991px) {
    width: 150px;
    height: 150px;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .dark-theme & {
    background: rgba(30, 150, 242, 0.2);
  }
`;
export const EditContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: ${theme.colors.grey_icon_bg};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 991px) {
    top: 8px;
    right: 8px;
  }
  .dark-theme & {
    background: ${theme.colors.primary_default};
  }
`;
export const ButtonContainer = styled.div`
  button {
    width: auto;
  }
  padding-left: 36px;
  @media (min-width: 1200px) {
    padding-left: 56px;
  }
  @media (max-width: 991px) {
    padding-left: 0;
  }
`;
export const PasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px 36px 36px;
  width: 501px;
  gap: 40px;
  width: 306px;
  @media (min-width: 1200px) {
    margin: 40px 0px 36px 56px;
    width: 501px;
  }
  @media (max-width: 991px) {
    margin: 40px 0px 16px;
    width: 100%;
  }
`;
