import { Row, Col } from "react-bootstrap";
import { Formik } from "formik";
import { Grid } from "antd";

import { FormFields } from "../../Basic/FormFields";
import { FormExampleStyles } from "./Styles";
import { DarkModeButton, Buttons } from "../../Basic";
import { FormExampleFields } from "./FormExampleUtils";
import { FormikHelpers } from "../../Basic/FormFields/FormikFields/FormikFields.helpers";

export const FormExample = () => {
  const screen = Grid.useBreakpoint();
  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      {(formik) => (
        <FormExampleStyles.Container>
          <DarkModeButton />
          <Row className="form-row">
            <Col md={4}>
              <FormFields.Input {...FormExampleFields.firstName} />
            </Col>
            <Col md={3} xl={2}>
              <FormFields.Input {...FormExampleFields.middleName} />
            </Col>
            <Col md={4}>
              <FormFields.Input {...FormExampleFields.lastName} />
            </Col>
          </Row>

          <Row className="form-row">
            <Col md={7}>
              <FormFields.CitySearch {...FormExampleFields.city} />
            </Col>
            <Col md={5}>
              <FormFields.Input {...FormExampleFields.country} />
            </Col>
          </Row>

          <Row className="form-row">
            <Col md={6}>
              <FormFields.TextArea {...FormExampleFields.bio} />
            </Col>
            <Col md={6}>
              <FormFields.Dropdown {...FormExampleFields.movies} />
            </Col>
          </Row>

          <Row className="form-row">
            <Col md={5}>
              <FormFields.Dropdown {...FormExampleFields.skills} />
            </Col>
            <Col className="d-none d-md-block" md={1} />
            <Col md={6}>
              <FormFields.Dropdown {...FormExampleFields.hobby} />
            </Col>
          </Row>

          <Row className="form-row">
            <Col md={3}>
              <FormFields.DatePickerElement
                {...FormExampleFields.dateOfBirth}
              />
            </Col>
            <Col className="d-none d-md-block" md={1} />
            <Col md={3}>
              <FormFields.InputWithIncrementButton {...FormExampleFields.age} />
            </Col>
            <Col className="d-none d-md-block" md={1} />
            <Col md={4}>
              <FormFields.Input
                {...FormExampleFields.email}
                label={screen.md ? "" : "Email"}
              />
            </Col>
          </Row>

          <Row
            className="form-row"
            style={{ marginTop: screen.md ? "12px" : "0" }}
          >
            <Col md={9} lg={7}>
              <FormFields.RadioGroup
                {...FormExampleFields.favouriteColor}
                showEveryThingInRow={screen.md ? true : false}
              />
            </Col>
            <Col className="d-none d-lg-block" lg={1} />
            <Col md={3}>
              <FormFields.Switch {...FormExampleFields.driverLicense} />
            </Col>
            <Col className="d-none d-lg-block" lg={1} />
          </Row>

          <Row className="form-row">
            <Col md={5} lg={4} xl={3}>
              <FormFields.CheckboxGroup
                {...FormExampleFields.willingToRelocate}
              />
            </Col>
            <Col md={1} className="d-none d-md-block" />
            <Col md={3} xl={2}>
              <FormFields.Switch {...FormExampleFields.driverLicense1} />
            </Col>
            <Col className="d-none d-lg-block" lg={1} />
            <Col md={3} xl={2}>
              <FormFields.Switch {...FormExampleFields.canYouPaint} />
            </Col>
          </Row>

          <Row className="form-row">
            <Col md={12} lg={10}>
              <FormFields.RichTextArea {...FormExampleFields.description} />
            </Col>
          </Row>

          <Row
            className="form-row"
            style={{ marginTop: screen.md ? "12px" : "0" }}
          >
            <Col md={4} lg={3}>
              <FormFields.RadioGroup {...FormExampleFields.favouriteColor1} />
            </Col>
            <Col className="d-none d-lg-block" lg={1} />
            <Col md={4} lg={3}>
              <FormFields.CheckboxGroup
                {...FormExampleFields.whatCanYouDoForCompany}
              />
            </Col>
            <Col className="d-none d-lg-block" lg={1} />
            <Col md={4} lg={3}>
              <FormFields.RadioGroup {...FormExampleFields.favouriteColor2} />
            </Col>
            <Col className="d-none d-lg-block" lg={1} />
          </Row>

          <Row className="form-row">
            <Buttons
              handleClick={() =>
                FormikHelpers.formikSubmitHandler({
                  formFields: FormExampleFields,
                  formik,
                  onSubmitHandler: () => {},
                  idPrefix: "create-job",
                })
              }
              variant="success"
              label="Submit"
            />
          </Row>
        </FormExampleStyles.Container>
      )}
    </Formik>
  );
};
