import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const InternationalTransfers = () => (
  <>
    <Points.Heading pointNumber="8." heading="International Transfers" />
    <Points.Indent>
      <>
        <Points.Span pointNumber="8.1." Content={POINT_8_1} />
        <POINT_8_2 />
      </>
    </Points.Indent>
  </>
);

const POINT_8_1 = (
  <Text.Span content="We may share and process your personal data outside of the USA for the purpose of cloud storage or to engage with third parties." />
);
const POINT_8_2 = () => (
  <>
    <Points.SubHeading
      pointNumber="8.2."
      heading="Whenever we may transfer your personal data out of the USA, we will ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:"
      needUnderline={false}
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="8.2.1." Content={POINT_8_2_1} />
        <Points.Span2 pointNumber="8.2.1." Content={POINT_8_2_2} />
      </>
    </Points.Indent>
  </>
);
const POINT_8_2_1 = (
  <Text.Span content="We will only transfer your personal data to countries that have appropriate data protection legislation in place; and/or" />
);
const POINT_8_2_2 = (
  <Text.Span content="Where we use service providers, we may use specific contracts/clauses which ensure personal data is processed and secured lawfully." />
);
