const NO_RESULTS_ERROR = 400;
const NOT_ENOUGH_DATA_ERROR = 406;
const INPUT_DATA_ERROR = 422;

export const ERROR_CODES = {
  NO_RESULTS_ERROR,
  NOT_ENOUGH_DATA_ERROR,
  INPUT_DATA_ERROR,
};

export const ERRORS_MESSAGES = {
  400: "No results",
  406: "Not enough data",
  422: "Error in input data ",
};
