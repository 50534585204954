import styled from "styled-components";
import { Card } from "react-bootstrap";

import { theme } from "src/components/Theme";

const PageContainer = styled.div`
  background: ${theme.colors.page_background};
  padding: 0 8px 20px 8px;

  @media screen and (min-width: 768px) {
    padding: 0 20px 60px 20px;
  }
  @media (min-width: 992px) {
    padding: 0 20px 94px 20px;
  }
  @media (min-width: 1350px) {
    padding: 0 125px 94px 125px;
  }

  .dark-theme & {
    background: ${theme.colors.dark_page_background} !important;
  }
`;
const ChartContainer = styled(Card)<{ setHeight?: boolean }>`
  width: 100%;
  border-radius: 12px;

  padding: 24px 26px 29px 24px;
  @media (min-width: 768px) {
    padding: 24px 30px;
    height: ${({ setHeight }) => (setHeight ? "440px" : "auto")};
  }
`;

export const Styles = { PageContainer, ChartContainer };
