import { useContext, useMemo } from "react";
import ReactEcharts from "echarts-for-react";
import { EChartsOption } from "echarts";
import styled from "styled-components";

import { CampaignProps, useAppSelector } from "src/Redux";
import { theme } from "src/components/Theme";
import { AppContext } from "src/App";
import { BoostedCampaignUtils } from "../Utils";
import { Typography } from "src/components/Basic";
import { ICONS } from "src/assets/custom_Icons";
import { getBidType } from "..";

const getClosestIndexOfBidValue = (
  bidValue: string,
  data: string[],
  bidType: CampaignProps.BidType,
  chartData: CampaignProps.chartData
) => {
  if (!data) return 0;

  let startIndex = 0;
  let endIndex = data.length - 1;
  let checkIndex = Math.floor(data.length / 2);
  const value = parseFloat(bidValue);

  //Getting Index of the chart bar which is closest to bidValue
  while (startIndex < endIndex && !(endIndex - startIndex === 1)) {
    const checkValue = parseFloat(data[checkIndex]);
    if (value > checkValue) startIndex = checkIndex;
    else if (value < checkValue) endIndex = checkIndex;
    else if (value === checkValue) return checkIndex;
    checkIndex = Math.floor((startIndex + endIndex) / 2);
  }
  if (checkIndex < data.length - 1) {
    const checkValue = parseFloat(data[checkIndex]);
    const checkValueDiff = value - checkValue;

    const nextCheckValue = parseFloat(data[checkIndex + 1]);
    const nextCheckValueDiff = nextCheckValue - value;

    if (nextCheckValueDiff < checkValueDiff) checkIndex += 1;
  }

  const chartDataBidValue = data[checkIndex];
  const chartDataBidType = getBidType(chartDataBidValue, chartData);

  if (bidType === chartDataBidType) return checkIndex;
  if (bidValue < chartDataBidValue) return checkIndex - 1;
  else return checkIndex + 1;
};

type ChartsProps = { bidValue: string; bidType: CampaignProps.BidType };
export const Charts = ({ bidValue, bidType }: ChartsProps) => {
  //#region Redux
  const { common, campaign } = useAppSelector((store) => store);
  const { isDarkTheme } = common;
  let { chartData, chartDataError } = campaign;
  //#endregion

  //#region React
  const { isMobile, isSmallTablet, isTablet } = useContext(AppContext);
  //#endregion

  //#region Data
  const isChartDataExists = chartData?.axis_pointer_value;
  if (!isChartDataExists && chartDataError)
    chartData = BoostedCampaignUtils.chartData;
  const seriesData = useMemo(
    () =>
      chartData.series_data?.map(({ value, bidType }) => ({
        value,
        itemStyle: { color: BoostedCampaignUtils.bidColors[bidType] },
      })),
    [chartData.series_data]
  );

  //#region To calculate interval
  //Interval 0 means every available chart bar is shown, 1 means skips 1 bar between any 2 bars
  let xAxisIntervalLimit = 25;
  if (isMobile) xAxisIntervalLimit = 10;
  if (isSmallTablet) xAxisIntervalLimit = 15;
  if (isTablet) xAxisIntervalLimit = 20;
  const xAxisInterval =
    Math.floor(chartData.x_axis_data?.length / xAxisIntervalLimit) - 1;
  //#endregion

  const bidMessages: CampaignProps.BidMessages = {
    recommended: `Your bid meets the recommended minimum of ${
      chartData.recommended_range?.start_value || 0
    }`,
    low: "Bid might be too low and your ad's performance might be affected.",
    other: "Your bid per view meets the minimum requirements.",
  };
  //#endregion

  const axisLabelStyle = {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "13px",
    color: "#ADB5BE",
    lineHeight: 19.5,
  };
  const axisLineColor = isDarkTheme ? "#4A4F62" : "#77778E40";

  const option: EChartsOption = {
    textStyle: {
      color: isDarkTheme ? "#4A4F62" : "#77778E70",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "7px",
    },
    xAxis: {
      type: "category",
      data: chartData.x_axis_data,
      name: "Bid Per View",
      nameLocation: "middle",
      nameTextStyle: axisLabelStyle,
      boundaryGap: true,
      nameGap: 40.5,
      splitLine: { show: false },
      axisTick: { show: true, alignWithLabel: false, interval: 0 },
      silent: true,
      triggerEvent: false,

      axisPointer: {
        type: "line",
        value: getClosestIndexOfBidValue(
          bidValue,
          chartData.x_axis_data,
          bidType,
          chartData
        ),
        snap: true,
        triggerOn: "none",
        lineStyle: {
          color: isDarkTheme ? theme.colors.black70 : "#7581BD",
          width: 2,
        },
        label: { show: false },
        triggerTooltip: false,
        handle: {
          show: true,
          color: theme.colors.success_default,
          throttle: 0,
          size: 0,
          shadowColor: "transparent",
        },
      },
      axisLine: { lineStyle: { color: axisLineColor } },
      axisLabel: {
        fontFamily: "Poppins",
        fontSize: "7px",
        fontWeight: 400,
        lineHeight: 11,
        interval: xAxisInterval,
        color: "ADB5BE",
      },
    },
    yAxis: {
      type: "value",
      nameTextStyle: axisLabelStyle,
      show: true,
      name: "Job Volume",
      nameRotate: 90,
      nameGap: 10.2,
      nameLocation: "middle",
      axisLabel: { show: false },
      axisLine: {
        show: true,
        lineStyle: { color: axisLineColor },
      },
      splitLine: { lineStyle: { color: axisLineColor } },
    },
    series: [
      {
        type: "bar",
        data: seriesData,
        itemStyle: { borderRadius: [2, 2, 0, 0] },
        markArea: {
          silent: true,
          itemStyle: {
            color: "rgba(36, 213, 184, 0.15)",
            borderColor: "#9D9DA5",
            borderType: "dashed",
            borderJoin: "round",
            borderCap: "butt",
          },
          label: {
            backgroundColor: isDarkTheme ? "#294751" : theme.colors.black80,
            color: "white",
            fontSize: "11px",
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: 16,
            padding: [2, 12],
            borderRadius: 41,
          },
          data: [
            [
              {
                name: "Recommended",
                xAxis: chartData.recommended_range?.start_value,
              },
              { xAxis: chartData.recommended_range?.end_value },
            ],
          ],
        },
      },
    ],
  };

  return (
    <>
      <Container>
        {chartDataError && (
          <ErrorMessage>
            <Typography
              content={chartDataError}
              styleName="jobCheckoutChartError"
              color={isDarkTheme ? "black10" : "black100"}
            />
          </ErrorMessage>
        )}
        <ChartContainer>
          <TextWrapper>
            <Typography
              content="Estimate"
              styleName="jobCheckoutChartTitle"
              color={isDarkTheme ? "black10" : "black_pure"}
            />
          </TextWrapper>

          <ReactEcharts option={option} />
        </ChartContainer>
      </Container>
      <BidMessage>
        <ICONS.WarningIcon fill={BoostedCampaignUtils.bidColors[bidType]} />
        <Typography
          content={bidMessages[bidType]}
          styleName="text_13pt"
          color={isDarkTheme ? "black10" : "black100"}
        />
      </BidMessage>
    </>
  );
};

const Container = styled.div`
  position: relative;
  padding: 8px 16px;
  @media (min-width: 768px) {
    margin-top: 24px;
    background: ${theme.colors.white_pure};
    .dark-theme & {
      background: ${theme.colors.black110};
    }
  }
  @media (min-width: 992px) {
    padding: 24px;
  }
`;
const ChartContainer = styled.div`
  padding-top: 13px;
`;
const ErrorMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(83, 82, 82, 0.4);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TextWrapper = styled.div`
  @media (max-width: 991px) {
    padding-left: 14px;
  }
`;
const BidMessage = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  @media (min-width: 768px) {
    padding: 8px 0 0 14px;
  }
  @media (min-width: 992px) {
    padding: 26px 0 0 0px;
  }
`;
