
const CreditCardIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.875 4.16663H3.12499C1.9744 4.16663 1.04166 5.09937 1.04166 6.24996V18.75C1.04166 19.9006 1.9744 20.8333 3.12499 20.8333H21.875C23.0256 20.8333 23.9583 19.9006 23.9583 18.75V6.24996C23.9583 5.09937 23.0256 4.16663 21.875 4.16663Z"
        stroke="#7A839B"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.04166 10.4166H23.9583"
        stroke="#7A839B"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreditCardIcon;
