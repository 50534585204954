import { useContext } from "react";
import styled from "styled-components";

import { Typography } from "src/components/Basic";
import { AppContext } from "src/App";
import { useAppSelector } from "src/Redux";
import { ICONS } from "src/assets/custom_Icons";

//#region Heading
type HeadingProps = { content: string };
const Heading = ({ content }: HeadingProps) => {
  const { isMobile } = useContext(AppContext);
  const { isDarkTheme } = useAppSelector((store) => store.common);

  return (
    <HeadingContainer className="page-heading">
      <Typography
        styleName={isMobile ? "heading5Mobile" : "heading5"}
        content={content}
        color={isDarkTheme ? "black10" : "black90"}
      />
    </HeadingContainer>
  );
};
const HeadingContainer = styled.div`
  @media (min-width: 768px) {
    padding: 20px 0;
  }
  @media (min-width: 992px) {
    padding: 40px 0 10px 0;
  }
`;
//#endregion

//#region ChartTitle
type ChartTitleProps = { title: string };
const ChartTitle = ({ title }: ChartTitleProps) => {
  const { isDarkTheme } = useAppSelector((store) => store.common);
  return (
    <Typography
      content={title}
      styleName="commonChartTitle"
      color={isDarkTheme ? "black10" : "black300"}
    />
  );
};
//#endregion

//#region DailyAverage Text
type DailyAverageProps = { dailyAverage: number };
const DailyAverage = ({ dailyAverage = 0 }: DailyAverageProps) => {
  const { isDarkTheme } = useAppSelector((store) => store.common);
  const color = isDarkTheme ? "black20" : "black80";
  return (
    <DailyAverageContainer className="chart-features">
      <div className="d-flex flex-column">
        <Typography
          content="Daily Average"
          styleName="commonDailyAverageText"
          color={color}
        />
        <Typography
          content={String(dailyAverage)}
          styleName="commonDailyAverageTextCount"
          color={color}
        />
      </div>
    </DailyAverageContainer>
  );
};
const DailyAverageContainer = styled.div`
  padding-top: 32px;
  @media (min-width: 768px) {
    padding-left: 60px;
  }
  @media (min-width: 1200px) {
    padding-left: 70px;
  }
`;
//#endregion

//#region Insufficient Data Container
const InsufficientData = () => (
  <InsufficientDataContainer>
    <ICONS.InsufficientData />
  </InsufficientDataContainer>
);

const InsufficientDataContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
//#endregion

export const Components = {
  Heading,
  ChartTitle,
  DailyAverage,
  InsufficientData,
};
