import { ICONS } from "src/assets/custom_Icons";
import { Typography } from "src/components/Basic";
import { theme } from "src/components/Theme";
import styled from "styled-components";

export const Footer = () => {
  return (
    <Container>
      <Typography
        content="Copyright © 2023 ."
        styleName="text_14pt"
        color="black80"
      />
      <Typography
        content="Designed with"
        styleName="text_14pt"
        color="black80"
      />
      <ICONS.Heart />
      <Typography content="by " styleName="text_14pt" color="black80" />
      <Typography
        content="Ditto Jobs"
        styleName="text_14pt"
        color="primary_default"
      />
      <Typography
        content=" . All rights reserved"
        styleName="text_14pt"
        color="black80"
      />
    </Container>
  );
};

const Container = styled.footer`
  height: 51px;
  background-color: ${theme.colors.white_pure};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  gap: 4px;
`;
