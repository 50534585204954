import { createContext, useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";

import {
  CampaignProps,
  actions,
  useAppDispatch,
  useAppSelector,
} from "src/Redux";
import { Buttons, Typography } from "src/components/Basic";
import {
  FormFieldCommon,
  FormikHelpers,
} from "src/components/Basic/FormFields";
import { API_CALLS } from "src/API_CALLS";
import { CreateJobStyles } from "../../Styles";
import { BoostedCampaignUtils } from "./Utils";
import { BoostedCampaignStyles } from "./Styles";
import { BoostedCampaignContent } from "./BoostedCampaignContent";
import { BoostedCampaignProps } from "./BoostedCampaign";
import { CreateJobContext } from "../..";
import { AdvertiseUtils } from "../Utils";
import { CurrencyUtils } from "src/components/Basic/FormFields/FormikFields/currencySelector/utils";
import { ROUTES } from "src/utils/ROUTES";

export const getBidType = (
  bidValue: string,
  chartData: CampaignProps.chartData
) => {
  const value = parseFloat(bidValue);
  let bidType: CampaignProps.BidType = "other";

  //recommended
  const { recommended_range, low_range } = chartData;
  const recommendedStart = parseFloat(recommended_range?.start_value);
  const recommendedEnd = parseFloat(recommended_range?.end_value);
  if (value >= recommendedStart && value <= recommendedEnd)
    bidType = "recommended";

  //low
  const lowStart = parseFloat(low_range?.start_value);
  const lowEnd = parseFloat(low_range?.end_value);
  if (value >= lowStart && value <= lowEnd) bidType = "low";

  return bidType;
};

export const BoostedCampaignContext = createContext(
  {} as BoostedCampaignProps.Context
);

export const BoostedCampaign = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { common, createJob, stripe, campaign } = useAppSelector(
    (store) => store
  );
  const { isDarkTheme } = common;
  const { createNovaJobForm, isEditFlow } = createJob;
  const { boostedCampaignForm, campaignProductResponse } = campaign;
  const { stripeData } = stripe;

  const {
    campaign_duration_from,
    campaign_duration_to,
    campaign_input,
    total_campaign_budget,
  } = boostedCampaignForm;
  let { chartData } = campaign;

  const { setBoostedCampaignFormValid } = useContext(CreateJobContext);

  const [bidValue, setBidValue] = useState(campaign_input);
  const [isBidEditable, setBidEditable] = useState(false);
  const [isAdvertiseClicked, setAdvertiseClicked] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);

  //#region DATA
  const isChartDataExists = chartData?.axis_pointer_value ? true : false;
  if (!isChartDataExists) chartData = BoostedCampaignUtils.chartData;
  const currency = isEditFlow
    ? CurrencyUtils.currency_dict[campaignProductResponse.currency]
    : createNovaJobForm.base_currency?.value;
  const { bid_start, bid_end, bid_stepper } = chartData;
  const bidMin = parseFloat(parseFloat(bid_start || "0").toFixed(2));
  const bidMax = Math.ceil(parseFloat(bid_end || "0"));
  const step = parseFloat(bid_stepper || "0");
  const bidType = getBidType(bidValue, chartData);
  const { formFields, idPrefix, validationSchema } = BoostedCampaignUtils;
  const initialValues: BoostedCampaignProps.Form = {
    campaign_duration_from: new Date(campaign_duration_from),
    campaign_duration_to: new Date(campaign_duration_to),
    campaign_input,
    total_campaign_budget,
  };
  //#endregion

  //#region useEffects
  useEffect(() => {
    if (isChartDataExists) return;

    const { job_title, city } = createNovaJobForm;
    const data: CampaignProps.getChartDataAPI = {
      job_title,
      city_id: parseInt(city?.value),
    };
    API_CALLS.CAMPAIGN.getChartData(data);
  }, [isChartDataExists, createNovaJobForm]);

  useEffect(() => {
    if (!isChartDataExists) return;
    if (isEditFlow && bidValue) return;
    const newBidValue = chartData?.axis_pointer_value;
    setBidValue(newBidValue);
  }, [isChartDataExists, chartData]);
  //#endregion

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {}}
      validationSchema={validationSchema}
    >
      {(formik) => {
        const isFormTouched = Object.keys(formik.touched).length > 0;
        if (isFormTouched) {
          setBoostedCampaignFormValid(formik.isValid);

          if (formik.isValid !== isValidForm) {
            setIsValidForm(formik.isValid);
          }
        }

        const onSubmitHandler = () => {
          const _ = formik.values;
          const boostedCampaignForm: CampaignProps.boostedCampaignForm = {
            campaign_duration_from: _.campaign_duration_from?.getTime() || 0,
            campaign_duration_to: _.campaign_duration_to?.getTime() || 0,
            campaign_input: _.campaign_input,
            total_campaign_budget: _.total_campaign_budget,
          };

          dispatch(actions.campaign.setAdvertType("boosted"));
          dispatch(
            actions.stripe.setStripeData({
              ...stripeData,
              totalPrice: parseInt(_.total_campaign_budget),
            })
          );

          AdvertiseUtils.postCampaign(boostedCampaignForm);
        };

        return (
          <BoostedCampaignContext.Provider
            value={{
              currency,
              chartData,
              isBidEditable,
              setBidEditable,
              bidValue,
              setBidValue,
              formik,
              isChartDataExists,
              bidMin,
              bidMax,
              bidType,
              step,
            }}
          >
            <BoostedCampaignStyles.Container
              bidType={bidType}
              className="bid-container"
            >
              <BoostedCampaignStyles.LabelWithInfo>
                <Typography
                  content="Set up your campaign"
                  styleName="subtitle3"
                  color={isDarkTheme ? "black10" : "black_pure"}
                />
                <FormFieldCommon.Info
                  helpText={{ info: "Setup the campaign", title: "campaign" }}
                />
              </BoostedCampaignStyles.LabelWithInfo>
              <BoostedCampaignContent />
            </BoostedCampaignStyles.Container>
            <CreateJobStyles.AdvertiseButtonWrapper>
              <Buttons
                label="Advertise"
                variant="primary"
                isDisable={isAdvertiseClicked && !formik.isValid}
                handleClick={() => {
                  setAdvertiseClicked(true);
                  if (isEditFlow && !isFormTouched) {
                    navigate(ROUTES.JOBS);
                    dispatch(actions.createJob.reset());
                    dispatch(actions.campaign.reset());
                    return;
                  }
                  FormikHelpers.formikSubmitHandler({
                    formFields,
                    formik,
                    onSubmitHandler,
                    idPrefix,
                  });
                }}
              />
            </CreateJobStyles.AdvertiseButtonWrapper>
          </BoostedCampaignContext.Provider>
        );
      }}
    </Formik>
  );
};
