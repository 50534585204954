export const Heart = () => (
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8794 1.84757C16.4356 2.07803 16.941 2.41583 17.3666 2.84166C17.7924 3.26729 18.1302 3.77265 18.3607 4.32887C18.5912 4.88508 18.7098 5.48126 18.7098 6.08333C18.7098 6.6854 18.5912 7.28158 18.3607 7.83779C18.1302 8.39401 17.7924 8.89937 17.3666 9.325L16.4833 10.2083L9.99994 16.6917L3.5166 10.2083L2.63327 9.325C1.77353 8.46525 1.29053 7.29919 1.29053 6.08333C1.29053 4.86747 1.77353 3.70141 2.63327 2.84166C3.49301 1.98192 4.65908 1.49892 5.87494 1.49892C7.0908 1.49892 8.25686 1.98192 9.1166 2.84166L9.99994 3.725L10.8833 2.84166C11.3089 2.41583 11.8143 2.07803 12.3705 1.84757C12.9267 1.6171 13.5229 1.49847 14.1249 1.49847C14.727 1.49847 15.3232 1.6171 15.8794 1.84757Z"
      fill="#ED345E"
      stroke="#ED345E"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
