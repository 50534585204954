import styled from "styled-components";
import { Introduction } from "./1-Introduction";
import { DisclaimersAndWarranties } from "./10-DisclaimersAndWarranties";
import { Indemnities } from "./11-Indemnities";
import { DisputeResolution } from "./12-DisputeResolution";
import { TerminationOfUse } from "./13-TerminationOfUse";
import { NoticesAndServiceAddress } from "./14-NoticeAndServiceAddress";
import { General } from "./15-General";
import { ServicesOffered } from "./2-ServicesOffered";
import { RegisteringProfile } from "./3-RegisteringProfile";
import { Payments } from "./4-Payments";
import { RefundAndReturns } from "./5-RefundAndReturns";
import { JobPostingGuidelines } from "./6-JobPostingGuidelines";
import { ResponsibilitiesAndWarranties } from "./7-ResponsibilitiesAndWarranties";
import { IntelectualPropertyProtection } from "./8-IntelectualPropertyProtection";
import { LinksAndMessages } from "./9-LinksAndMessages";

export const Terms = () => {
  return (
    <Container>
      <Introduction />
      <ServicesOffered />
      <RegisteringProfile />
      <Payments />
      <RefundAndReturns />
      <JobPostingGuidelines />
      <ResponsibilitiesAndWarranties />
      <IntelectualPropertyProtection />
      <LinksAndMessages />
      <DisclaimersAndWarranties />
      <Indemnities />
      <DisputeResolution />
      <TerminationOfUse />
      <NoticesAndServiceAddress />
      <General />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
