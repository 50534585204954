import styled from "styled-components";
import { theme } from "../../../Theme";
import { Typography } from "../../../Basic";
import { Button } from "react-bootstrap";
import { Grid } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/utils/ROUTES";
import { ICONS } from "src/assets/custom_Icons";
import { useAppSelector } from "src/Redux";

const WelcomeCard = () => {
  const screen = Grid.useBreakpoint();
  const navigate = useNavigate();
  const { user_name, active_jobs } = useAppSelector((state) => state.dashboard);
  return (
    <WelcomeCardContainer>
      <ImageContainer>
        <ICONS.DashboardPageIcon />
      </ImageContainer>
      <Content>
        <div className="d-flex gap-2">
          <Typography
            styleName={screen.lg ? "heading2" : "heading4"}
            content="Hi, Welcome Back"
            color="black85"
          />
          <Typography
            styleName={screen.lg ? "heading2" : "heading4"}
            content={`${user_name}!`}
            color="primary_default"
          />
        </div>
        <ActiveJobContainer>
          <Typography
            styleName={screen.lg ? "activejobdec" : "activejobdecMobile"}
            content="You have"
            color="black85"
          />
          <Typography
            styleName={screen.lg ? "activejobdec" : "activejobdecMobile"}
            content={String(active_jobs.active_jobs)}
            color="primary_default"
          />
          <Typography
            styleName={screen.lg ? "activejobdec" : "activejobdecMobile"}
            content="active jobs."
            color="black85"
          />
        </ActiveJobContainer>
        <Button
          variant="primary"
          style={{ fontWeight: "600", width: "173px" }}
          onClick={() => navigate(ROUTES.JOBS)}
        >
          Go to Jobs
        </Button>
      </Content>
    </WelcomeCardContainer>
  );
};

export default WelcomeCard;

export const WelcomeCardContainer = styled.div`
  background: ${theme.colors.white_pure};
  box-shadow: 0px 4px 4px rgba(90, 90, 90, 0.1);

  display: flex;
  padding: 16px 20px 21px;
  flex-direction: column;
  gap: 14px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 20px 52px 20px 27px;
    gap: 26px;
  }

  .dark-theme & {
    background-color: ${theme.colors.black110};
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  width: 250px;
  height: 250px;
  background: linear-gradient(91.56deg, #1e96f2 -6.73%, #38cab3 93.41%);
  @media (min-width: 992px) {
    background: ${theme.colors.primary_default};
  }
  .dark-theme & {
    @media (min-width: 992px) {
      background: #24486c;
    }
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0px 19px;
  @media (max-width: 991px) {
    padding: 0;
  }
  label {
    white-space: nowrap;
  }
  .dark-theme & > div label {
    &:first-child,
    &:nth-child(3) {
      color: ${theme.colors.black10};
    }
  }
`;
export const ActiveJobContainer = styled.div`
  display: flex;
  gap: 3px;
  margin: 18px 0px 112px;
  @media (max-width: 991px) {
    margin: 7px 0px 28px;
  }
`;
