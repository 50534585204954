const SignoutIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M7.5 17.981H4.16667C3.72464 17.981 3.30072 17.8054 2.98816 17.4928C2.67559 17.1802 2.5 16.7563 2.5 16.3143V4.64762C2.5 4.2056 2.67559 3.78167 2.98816 3.46911C3.30072 3.15655 3.72464 2.98096 4.16667 2.98096H7.5"
        stroke="#7A839B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3334 14.6477L17.5 10.481L13.3334 6.31433"
        stroke="#7A839B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 10.481H7.5"
        stroke="#7A839B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SignoutIcon;
