import { FormFieldsProps } from "src/components/Basic/FormFields";
export const idPrefix = "add-user";
const first_name: FormFieldsProps.Input = {
  placeholder: "First Name",
  label: "First Name",
  fieldName: "first_name",
  idPrefix,
  isRequired: true,
};
const last_name: FormFieldsProps.Input = {
  fieldName: "last_name",
  idPrefix,
  isRequired: true,
  placeholder: "Last Name",
  label: "Last Name",
};
const email: FormFieldsProps.Input = {
  placeholder: "Email",
  label: "Email",
  fieldName: "email",
  idPrefix,
  isRequired: true,
  inputType:"email"
};

const user_role: FormFieldsProps.Dropdown = {
  placeholder: "Select User Role",
  label: " User Role",
  fieldName: "user_role",
  idPrefix,
  isRequired: true,
  options: [
    { label: "Standard", value: "Standard" },
    { label: "Admin", value: "Admin" },
  ],
};

export const addUserFields = {
  first_name,
  last_name,
  email,
  user_role,
};
export const editUserFields = {
  first_name,
  last_name,
  user_role,
  email,
};
