import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Field, FieldProps } from "formik";

import { FormFieldCommon } from "../../FormFieldsCommon";
import { FormFieldsProps } from "../../FormFields";
import { REGEX } from "../../REGEX";
import { InputIconStyles } from "./inputIconStyles";
import { useAppSelector } from "src/Redux";

export const InputIcon = (props: FormFieldsProps.InputIcon) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    disabled = false,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    inputType = "text",
    defaultValue = "",
    showLabel = true,
    icon,
    iconLocation = "suffix",
    onChangeHandler,
  } = props;

  const [isFocused, setFocused] = useState(false);
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const { isDarkTheme } = useAppSelector((store) => store.common);

  return (
    <Field name={fieldName} key={fieldName}>
      {({ field, meta, form }: FieldProps) => {
        const { onChange, value, onBlur } = field;
        const { touched, error } = meta;
        const { setFieldValue } = form;

        const errorConditions = isRequired && !isFocused && touched;
        let hasError =
          errorConditions &&
          ((error ? true : false) ||
            value === undefined ||
            value?.length === 0);
        let errorLabel = error || `${label || fieldName} is required`;

        if (inputType === "email") {
          const isValidEmail = REGEX.EMAIL.test(value);
          if (!isValidEmail) hasError = errorConditions;
          errorLabel = "Please enter valid email";
        } else if (inputType === "URL") {
          const isValidURL = REGEX.WEBSITE.test(value);
          if (!isValidURL) hasError = errorConditions;
          errorLabel = "Please enter valid URL";
        } else if (!isDefaultSet && inputType === "number" && isNaN(value)) {
          setFieldValue(fieldName, defaultValue);
          setIsDefaultSet(true);
        }

        const onChangeNumber = (e: React.ChangeEvent<any>) => {
          const value = e.target.value;
          if (!value) {
            onChange(e);
            onChangeHandler && onChangeHandler(value);
            return;
          }
          const newNumber = Number.parseFloat(value);
          if (!isNaN(newNumber)) {
            setFieldValue(fieldName, newNumber);
            onChangeHandler && onChangeHandler(newNumber);
          }
        };

        const showErrorLabel = hasError && errorLabel ? true : false;

        return (
          <InputIconStyles.Container
            {...{
              isDarkTheme,
              disabled,
              Error: hasError,
              isFieldActive: isFocused,
            }}
          >
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <InputGroup className="icon-input">
              {iconLocation === "prefix" && (
                <InputGroup.Text className="input-field-icon input-field-icon-left">
                  <span style={{ width: "21px" }}>{icon}</span>
                </InputGroup.Text>
              )}
              <Form.Control
                id={`${idPrefix}_${fieldName}`}
                type="text"
                className="form-control"
                name={fieldName}
                placeholder={placeholder}
                onChange={
                  inputType === "number"
                    ? onChangeNumber
                    : (e) => {
                        onChangeHandler && onChangeHandler(e.target.value);
                        onChange(e);
                      }
                }
                value={value}
                disabled={disabled}
                onFocus={() => setFocused(true)}
                onBlur={(e) => {
                  setFocused(false);
                  onBlur(e);
                }}
              />
              {iconLocation === "suffix" && (
                <InputGroup.Text className="input-field-icon input-field-icon-left">
                  <span style={{ width: "21px" }}>{icon}</span>
                </InputGroup.Text>
              )}
            </InputGroup>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </InputIconStyles.Container>
        );
      }}
    </Field>
  );
};
