import { useEffect, useRef, useContext } from "react";
import styled from "styled-components";

import "./DatePicker.css";

import { DatePicker } from "./DatePicker";
import { Typography } from "src/components/Basic";
import { theme } from "src/components/Theme";
import { ICONS } from "src/assets/custom_Icons";
import { IIconProps } from "src/assets/custom_Icons/customIcons";
import { datepickerButtonUtils } from "./Utils";
import { useAppSelector } from "src/Redux";
import { AppContext } from "src/App";
import { LinearChartContext } from "..";

export const DatepickerButton = () => {
  const { range, setShowDatePicker, showDatePicker } =
    useContext(LinearChartContext);
  const { isMobile } = useContext(AppContext);
  const { isDarkTheme } = useAppSelector((store) => store.common);
  const { getDateLabel, getRangeType } = datepickerButtonUtils;

  //#region Data
  const iconProps: IIconProps.Iprops = {
    height: 6,
    width: 10.29,
    fill: isDarkTheme ? theme.colors.black50 : theme.colors.pagination,
  };
  const color = isDarkTheme ? "black50" : "black80";
  const Icon = showDatePicker ? ICONS.DropdownArrowUp : ICONS.DropdownArrowDown;
  //#endregion

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node))
        setShowDatePicker(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <Container ref={ref}>
      <DatepickerButtonContainer
        onClick={() => setShowDatePicker((prev: boolean) => !prev)}
      >
        <SelectedFilter>
          <Typography
            styleName="reportsDatePickerFilter"
            content={getRangeType(range.startDate, range.endDate)}
            color={color}
          />
          {isMobile && <Icon {...iconProps} />}
        </SelectedFilter>
        <Typography
          styleName="reportsDatePickerFilter"
          content={getDateLabel(range.startDate, range.endDate)}
          color={color}
        />
        {!isMobile && <Icon {...iconProps} />}
      </DatepickerButtonContainer>
      {showDatePicker && <DatePicker />}
    </Container>
  );
};

const Container = styled.div``;
const SelectedFilter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;
const DatepickerButtonContainer = styled.div`
  border: 1px solid ${theme.colors.black30};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0px 10px;
  position: relative;

  @media (min-width: 576px) {
    padding: 4.5px 13px;
    flex-direction: row;
    height: 30px;
    align-items: center;
    gap: 10px;
    border-radius: 0px 3.2px 3.2px 0px;
  }

  .dark-theme & {
    border: 1px solid ${theme.colors.black130};
  }
`;
