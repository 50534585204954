import { HiringProgressProps } from "./HiringProgress";

const dataLabels = {
  name: {
    color: "#737373",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "12px",
    offsetY: -6,
  },
  value: {
    color: "#0A0A0A",
    fontSize: "20px",
    fontFamily: "Roboto",
    fontWeight: "700",
    show: true,
    offsetY: 7,
    formatter: (val: any) => val,
  },
};
const RadialChartData: HiringProgressProps.chartData = [
  {
    key: "inbox",
    plotOptions: {
      radialBar: {
        track: { background: "#CCF8FE" },
        hollow: { size: "50%" },
        dataLabels,
      },
    },
    fill: { colors: ["#1E96F2"] },
    labels: ["Inbox"],
  },
  {
    key: "screening",
    plotOptions: {
      radialBar: {
        track: { background: "#D3F7F1" },
        hollow: { size: "50%" },
        dataLabels,
      },
    },
    fill: { colors: ["#24D5B8"] },
    labels: ["Screening"],
  },
  {
    key: "shortlist",
    plotOptions: {
      radialBar: {
        track: { background: "#DAD7FE" },
        hollow: { size: "50%" },
        dataLabels,
      },
    },
    fill: { colors: ["#AB8DF4"] },
    labels: ["Shortlist"],
  },
  {
    key: "interview",
    plotOptions: {
      radialBar: {
        track: { background: "#FFE5D3" },
        hollow: { size: "50%" },
        dataLabels,
      },
    },
    fill: { colors: ["#FC7D9A"] },
    labels: ["Interview"],
  },
  {
    key: "offer_made",
    plotOptions: {
      radialBar: {
        track: { background: "#DAD7FE" },
        hollow: { size: "50%" },
        dataLabels,
      },
    },
    fill: { colors: ["#AB8DF4"] },
    labels: ["Offer Made"],
  },
  {
    key: "onboarding",
    plotOptions: {
      radialBar: {
        track: { background: "#D3F7F1" },
        hollow: { size: "50%" },
        dataLabels,
      },
    },
    fill: { colors: ["#24D5B8"] },
    labels: ["Onboarding"],
  },
];

export const HiringProgressUtils = { RadialChartData };
