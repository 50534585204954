import { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import { theme } from "src/components/Theme";
import { AppContext } from "src/App";
import { AuthLayoutTemplateLeft } from "../Components/AuthLayout";
import { Introduction } from "./Introduction";
import { Logos } from "./Logos";
import { SetPassword } from "./SetPassword";
import { OnboardingProps } from "./Onboarding";
import { Header } from "./Header";
import { Rocket } from "../Components/Icon";
import { ROUTES } from "src/utils/ROUTES";
import { Typography } from "src/components/Basic";
import { useAppSelector } from "src/Redux";
import { API_CALLS } from "src/API_CALLS";

export const OnboardingContext = createContext({} as OnboardingProps.context);
export const Onboarding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isOnboardingVerify = location.pathname === ROUTES.ONBOARDING_VERIFY;

  const { verifyRequestInProgress, verifyRequestError, verifyResponse } =
    useAppSelector((store) => store.onboarding);

  const { isDesktopOrBig } = useContext(AppContext);
  const [getStartedClicked, setGetStartedClicked] = useState(false);

  if (isOnboardingVerify) {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email") || "";
    const token = queryParams.get("token") || "";
    if (!verifyRequestInProgress && !verifyRequestError)
      API_CALLS.ONBOARDING.verify({ email, token }, navigate);

    return (
      <VerifyContainer>
        <Typography styleName="heading4" content="Verifying URL..." />
      </VerifyContainer>
    );
  }

  return (
    <OnboardingContext.Provider
      value={{
        getStartedClicked,
        setGetStartedClicked,
        userName: verifyResponse.first_name,
      }}
    >
      <Container>
        <ContentContainer>
          {isDesktopOrBig ? <AuthLayoutTemplateLeft /> : <Header />}
          <Content>
            <Logos />
            {!getStartedClicked ? <Introduction /> : <SetPassword />}
          </Content>
        </ContentContainer>
        {!isDesktopOrBig && <Rocket />}
      </Container>
    </OnboardingContext.Provider>
  );
};

const VerifyContainer = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${theme.colors.white_pure};
  align-items: center;
  padding-bottom: 50px;
  min-height: 100vh;
  @media (min-width: 992px) {
    padding: 0;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 20px 56px 20px;
  @media (min-width: 992px) {
    padding: 0;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: 64px;
  @media (min-width: 768px) {
    gap: 12px;
  }
  @media (min-width: 992px) {
    padding: 33px 0 70px 0;
  }
`;
