const EyeOffIcon = () => {
  return (
    <svg
      width="172"
      height="172"
      viewBox="0 0 172 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M128.57 128.57C116.32 137.908 101.402 143.082 86.0003 143.333C35.8337 143.333 7.16699 86 7.16699 86C16.0815 69.3869 28.4458 54.8724 43.4303 43.43M70.9503 30.3867C75.8834 29.232 80.9339 28.6548 86.0003 28.6667C136.167 28.6667 164.834 86 164.834 86C160.483 94.1385 155.295 101.801 149.354 108.862M101.194 101.193C99.2253 103.306 96.8517 105 94.2144 106.175C91.5771 107.35 88.7301 107.982 85.8433 108.033C82.9565 108.084 80.089 107.553 77.4119 106.472C74.7347 105.39 72.3028 103.781 70.2612 101.739C68.2196 99.6975 66.6101 97.2656 65.5288 94.5885C64.4475 91.9113 63.9164 89.0438 63.9674 86.157C64.0183 83.2702 64.6502 80.4232 65.8253 77.7859C67.0004 75.1486 68.6946 72.775 70.807 70.8067"
        stroke="#4EC2F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.16699 7.16669L164.834 164.833"
        stroke="#4EC2F0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeOffIcon;
