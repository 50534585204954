import styled from "styled-components";
import { useContext } from "react";

import { theme } from "src/components/Theme";
import { LinearChartContext } from "..";

export const DatepickerButtons = () => {
  const { setShowDatePicker, data, type } = useContext(LinearChartContext);
  const { apiCall, requestData } = data[type];
  return (
    <Container>
      <button className="cancel" onClick={() => setShowDatePicker(false)}>
        Cancel
      </button>
      <button
        className="apply"
        onClick={() => {
          apiCall(requestData);
          setShowDatePicker(false);
        }}
      >
        Apply
      </button>
    </Container>
  );
};

const Container = styled.div`
  margin: 8px 20px 16px 20px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  button {
    padding: 8px 12px;
    border-radius: 5px;
  }
  .cancel {
    border: 1px solid ${theme.colors.light_outline};
    background: ${theme.colors.light_default};
    color: ${theme.colors.black90};
  }
  .apply {
    border: 0;
    background: ${theme.colors.primary_default};
    color: ${theme.colors.white_pure};
  }
`;
