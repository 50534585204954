import styled from "styled-components";

import { NovaBlack } from "../Components/Icon";
import { useContext } from "react";
import { AppContext } from "src/App";

export const Logos = () => {
  const { isDesktopOrBig } = useContext(AppContext);
  return (
    <LogoContainer>
      {isDesktopOrBig && <NovaBlack />}
      <img
        src={require("./Telescope.png")}
        height={212}
        width={212}
        alt="company-logo"
      />
    </LogoContainer>
  );
};
const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 82px;
  align-items: center;
  height: fit-content;
`;
