import { createSlice } from "@reduxjs/toolkit";
import { IMessagingTemplates } from "src/components/Settings/MessagingTemplates/MessagingTemplates";

const initialState: IMessagingTemplates.MessageTemplateSlice = {
  tags: [],
  templates: [],
  selectedTemplates: {} as IMessagingTemplates.Templates,
};

const messageTemplateSlice = createSlice({
  name: "messageTemplate",
  initialState,
  reducers: {
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setSelectedTemplates: (state, action) => {
      state.selectedTemplates = action.payload;
    },
  },
});

export const messageTemplateActions = messageTemplateSlice.actions;
export const messageTemplateReducer = messageTemplateSlice.reducer;
