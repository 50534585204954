import { NavigateFunction } from "react-router-dom";

import { API_INSTANCES } from "src/API_INSTANCES";
import { OnboardingProps } from "src/Redux";
import { store, actions } from "src/Redux";
import { ROUTES } from "src/utils/ROUTES";
import { API_CALLS } from ".";

const dispatch = store.dispatch;

const verify = (
  values: OnboardingProps.verifyRequest,
  navigate: NavigateFunction
) => {
  dispatch(actions.onboarding.setVerifyRequestInProgress(true));
  API_INSTANCES.ONBOARDING.verify(values)
    .then((response) => {
      console.log("ONBOARDING VERIFY RESPONSE", response);
      const data: OnboardingProps.verifyResponse = response.data;
      dispatch(actions.onboarding.setVerifyResponse(data));
      dispatch(actions.onboarding.setVerifyRequestError(""));
      navigate(ROUTES.ONBOARDING);
    })
    .catch((error) => {
      console.log("ONBOARDING VERIFY ERROR", error);
      dispatch(
        actions.onboarding.setVerifyRequestError("Error while veriying user: ")
      );
    })
    .finally(() => {
      dispatch(actions.onboarding.setVerifyRequestInProgress(false));
    });
};

const register = (
  values: OnboardingProps.registerRequest,
  navigate: NavigateFunction
) => {
  dispatch(actions.onboarding.setRegisterRequestInProgress(true));
  API_INSTANCES.ONBOARDING.register(values)
    .then((response) => {
      console.log("ONBOARDING REGISTER RESPONSE", response);
      const data: OnboardingProps.registerResponse = response.data;
      dispatch(actions.onboarding.setRegisterResponse(data));
      dispatch(actions.onboarding.setRegisterRequestError(""));

      const { verifyResponse } = store.getState().onboarding;
      dispatch(
        actions.login.setFormValues({
          username: verifyResponse.email,
          password: values.password,
        })
      );
      API_CALLS.AUTH.login(navigate, ROUTES.HOME);
    })
    .catch((error) => {
      console.log("ONBOARDING REGISTER ERROR", error);
      dispatch(
        actions.onboarding.setRegisterRequestError(
          "Error while registering user: "
        )
      );
    })
    .finally(() => {
      dispatch(actions.onboarding.setRegisterRequestInProgress(false));
    });
};

export const ONBOARDING = { verify, register };
