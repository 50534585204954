import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const HowWeUse = () => (
  <>
    <Points.Heading pointNumber="4." heading="How We Use Your Personal Data" />
    <Points.Indent>
      <POINT_4_1 />
    </Points.Indent>
  </>
);

const POINT_4_1 = () => (
  <>
    <Points.SubHeading
      pointNumber="4.1."
      heading="We will only use your personal data when the law allows us to and for legitimate reasons, which you hereby expressly understand and consent to. Most commonly, we will use your personal data in the following circumstances:"
      needUnderline={false}
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="4.1.1." Content={POINT_4_1_1} />
        <Points.Span2 pointNumber="4.1.2." Content={POINT_4_1_2} />
        <Points.Span2 pointNumber="4.1.3." Content={POINT_4_1_3} />
        <Points.Span2 pointNumber="4.1.4." Content={POINT_4_1_4} />
      </>
    </Points.Indent>
  </>
);
const POINT_4_1_1 = (
  <Points.Content>
    <Text.Span content="where we have your " />
    <Text.Bold content="express consent" />
    <Text.Span content=" to do so;" />
  </Points.Content>
);
const POINT_4_1_2 = (
  <Points.Content>
    <Text.Span content="where we need to consult with you or " />
    <Text.Bold content="perform on the Services contract" />
    <Text.Span content=" we are about to enter into or have entered into with you;" />
  </Points.Content>
);
const POINT_4_1_3 = (
  <Points.Content>
    <Text.Span content="where it is necessary for our " />
    <Text.Bold content="legitimate business interests" />
    <Text.Span content=" (or those of a third party) and your interests and fundamental rights do not override those interests; and/or" />
  </Points.Content>
);
const POINT_4_1_4 = (
  <Points.Content>
    <Text.Span content="where we need to comply with a " />
    <Text.Bold content="legal or regulatory obligation" />
    <Text.Span content="." />
  </Points.Content>
);
