import { useEffect, useContext } from "react";
import styled from "styled-components";

import { actions, useAppDispatch, useAppSelector } from "src/Redux/";
import { PagesCommon } from "../Common";
import { HalfDoughnutCharts } from "./HalfDoughnutCharts";
import { LinearCharts } from "./LinearCharts";
import { DoughnutCharts } from "./DoughnutCharts";
import { AppContext } from "src/App";

export const Reports = () => {
  const { isSmallTabletOrSmall } = useContext(AppContext);

  const dispatch = useAppDispatch();
  const { isPageLoad } = useAppSelector((state) => state.common);

  dispatch(actions.common.setPageLoading(false));
  useEffect(() => {
    isPageLoad && dispatch(actions.common.setPageLoading(false));
  }, [isPageLoad, dispatch]);

  return (
    <Container>
      <PagesCommon.Components.Heading content="REPORTS" />

      <PagesCommon.CardGroup type="full" />
      <LinearCharts type="views" />
      <LinearCharts type="applications" />
      <TwoCharts>
        <DoughnutCharts chartType="work-situation" />
        <DoughnutCharts chartType="employment-type" />
      </TwoCharts>
      <TwoCharts>
        <HalfDoughnutChartWrapper>
          <HalfDoughnutCharts type="AdvertiseMix" />
        </HalfDoughnutChartWrapper>
        <LinearCharts type="jobs-created" setHeight={!isSmallTabletOrSmall} />
      </TwoCharts>
      <PagesCommon.HiringProgress showViewDetails={false} />
    </Container>
  );
};

const Container = styled(PagesCommon.Styles.PageContainer)`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;
const TwoCharts = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  @media (min-width: 992px) {
    flex-direction: row;
  }
`;
const HalfDoughnutChartWrapper = styled.div`
  width: 100%;
  max-width: 445px;
`;
