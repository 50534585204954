import { ContentProps } from "./Contents";

const title: ContentProps.Title = {
  terms_and_conditions: "Terms & Conditions",
  privacy_policy: "Privacy Policy",
};
const subTitle: ContentProps.Title = {
  terms_and_conditions:
    "Nova’s Terms and Conditions of Platform Use and Services",
  privacy_policy: "Nova’s Privacy and Personal Information Policy",
};

export const TermsAndConditionsContent = { title, subTitle };
