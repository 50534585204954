import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const Glossary = () => (
  <>
    <Points.Heading pointNumber="12." heading="Glossary" />
    <Points.Indent>
      <>
        <POINT_12_1 />
        <POINT_12_2 />
        <POINT_12_3 />
      </>
    </Points.Indent>
  </>
);

//#region 12.1
const POINT_12_1 = () => (
  <>
    <Points.SubHeading pointNumber="12.1." heading="Lawful Basis" />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="12.1.1." Content={POINT_12_1_1} />
        <Points.Span2 pointNumber="12.1.2." Content={POINT_12_1_2} />
        <Points.Span2 pointNumber="12.1.3." Content={POINT_12_1_3} />
        <Points.Span2 pointNumber="12.1.4." Content={POINT_12_1_4} />
      </>
    </Points.Indent>
  </>
);
const POINT_12_1_1 = (
  <Points.Content>
    <Text.Bold content="Legitimate Interest" />
    <Text.Span content=" means the interest of our organization in conducting and managing our organization to enable us to give you the best service and the most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us." />
  </Points.Content>
);
const POINT_12_1_2 = (
  <Points.Content>
    <Text.Bold content="Performance of Contract" />
    <Text.Span content=" means processing your personal data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract." />
  </Points.Content>
);
const POINT_12_1_3 = (
  <Points.Content>
    <Text.Bold content="Comply with a legal or regulatory obligation" />
    <Text.Span content=" means processing your personal data where it is necessary for compliance with a legal or regulatory obligation that we are subject to." />
  </Points.Content>
);
const POINT_12_1_4 = (
  <Points.Content>
    <Text.Bold content="Express consent" />
    <Text.Span content=" means the confirmed express consent you have provided to our processing of your personal data by actively accepting this Privacy Policy." />
  </Points.Content>
);
//#endregion

//#region 12.2
const POINT_12_2 = () => (
  <>
    <Points.SubHeading pointNumber="12.2." heading="Third Parties" />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="12.2.1." Content={POINT_12_2_1} />
        <POINT_12_2_2 />
      </>
    </Points.Indent>
  </>
);
const POINT_12_2_1 = (
  <Points.Content>
    <Text.Bold content="Internal Third Parties" />
    <Text.Span content=" means other entities or parties in the Ditto Media group acting as joint controllers or processors and provide IT and system administration services and undertake reporting." />
  </Points.Content>
);
//#region 12.2.2
const POINT_12_2_2 = () => (
  <>
    <Points.SubHeadingElement2
      pointNumber="12.2.2."
      Content={POINT_12_2_2_HEADING}
    />
    <Points.Indent>
      <Points.Indent>
        <>
          <Points.Span3 pointNumber="12.2.2.1." Content={POINT_12_2_2_1} />
          <Points.Span3 pointNumber="12.2.2.2." Content={POINT_12_2_2_2} />
          <Points.Span3 pointNumber="12.2.2.3." Content={POINT_12_2_2_3} />
          <Points.Span3 pointNumber="12.2.2.4." Content={POINT_12_2_2_4} />
        </>
      </Points.Indent>
    </Points.Indent>
  </>
);
const POINT_12_2_2_HEADING = (
  <Points.Content>
    <Text.Bold content="External Third Parties" />
    <Text.Span content=" means:" />
  </Points.Content>
);
const POINT_12_2_2_1 = (
  <Text.Span content="Authorized third-party service providers under contract with Ditto Media who need your personal data in order to contact and transact with you pursuant to your use of the Services;" />
);
const POINT_12_2_2_2 = (
  <Text.Span content="service providers acting as operators who provide IT and system administration services;" />
);
const POINT_12_2_2_3 = (
  <Text.Span content="Federal and Local governments and/or their respective authorities pursuant to our adherence with anti-corruption and crime-fighting legislation; and/or" />
);
const POINT_12_2_2_4 = (
  <Text.Span content="professional advisers acting as operators or joint controllers including lawyers, bankers, auditors and insurers who provide consultancy, banking, legal, insurance and accounting services as required." />
);
//#endregion
//#endregion

//#region 12.3
const POINT_12_3 = () => (
  <>
    <Points.SubHeading pointNumber="12.3." heading="Your Legal Rights" />
    <Points.Indent>
      <>
        <Text.Span content="You have the right to:" />
        <Points.Span2 pointNumber="12.3.1." Content={POINT_12_3_1} />
        <Points.Span2 pointNumber="12.3.2." Content={POINT_12_3_2} />
        <Points.Span2 pointNumber="12.3.3." Content={POINT_12_3_3} />
        <Points.Span2 pointNumber="12.3.4." Content={POINT_12_3_4} />
        <POINT_12_3_5 />
        <Points.Span2 pointNumber="12.3.6." Content={POINT_12_3_6} />
        <Points.Span2 pointNumber="12.3.7." Content={POINT_12_3_7} />
      </>
    </Points.Indent>
  </>
);
const POINT_12_3_1 = (
  <Points.Content>
    <Text.Bold content="Request access" />
    <Text.Span content=" to your personal data (commonly known as a " />
    <Text.Bold content="“data subject access request”" />
    <Text.Span content="). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it." />
  </Points.Content>
);
const POINT_12_3_2 = (
  <Points.Content>
    <Text.Bold content="Request correction" />
    <Text.Span content=" of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us." />
  </Points.Content>
);
const POINT_12_3_3 = (
  <Points.Content>
    <Text.Bold content="Request erasure" />
    <Text.Span content=" of your personal data. This enables you to ask us to delete or remove personal data where there is no valid reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. " />
    <Text.Bold content="Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be communicated to you, if applicable, at the time of your request." />
  </Points.Content>
);
const POINT_12_3_4 = (
  <Points.Content>
    <Text.Bold content="Object to processing " />
    <Text.Span content=" of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms." />
  </Points.Content>
);
//#region 12.3.5
const POINT_12_3_5 = () => (
  <>
    <Points.SubHeadingElement2
      pointNumber="12.3.5."
      Content={POINT_12_3_5_HEADING}
    />
    <Points.Indent>
      <Points.Indent>
        <>
          <Points.Span3 pointNumber="12.3.5.1." Content={POINT_12_3_5_1} />
          <Points.Span3 pointNumber="12.3.5.2." Content={POINT_12_3_5_2} />
          <Points.Span3 pointNumber="12.3.5.3." Content={POINT_12_3_5_3} />
          <Points.Span3 pointNumber="12.3.5.4." Content={POINT_12_3_5_4} />
        </>
      </Points.Indent>
    </Points.Indent>
  </>
);
const POINT_12_3_5_HEADING = (
  <Points.Content>
    <Text.Bold content="Request restriction of processing" />
    <Text.Span content=" of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:" />
  </Points.Content>
);
const POINT_12_3_5_1 = (
  <Text.Span content="if you want us to establish the data’s accuracy;" />
);
const POINT_12_3_5_2 = (
  <Text.Span content="where our use of the data is unlawful but you do not want us to erase it;" />
);
const POINT_12_3_5_3 = (
  <Text.Span content="where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or" />
);
const POINT_12_3_5_4 = (
  <Text.Span content="you have objected to our use of your data, but we need to verify whether we have overriding legitimate grounds to use it." />
);
//#endregion
const POINT_12_3_6 = (
  <Points.Content>
    <Text.Bold content="Request the transfer" />
    <Text.Span content=" of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform on a contract with you." />
  </Points.Content>
);
const POINT_12_3_7 = (
  <Points.Content>
    <Text.Bold content="Withdraw consent at any time" />
    <Text.Span content=" where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain Platform access or Services to you. We will advise you if this is the case at the time you withdraw your consent. " />
    <Text.Bold content="Please take note that regardless of your right to withdraw consent there may be other legislation that applies and may require that we continue to process your data to comply with anti-corruption, crime-fighting and/or other federal or local legislation, which you expressly understand and agree to." />
  </Points.Content>
);
//#endregion
