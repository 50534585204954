import React from "react";
import { Typography } from "src/components/Basic";

type CardNumberProps = {
  content: string;
};

const CardNumber: React.FC<CardNumberProps> = ({ content }) => {
  return (
    <Typography
      styleName="billingCardNumber"
      content={content}
      color={"jobCheckoutCardNumber"}
    />
  );
};

export default CardNumber;
