import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import clsx from "clsx";

import { ICONS } from "src/assets/custom_Icons";
import { Comment } from "../../PipeLine";
import { CommentDeleteContainer, Wrapper } from "./CommentsStyle";
import { Typography } from "src/components/Basic";
import { API_CALLS } from "src/API_CALLS";

const CommentDetail: React.FC<Comment> = ({
  id,
  user_profile_picture,
  user_id,
  comment,
  user_first_name,
  user_last_name,
  date_created,
  deletable,
  application_id,
}) => {
  const [isShow, setIsShow] = React.useState<boolean>(false);
  return (
    <div
      className="timeline-wrapper timeline-inverted timeline-wrapper-primary"
      key={id}
    >
      <div className="timeline-badge ">
        <img
          className="timeline-image"
          alt=""
          src={`data:image/png;base64,${user_profile_picture}`}
        />
      </div>
      <div className="timeline-panel">
        {deletable && (
          <CommentDeleteContainer
            isShow={isShow}
            className={clsx(isShow && "comment-delete-container")}
          >
            {isShow && (
              <>
                <div className="cancel-button" onClick={() => setIsShow(false)}>
                  <Typography
                    content="Cancel"
                    styleName="text_11pt"
                    color="black80"
                  />
                </div>
                <div
                  className="delete-button"
                  onClick={() => {
                    API_CALLS.PIPELINE.deleteCandidateComment({
                      user_id,
                      application_id,
                      id,
                    });
                  }}
                >
                  <Typography
                    content="Delete"
                    styleName="text_11pt"
                    color="secondary_default"
                  />
                </div>
              </>
            )}
            <div onClick={() => setIsShow(true)} className="comment-cross">
              <ICONS.CrossIcon />
            </div>
          </CommentDeleteContainer>
        )}
        <Wrapper className="timeline-title">
          <img
            className="timeline-image"
            alt=""
            src={`data:image/png;base64,${user_profile_picture}`}
            width={45}
          />
          <div>{user_first_name + " " + user_last_name}</div>
        </Wrapper>
        <div className="timeline-body">
          <div dangerouslySetInnerHTML={{ __html: comment }} />
        </div>
        <div className="timeline-footer text-muted d-flex align-items-center justify-content-end ">
          <div className="d-flex gap-3 align-items-center">
            <FontAwesomeIcon icon={faCalendar} />
            <span className="ms-auto">{date_created}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentDetail;
