import { Dropdown } from "react-bootstrap";
import { theme } from "src/components/Theme";
import styled from "styled-components";

export const BillingInformationContainer = styled.div`
  border-radius: 5px;
  background: ${theme.colors.white_pure};
  box-shadow: 1px 1px 7px 0px rgba(154, 154, 204, 0.1);
  padding: 20px 0 0 20px;
  min-height: 640px;
  margin-bottom: 94px;
  width: 100%;
  @media (max-width: 991px) {
    padding: 20px 8px 0 8px;
    margin-bottom: 34px;
    min-height: 353px;
  }
  .dark-theme & {
    background: ${theme.colors.black110};
    @media (max-width: 991px) {
      box-shadow: none;
    }
  }
`;
export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 991px) {
    padding-left: 8px;
  }
  .dark-theme & label {
    &:first-child {
      color: ${theme.colors.grey_subtitle1};
    }
    &:last-child {
      color: ${theme.colors.grey_subtitle2};
    }
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 56px 0px 16px 28px;
  @media (max-width: 991px) {
    padding: 28px 0 0 0;
  }
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    gap: 0;
    align-items: start;
    .desktop-default-pop-up {
      display: none !important;
    }
  }
  .custom-control {
    cursor: pointer;
  }

  .default-card-tag {
    display: flex;
    align-items: end;
    height: 66px;
    @media (max-width: 991px) {
      height: 100%;
    }
    .dark-theme & {
      color: ${theme.colors.black50};
    }
  }
`;
export const Card = styled.div<{ selected: boolean }>`
  height: 66px;
  width: 368px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px 20px 20px 20px;
  border: 1px solid
    ${({ selected }) =>
      selected ? theme.colors.primary_default : theme.colors.black30};
  background: ${({ selected }) =>
    selected ? theme.colors.grey_light : theme.colors.white_pure};
  @media (max-width: 991px) {
    background: ${theme.colors.white300};
    width: 328px;
    .expiry-date {
      display: none;
    }
  }
  .dropdown {
    ${({ selected }) => selected && `display: none;`}
  }
  .dark-theme & {
    border-color: ${theme.colors.black130};
    background: ${theme.colors.black110};
    @media (max-width: 991px) {
      border: 1px solid ${theme.colors.primary_default};
    }
    label {
      color: ${theme.colors.black50};
    }
  }
`;
export const CardNumberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  flex-grow: 1;
`;

export const DropDownContainer = styled(Dropdown.Toggle)`
  background: transparent !important;
  border: none;
  color: black !important;
  padding: 8px 2px;
  &::after {
    display: none;
  }
  &:hover {
    color: black;
    background: transparent !important;
    border: none;
  }
  .dark-theme & svg path {
    fill: ${theme.colors.setting_grey};
  }
`;

export const DropDownMenuContainer = styled(Dropdown.Menu)`
  margin: 0px;
  border: 1px solid ${theme.colors.black10};
  border-radius: 5px !important;
  inset: auto !important;
  transform: translate(-135px, 10px) !important;
  .dropdown-item:hover {
    background: ${theme.colors.grey_input_dropdown_hover} !important;
    label {
      font-size: 13px;
    }
  }
  .dropdown-item {
    background: ${theme.colors.white_pure} !important;
  }
  &::after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: ${theme.colors.white_pure};
    transform: rotate(45deg);
    top: 0;
    right: 10px;
    z-index: -1;
    border: 1px solid ${theme.colors.black10};
  }
  & label {
    cursor: pointer;
  }
  .dark-theme & {
    border: 1px solid ${theme.colors.black130};
    .dropdown-item {
      background: ${theme.colors.black120} !important;
      & label {
        color: ${theme.colors.white_pure};
      }
      &:hover {
        background: ${theme.colors.black130} !important;
      }
    }
    &:after {
      border: 1px solid ${theme.colors.black130};
    }
  }
`;

export const Wrapper = styled.div<{ isEnd: boolean }>`
  padding: 40px 0 0 28px;
  @media (max-width: 991px) {
    display: flex;
    justify-content: ${({ isEnd }) => isEnd && "flex-end"};
    padding: 28px 16px 52px 8px;
  }
`;
export const FormDetailContainer = styled.div`
  padding: 40px 0 0 28px;
  @media (min-width: 992px) and (max-width: 1200px) {
    padding: 40px 0 0 28px;
  }
  @media (max-width: 991px) {
    padding: 28px 0 0 4px;
  }
  .dark-theme & > label {
    color: ${theme.colors.success_default} !important;
  }
`;
export const FormContainer = styled.div`
  padding: 28px 373px 23px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (min-width: 992px) and (max-width: 1200px) {
    padding: 28px 126px 23px 0;
  }
  @media (max-width: 991px) {
    padding: 28px 4px 23px 0;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 14px;
  padding: 0 32px 39px 0;
  @media (max-width: 991px) {
    padding: 0 11px 161px 0;
  }
  button {
    font-weight: 600;
  }
`;

export const DefaultExpiredContainer = styled.div<{ isDefault: boolean }>`
  border-radius: 3px;
  background: ${({ isDefault }) =>
    isDefault ? theme.colors.success_default : theme.colors.black70};
  color: ${theme.colors.white_pure};
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  line-height: normal;
  ${theme.text.text_11pt};
`;
