import { FormFieldsProps } from "src/components/Basic/FormFields";

export const idPrefix = "verify";

const first_name: FormFieldsProps.Input = {
  placeholder: "First Name",
  label: "First Name",
  fieldName: "first_name",
  idPrefix,
  isRequired: false,
};
const last_name: FormFieldsProps.Input = {
  fieldName: "last_name",
  idPrefix,
  isRequired: false,
  placeholder: "Last Name",
  label: "Last Name",
};
const email: FormFieldsProps.Input = {
  placeholder: "Email",
  label: "Email",
  fieldName: "email",
  idPrefix,
  isRequired: false,
  disabled: true,
};
const password: FormFieldsProps.Password = {
  placeholder: "password",
  label: "Password",
  fieldName: "password",
  idPrefix,
  isRequired: true,
};
const verify_password: FormFieldsProps.Password = {
  placeholder: "Confirm Password",
  label: "Confirm Password",
  fieldName: "verify_password",
  idPrefix,
  isRequired: true,
};


export const verifyFormFields = {
  first_name,
  last_name,
  email,
  password,
  verify_password,
};
