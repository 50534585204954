import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const YourLegalRights = () => (
  <>
    <Points.Heading pointNumber="11." heading="Your Legal Rights" />
    <Points.Indent>
      <>
        <POINT_11_1 />
        <Points.Span pointNumber="11.2." Content={POINT_11_2} />
        <Points.Span pointNumber="11.3." Content={POINT_11_3} />
        <Points.Span pointNumber="11.4." Content={POINT_11_4} />
        <POINT_11_5 />
        <Points.Span pointNumber="11.6." Content={POINT_11_6} />
      </>
    </Points.Indent>
  </>
);

//#region 11.1
const POINT_11_1 = () => (
  <>
    <Points.SubHeading
      pointNumber="11.1."
      heading="You have rights in relation to your personal data where we are Controller over such personal data. Please contact us to find out more about, or manifest, these rights:"
      needUnderline={false}
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="11.1.1." Content={POINT_11_1_1} />
        <Points.Span2 pointNumber="11.1.2." Content={POINT_11_1_2} />
        <Points.Span2 pointNumber="11.1.3." Content={POINT_11_1_3} />
        <Points.Span2 pointNumber="11.1.4." Content={POINT_11_1_4} />
        <Points.Span2 pointNumber="11.1.5." Content={POINT_11_1_5} />
        <Points.Span2 pointNumber="11.1.6." Content={POINT_11_1_6} />
        <Points.Span2 pointNumber="11.1.7." Content={POINT_11_1_7} />
      </>
    </Points.Indent>
  </>
);
const POINT_11_1_1 = (
  <Text.Span content="request access to your personal data;" />
);
const POINT_11_1_2 = (
  <Text.Span content="request correction of your personal data;" />
);
const POINT_11_1_3 = (
  <Text.Span content="request erasure of your personal data;" />
);
const POINT_11_1_4 = (
  <Text.Span content="object to the processing of your personal data;" />
);
const POINT_11_1_5 = (
  <Text.Span content="request a restriction of processing your personal data;" />
);
const POINT_11_1_6 = (
  <Text.Span content="request transfer of your personal data; and/or" />
);
const POINT_11_1_7 = <Text.Span content="right to withdraw consent." />;
//#endregion
const POINT_11_2 = (
  <Text.Span content="You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive, or excessive. Alternatively, we may refuse to comply with your request in these circumstances." />
);
const POINT_11_3 = (
  <Text.Span content="We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response." />
);
const POINT_11_4 = (
  <Text.Span content="We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated." />
);
//#region 11.5
const POINT_11_5 = () => (
  <>
    <Points.SubHeadingElement
      pointNumber="11.5."
      Content={POINT_11_5_HEADING}
    />
    <Points.Indent>
      <>
        <Points.Span2 pointNumber="11.5.1." Content={POINT_11_5_1} />
        <POINT_11_5_2 />
        <POINT_11_5_3 />
        <Points.Span2 pointNumber="11.5.4." Content={POINT_11_5_4} />
        <Points.Span2 pointNumber="11.5.5." Content={POINT_11_5_5} />
        <Points.Span2 pointNumber="11.5.6." Content={POINT_11_5_6} />
      </>
    </Points.Indent>
  </>
);
const POINT_11_5_HEADING = (
  <div className="with-underline">
    <Text.Span content="California Consumer Privacy Act (" />
    <Text.Bold content="“CCPA”" />
    <Text.Span content=")" />
  </div>
);
const POINT_11_5_1 = (
  <Points.Content>
    <Text.Span content="If you are a resident of the State of California, you have certain rights under the CCPA. One of these rights includes the right to request that we do not sell your information. While we do not sell your personal data to receive any monetary compensation, we may share your personal data with associated companies and third parties for various business reasons. If you would like to opt-out of your data being shared for any reason, please email us directly at " />
    <Text.Link content="legal@ditto.work" />
    <Text.Span content="." />
  </Points.Content>
);
//#region 11.5.2
const POINT_11_5_2 = () => (
  <>
    <Points.SubHeading2
      pointNumber="11.5.2."
      heading="Your rights under the CCPA are in addition to those provided for above. When you contact us to request to exercise your rights under the CCPA, your request must:"
      needUnderline={false}
    />
    <Points.Indent>
      <Points.Indent>
        <>
          <Points.Span3 pointNumber="11.5.2.1." Content={POINT_11_5_2_1} />
          <Points.Span3 pointNumber="11.5.2.2." Content={POINT_11_5_2_2} />
        </>
      </Points.Indent>
    </Points.Indent>
  </>
);
const POINT_11_5_2_1 = (
  <Text.Span content="provide sufficient information that allows us to reasonably verify that you are the person about whom we collected personal data or an authorized representative;" />
);
const POINT_11_5_2_2 = (
  <Text.Span content="describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it." />
);
//#endregion
//#region 11.5.3
const POINT_11_5_3 = () => (
  <>
    <Points.SubHeading2
      pointNumber="11.5.3."
      heading="We cannot respond to your request or provide you with the required information if we cannot:"
      needUnderline={false}
    />
    <Points.Indent>
      <Points.Indent>
        <>
          <Points.Span3 pointNumber="11.5.3.1." Content={POINT_11_5_3_1} />
          <Points.Span3 pointNumber="11.5.3.2." Content={POINT_11_5_3_2} />
        </>
      </Points.Indent>
    </Points.Indent>
  </>
);
const POINT_11_5_3_1 = (
  <Text.Span content="verify your identity or authority to make the request; and" />
);
const POINT_11_5_3_2 = (
  <Text.Span content="confirm that the personal data relates to you." />
);
//#endregion
const POINT_11_5_4 = (
  <Text.Span content="We will disclose and deliver the required information free of charge within 45 days of receiving your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonably necessary and with prior notice to you." />
);
const POINT_11_5_5 = (
  <Text.Span content="Any disclosures we provide will only cover the 12-month period preceding the verifiable request's receipt." />
);
const POINT_11_5_6 = (
  <Text.Span content="For data portability requests, we will select a format to provide your personal data in that is readily useable and should allow you to transmit the information with ease." />
);
//#endregion
const POINT_11_6 = (
  <Text.Span content="Users with citizenships from jurisdictions other than the USA, should any foreign data laws be applicable to your use of the Services and/or the Platform in any way, including how we may process your personal data, please contact us and we will gladly engage with you on your rights." />
);
