import { createSlice } from "@reduxjs/toolkit";

import { OnboardingProps } from "./Onboarding";

const initialState: OnboardingProps.State = {
  verifyRequest: {} as OnboardingProps.verifyRequest,
  verifyRequestInProgress: false,
  verifyRequestError: "",
  verifyResponse: {} as OnboardingProps.verifyResponse,

  registerRequest: {} as OnboardingProps.registerRequest,
  registerRequestInProgress: false,
  registerRequestError: "",
  registerResponse: {} as OnboardingProps.registerResponse,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    //#region verify
    setVerifyRequest: (state, action: OnboardingProps.setVerifyRequest) => {
      state.verifyRequest = action.payload;
    },
    setVerifyRequestInProgress: (
      state,
      action: OnboardingProps.setVerifyRequestInProgress
    ) => {
      state.verifyRequestInProgress = action.payload;
    },
    setVerifyRequestError: (
      state,
      action: OnboardingProps.setVerifyRequestError
    ) => {
      state.verifyRequestError = action.payload;
    },
    setVerifyResponse: (state, action: OnboardingProps.setVerifyResponse) => {
      state.verifyResponse = action.payload;
    },
    //#endregion

    //#region register
    setRegisterRequest: (state, action: OnboardingProps.setRegisterRequest) => {
      state.registerRequest = action.payload;
    },
    setRegisterRequestInProgress: (
      state,
      action: OnboardingProps.setRegisterRequestInProgress
    ) => {
      state.registerRequestInProgress = action.payload;
    },
    setRegisterRequestError: (
      state,
      action: OnboardingProps.setRegisterRequestError
    ) => {
      state.registerRequestError = action.payload;
    },
    setRegisterResponse: (
      state,
      action: OnboardingProps.setRegisterResponse
    ) => {
      state.registerResponse = action.payload;
    },
    //#endregion
  },
});

export const onboardingActions = onboardingSlice.actions;
export const onboardingReducer = onboardingSlice.reducer;
