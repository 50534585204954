import { Accordion, OverlayTrigger, Popover } from "react-bootstrap";

import {
  NotificationContainer,
  NotificationContainerDesktop,
  WarningIconContainer,
} from "./style";
import { Typography } from "src/components/Basic";
import { ICONS } from "src/assets/custom_Icons";

const TemplateNotification = ({
  heading,
  description,
}: {
  heading: string;
  description: string;
}) => {
  return (
    <div>
      <OverlayTrigger
        placement="bottom-start"
        trigger={["click", "hover"]}
        overlay={
          <Popover
            style={{ margin: "0px" }}
            className="popover-head-primary bs-popover-bottom"
          >
            <Popover.Header as="h3" className="popover-headers">
              {heading}
            </Popover.Header>
            <Popover.Body style={{ margin: "0px" }}>{description}</Popover.Body>
          </Popover>
        }
      >
        <span className="template-notification">
          <ICONS.SettingsWarningIcon />
          <span>{heading}</span>
        </span>
      </OverlayTrigger>
      <NotificationContainer>
        <Accordion>
          <Accordion.Item eventKey="Item1">
            <Accordion.Header>
              <ICONS.SettingsWarningIcon />
              {heading}
            </Accordion.Header>
            <Accordion.Body>{description}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </NotificationContainer>
      <NotificationContainerDesktop>
        <div className="d-flex gap-2 align-items-center">
          <WarningIconContainer>
            <ICONS.SettingsWarningIcon />
          </WarningIconContainer>
          <Typography
            content={heading}
            color="black80"
            styleName="candidateName"
          />
        </div>
        <Typography
          content={description}
          color="primary_default"
          styleName="text1"
        />
      </NotificationContainerDesktop>
    </div>
  );
};

export default TemplateNotification;
