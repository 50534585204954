import styled from "styled-components";

import { theme } from "src/components/Theme";

export const CommentsContainer = styled.div`
  margin: 20px 45px 0px 38px;
  @media (max-width: 991px) {
    margin: 16px 8px 0px;
  }
`;
export const TimelineContainer = styled.div`
  margin-top: 20px;
  &:before {
    width: 0px;
  }
  .timeline-badge {
    left: 0px !important;
    background: transparent;
    @media (max-width: 991px) {
      display: none;
    }
  }
  & .timeline-wrapper.timeline-inverted .timeline-panel {
    width: 100%;
    @media (max-width: 991px) {
      margin: 0px !important;
    }
  }
  & .timeline-wrapper.timeline-inverted {
    padding-left: 70px;
  }
  .timeline-panel:after {
    @media (max-width: 991px) {
      display: none !important;
    }
  }
  .dark-theme & {
    .timeline-wrapper.timeline-inverted .timeline-panel {
      background: ${theme.colors.black190};
      &:after {
        border-right: 14px solid ${theme.colors.black190};
      }
    }
    .timeline-body,
    .timeline-footer {
      color: ${theme.colors.black10} !important;
    }
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 1px;
  button {
    @media (max-width: 991px) {
      display: none;
    }
  }
  svg {
    width: 30px;
    height: 30px;
    path {
      stroke: ${theme.colors.primary_default};
    }
    @media (min-width: 992px) {
      display: none !important;
    }
  }
  .dark-theme & svg {
    path,
    circle {
      stroke: ${theme.colors.success_default};
    }
  }
`;

export const Container = styled.div`
  margin-top: 24px;
`;
export const AddCommentContainer = styled.div`
  display: block;
  width: 450px;
  margin: 20px 0px 40px 70px;
  @media (max-width: 991px) {
    margin: 15px 0px 36px;
    width: 100%;
  }
  @media (min-width: 1440px) {
    width: 860px;
  }
  .dark-theme & .rdw-editor-toolbar {
    background: #bfc8de !important;
    box-shadow: 0px 2px 4px rgba(21, 21, 21, 0.08) !important;
    border-radius: 4px !important;
  }
  .rdw-editor-main {
    overflow: auto !important;
    height: 100% !important;
    min-height: 225px !important;
    max-height: 225px !important;
  }
  .rdw-editor-wrapper {
    box-sizing: border-box;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  img {
    display: none;
    @media (max-width: 991px) {
      display: inline-block;
    }
  }
`;
export const HeadingContainer = styled.div`
  @media (max-width: 991px) {
    margin-left: 8px;
  }
  .dark-theme &.no-data-msg label {
    color: ${theme.colors.black10};
  }
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  svg {
    @media (max-width: 991px) {
      width: 35px;
      height: 35px;
    }
  }
  @media (max-width: 991px) {
    margin: 55px auto 80px;
  }
  .dark-theme & svg path {
    stroke: ${theme.colors.light_default};
  }
`;
export const CommentDeleteContainer = styled.div<{ isShow: boolean }>`
  display: flex;
  position: absolute;
  top: 2px;
  right: 0;
  width: fit-content;
  align-items: center;
  padding: 10px 12px 9px 28px;
  gap: 27px;
  background: ${({ isShow }) => isShow && theme.colors.grey_light};
  @media (max-width: 991px) {
    background: ${({ isShow }) => isShow && theme.colors.black10};
    gap: 16px;
    padding: 9px 12px 10px 16px;
  }
  svg {
    cursor: pointer;
    path {
      stroke: ${theme.colors.black60};
      .dark-theme & {
        stroke: ${theme.colors.blue_grey};
      }
    }
  }
  .cancel-button label {
    cursor: pointer;
    &:hover {
      color: ${theme.colors.black70};
    }
  }
  .delete-button label {
    cursor: pointer;
    &:hover {
      color: ${theme.colors.secondary_hover};
    }
  }
  .comment-cross {
    display: ${({ isShow }) => (isShow ? "none" : "block")};
  }
`;
