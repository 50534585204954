import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions } from "src/Redux";

const dispatch = store.dispatch;

const getJobDashboard = () => {
  API_INSTANCES.DASHBOARD.getJobDashboard()
    .then((res) => dispatch(actions.dashboard.setDashboardData(res?.data)))
    .catch((error) => console.log(error))
    .finally(() => dispatch(actions.common.setPageLoading(false)));
};
export const DASHBOARD = { getJobDashboard };
