import { useEffect } from "react";
import { Grid } from "antd";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import ReactApexChart from "react-apexcharts";

import { useAppSelector } from "src/Redux";
import { Typography } from "../../../Basic";
import { theme } from "../../../Theme";
import { PagesCommon } from "..";
import { HiringProgressUtils } from "./utils";
import { HiringProgressProps } from "./HiringProgress";
import { API_CALLS } from "src/API_CALLS";

export const HiringProgress = ({
  showViewDetails,
}: HiringProgressProps.IProps) => {
  const screen = Grid.useBreakpoint();
  const { reports, common } = useAppSelector((state) => state);
  const { hiringProgressResponse, hiringProgressCompleted } = reports;
  const { isDarkTheme } = common;

  useEffect(() => {
    if (hiringProgressCompleted) return;
    API_CALLS.REPORTS.getHiringProgress();
  }, [hiringProgressCompleted]);

  return (
    <PagesCommon.Styles.ChartContainer>
      <div className="d-flex justify-content-between">
        <Typography
          content="Hiring Progress"
          color={isDarkTheme ? "black10" : "black80"}
          styleName="chartTitle"
        />
        {showViewDetails && (
          <Wrapper className="d-flex align-items-center gap-2 ">
            <Typography
              content="View Details"
              color="primary_diverging"
              styleName="hiringProgressSubTitle"
            />
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              style={{
                color: theme.colors.primary_diverging,
                marginBottom: "3px",
              }}
            />
          </Wrapper>
        )}
      </div>
      <ChartGroupWrapper>
        {Array.from({ length: 3 }, (_, index) =>
          HiringProgressUtils.RadialChartData.slice(index * 2, index * 2 + 2)
        ).map((chartGroup, index) => (
          <Container key={index}>
            {chartGroup.map((chartData) => (
              <ChartWrapper key={chartData.key} className={chartData.key}>
                <ReactApexChart
                  options={chartData}
                  series={[hiringProgressResponse[chartData.key]?.count]}
                  type="radialBar"
                  height={screen.lg ? 235 : 215}
                />
              </ChartWrapper>
            ))}
          </Container>
        ))}
      </ChartGroupWrapper>
    </PagesCommon.Styles.ChartContainer>
  );
};

const ChartGroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-direction: column;
  } ;
`;
const ChartWrapper = styled.div`
  max-width: calc(100% / 2);
  @media (max-width: 991px) {
    max-width: calc(100% / 2);
  }
`;
const Container = styled.div`
  display: flex;
  width: calc(100% / 3);
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  label {
    cursor: pointer;
  }
`;
