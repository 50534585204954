import { IIconProps } from "./customIcons";

const FileDownloadBlueIcon = ({ width, height }: IIconProps.Iprops) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "21"}
      height={height || "28"}
      viewBox="0 0 21 28"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0.000488281C1.34315 0.000488281 0 1.34363 0 3.00049V25.0005C0 26.6573 1.34315 28.0005 3 28.0005H18C19.6569 28.0005 21 26.6573 21 25.0005V9.33357L16.3336 4.66715L11.6669 0.000488281H3Z"
        fill="#C7E5FC"
      />
      <path
        d="M21 9.3335L13.6667 9.3335C12.5621 9.3335 11.6667 8.43806 11.6667 7.3335L11.6667 0.000163216L16.3333 4.66683L21 9.3335Z"
        fill="#8ECAF8"
      />
      <path
        d="M10.5 12.8335V21.5835M10.5 21.5835L7 17.5002M10.5 21.5835L14 17.5002"
        stroke="#1E96F2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileDownloadBlueIcon;
