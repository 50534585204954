const ACCESS_TOKEN = "supernova_access_token";
const SELECTED_PHASE = "supernova_selected_phase";
const THEME = "supernove_theme";
const ACTIVE_SETTINGS_TAB = "supernova_active_settings_tab";

export const LOCAL_STORAGE_KEY = {
  ACCESS_TOKEN,
  THEME,
  SELECTED_PHASE,
  ACTIVE_SETTINGS_TAB,
};
