import { useContext } from "react";
import { useAppSelector } from "src/Redux";
import { Typography } from "src/components/Basic";
import styled from "styled-components";
import { BoostedCampaignContext } from "../..";
import { FormFieldCommon } from "src/components/Basic/FormFields";

export const RecommendedBid = () => {
  const { common } = useAppSelector((store) => store);
  const { isDarkTheme } = common;

  const { currency, chartData } = useContext(BoostedCampaignContext);
  const { start_value, end_value } = chartData.recommended_range;
  const setupHelpText = { info: "Setup the campaign", title: "campaign" };

  return (
    <Container>
      <Title>
        <Typography
          content="Recommended bid"
          styleName="jobBoostedRecommendedBid"
          color={isDarkTheme ? "black50" : "black100"}
        />
        <Typography
          content={`${currency}${start_value} - ${currency}${end_value}`}
          styleName="jobBoostedRecommendedBid"
          color="primary_default"
        />
      </Title>
      <LabelWithInfo>
        <Typography
          content="*Based on current market data"
          styleName="jobBoostedRecommendedBidInfo"
          color={isDarkTheme ? "black70" : "black80"}
        />
        <FormFieldCommon.Info helpText={setupHelpText} />
      </LabelWithInfo>
    </Container>
  );
};

const Container = styled.div`
  width: fit-content;
  margin: auto;
  padding-top: 30px;
`;
const Title = styled.div`
  display: flex;
  gap: 8px;
`;
const LabelWithInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
