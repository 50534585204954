import { toast } from "react-toastify";

import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions } from "src/Redux";
import { PayLoadCompanyProfileDetail } from "src/components/Settings/CompanyProfileSettings/CompanyProfileSettings";

const dispatch = store.dispatch;

const getCompanyDetails = () => {
  API_INSTANCES.COMPANY_SETTINGS.getCompanyDetails()
    .then((res) => dispatch(actions.settings.setCompanyProfileDetail(res.data)))
    .catch((error) => console.log(error));
};

const updateCompanyDetails = (values: PayLoadCompanyProfileDetail) => {
  dispatch(actions.settings.setCompanyProfileChangesButtonDisable(true));
  toast.promise(API_INSTANCES.COMPANY_SETTINGS.updateCompanyDetails(values), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        dispatch(actions.settings.setCompanyProfileDetail(data?.data));
        dispatch(actions.settings.setCompanyProfileChangesButtonDisable(false));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        dispatch(actions.settings.setCompanyProfileChangesButtonDisable(false));
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const getCompanyLogo = () => {
  API_INSTANCES.COMPANY_SETTINGS.getCompanyLogo()
    .then((result) => {
      dispatch(actions.settings.setCompanyImage(result.data.company_image));
    })
    .catch((error) => console.log(error));
};

const uploadCompanyLogo = (values: File) => {
  toast.promise(API_INSTANCES.COMPANY_SETTINGS.uploadCompanyLogo(values), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        dispatch(actions.settings.setCompanyImage(data?.data.company_image));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const getCompanyCover = () => {
  API_INSTANCES.COMPANY_SETTINGS.getCompanyCover()
    .then((result) =>
      dispatch(actions.settings.setCoverImage(result.data.cover_image))
    )
    .catch((error) => console.log(error));
};

const uploadCompanyCover = (values: File) => {
  toast.promise(API_INSTANCES.COMPANY_SETTINGS.uploadCompanyCover(values), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        dispatch(actions.settings.setCoverImage(data?.data.cover_image));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const getCompanyGallery = () => {
  API_INSTANCES.COMPANY_SETTINGS.getCompanyGallery()
    .then((result) =>
      dispatch(actions.settings.setCompanyGallery(result.data.images))
    )
    .catch((error) => console.log(error))
    .finally(() => {
      dispatch(actions.common.setPageLoading(false));
      dispatch(actions.settings.setSelectedGalleryPhotoIndex(-1));
      dispatch(actions.settings.setGalleryPhotoEdit(false));
    });
};

const uploadCompanyGallery = (values: File) => {
  dispatch(actions.settings.setAddPhotoGalleryButtonDisable(true));
  toast.promise(API_INSTANCES.COMPANY_SETTINGS.uploadCompanyGallery(values), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        dispatch(actions.settings.setCompanyGallery(data?.data.images));
        dispatch(actions.settings.setSelectedGalleryPhotoIndex(0));
        dispatch(actions.settings.setGalleryPhotoEdit(true));
        dispatch(actions.settings.setAddPhotoGalleryButtonDisable(false));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        dispatch(actions.settings.setAddPhotoGalleryButtonDisable(false));
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

const deleteCompanyGallery = (id: number) => {
  dispatch(actions.settings.setAddPhotoGalleryButtonDisable(true));
  toast.promise(API_INSTANCES.COMPANY_SETTINGS.deleteCompanyGallery(id), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        dispatch(actions.settings.setCompanyGallery(data?.data.images));
        dispatch(actions.settings.setAddPhotoGalleryButtonDisable(false));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        dispatch(actions.settings.setAddPhotoGalleryButtonDisable(false));
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};
const updateGalleryPhotoCaption = (id: number, caption: string) => {
  toast.promise(
    API_INSTANCES.COMPANY_SETTINGS.updateGalleryPhotoCaption(id, caption),
    {
      pending: "Promise is pending",
      success: {
        render({ data }) {
          dispatch(actions.settings.setGalleryPhotoEdit(false));
          dispatch(actions.settings.setCompanyGallery(data?.data.images));
          return "Promise resolved 👌";
        },
      },
      error: {
        render({ data }) {
          dispatch(actions.settings.setGalleryPhotoEdit(false));
          console.log(data);
          return "Promise rejected 🤯";
        },
      },
    }
  );
};

const updateGalleryPhotoPosition = (id: number, position: number) => {
  toast.promise(
    API_INSTANCES.COMPANY_SETTINGS.updateGalleryPhotoPosition(id, position),
    {
      pending: "Promise is pending",
      success: {
        render() {
          return "Promise resolved 👌";
        },
      },
      error: {
        render({ data }) {
          console.log(data);
          return "Promise rejected 🤯";
        },
      },
    }
  );
};

export const COMPANY_SETTINGS = {
  getCompanyDetails,
  updateCompanyDetails,
  uploadCompanyLogo,
  getCompanyLogo,
  uploadCompanyCover,
  getCompanyCover,
  uploadCompanyGallery,
  getCompanyGallery,
  deleteCompanyGallery,
  updateGalleryPhotoCaption,
  updateGalleryPhotoPosition,
};
