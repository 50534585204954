import instance from "src/axios/axios-user";
import { API_PATHS } from "src/utils/API_PATHS";

const getEmailNotificationSettings = () =>
  instance.get(API_PATHS.emailNotificationSettings);

const updateEmailNotificationSettings = (key: string, value: boolean) =>
  instance.put(API_PATHS.emailNotificationSettings, {
    column_name: key,
    column_value: value,
  });

export const EMAIL_NOTIFICATION = {
  getEmailNotificationSettings,
  updateEmailNotificationSettings,
};
