import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const HowWeCollect = () => (
  <>
    <Points.Heading
      pointNumber="3."
      heading="How Is Your Personal Data Collected?"
    />
    <Points.Indent>
      <>
        <POINT_3_1 />
      </>
    </Points.Indent>
  </>
);

//#region 3.1
const POINT_3_1 = () => (
  <>
    <Points.SubHeading
      pointNumber="3.1."
      heading="We use different methods to collect personal data from and about you, including through:"
      needUnderline={false}
    />
    <Points.Indent>
      <>
        <POINT_3_1_1 />
        <Points.Span2 pointNumber="3.1.2." Content={POINT_3_1_2} />
        <POINT_3_1_3 />
      </>
    </Points.Indent>
  </>
);

//#region 3.1.1
const POINT_3_1_1 = () => (
  <>
    <Points.SubHeading2
      pointNumber="3.1.1."
      heading="Direct interactions: You may give us your Identity, Contact, Jobseeker, Job Listing, Transaction, Technical, Marketing and Communications and Usage data by filling in various forms or by corresponding with us. This includes personal data you provide when you:"
      needUnderline={false}
    />
    <Points.Indent>
      <Points.Indent>
        <>
          <Points.Span3 pointNumber="3.1.1.1." Content={POINT_3_1_1_1} />
          <Points.Span3 pointNumber="3.1.1.2." Content={POINT_3_1_1_2} />
          <Points.Span3 pointNumber="3.1.1.3." Content={POINT_3_1_1_3} />
          <Points.Span3 pointNumber="3.1.1.4." Content={POINT_3_1_1_4} />
          <Points.Span3 pointNumber="3.1.1.5." Content={POINT_3_1_1_5} />
          <Points.Span3 pointNumber="3.1.1.6." Content={POINT_3_1_1_6} />
          <Points.Span3 pointNumber="3.1.1.7." Content={POINT_3_1_1_7} />
        </>
      </Points.Indent>
    </Points.Indent>
  </>
);
const POINT_3_1_1_1 = <Text.Span content="use our Services;" />;
const POINT_3_1_1_2 = <Text.Span content="use our Platform;" />;
const POINT_3_1_1_3 = <Text.Span content="use the Ditto Jobs platform;" />;
const POINT_3_1_1_4 = <Text.Span content="contract with us;" />;
const POINT_3_1_1_5 = (
  <Text.Span content="provide any services to us as a service provider or independent contractor on contract with us;" />
);
const POINT_3_1_1_6 = (
  <Text.Span content="request information to be sent to you;" />
);
const POINT_3_1_1_7 = <Text.Span content="give us some feedback." />;
//#endregion

const POINT_3_1_2 = (
  <Points.Content>
    <Text.Bold content="Automated technologies or interactions:" />
    <Text.Span content=" As you interact with our Platform, we may automatically collect " />
    <Text.Bold content="Technical data" />
    <Text.Span content=" and " />
    <Text.Bold content="Usage data" />
    <Text.Span content=" about your equipment, browsing actions and patterns. We may collect this personal data by using cookies, server logs and other similar technologies. We may also receive Technical Data about you if you visit other platforms employing our cookies." />
  </Points.Content>
);
//#region 3.1.3
const POINT_3_1_3 = () => (
  <>
    <Points.SubHeadingElement2
      pointNumber="3.1.3."
      Content={POINT_3_1_3_HEADING}
    />
    <Points.Indent>
      <Points.Indent>
        <>
          <Points.Span3 pointNumber="3.1.3.1." Content={POINT_3_1_3_1} />
          <Points.Span3 pointNumber="3.1.3.2." Content={POINT_3_1_3_2} />
          <Points.Span3 pointNumber="3.1.3.3." Content={POINT_3_1_3_3} />
          <Points.Span3 pointNumber="3.1.3.4." Content={POINT_3_1_3_4} />
          <Points.Span3 pointNumber="3.1.3.5." Content={POINT_3_1_3_5} />
        </>
      </Points.Indent>
    </Points.Indent>
  </>
);
const POINT_3_1_3_HEADING = (
  <Points.Content>
    <Text.Bold content="Third parties:" />
    <Text.Span content=" We may receive personal data about you from various third parties such as:" />
  </Points.Content>
);
const POINT_3_1_3_1 = (
  <Text.Span content="associated platforms and companies;" />
);
const POINT_3_1_3_2 = <Text.Span content="analytics providers;" />;
const POINT_3_1_3_3 = <Text.Span content="marketing platforms;" />;
const POINT_3_1_3_4 = <Text.Span content="search information providers; and" />;
const POINT_3_1_3_5 = <Text.Span content="payment service providers." />;
//#endregion
//#endregion
