import { useEffect } from "react";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
// Alternatively:
// import Lottie from 'react-lottie-player/dist/LottiePlayerLight'

import { ROUTES } from "src/utils/ROUTES";
import { actions, useAppDispatch } from "src/Redux";
import animationData from "./CampaignLoading.json";
import "./CampaignLoading.css";

export const Animation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const defaultScroll = window.onscroll;
    window.onscroll = () => {
      window.scrollTo(0, 0);
    };

    return () => {
      window.onscroll = defaultScroll;
    };
  }, []);

  return (
    <div className="lottie-wrapper">
      <Lottie
        loop={false}
        animationData={animationData}
        play
        style={{ width: 619, height: 319 }}
        onComplete={() => {
          navigate(ROUTES.JOBS);
          dispatch(actions.createJob.reset());
          dispatch(actions.campaign.setShowCampaignLoadingAnimation(false));
        }}
      />
    </div>
  );
};

export default Animation;
