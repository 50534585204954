import { CampaignProps } from "./Campaign";
import { createSlice } from "@reduxjs/toolkit";

const startDate = new Date();
const endDate = new Date(startDate);
endDate.setDate(startDate.getDate() + 30);

const initialState: CampaignProps.State = {
  advertType: "",
  campaignPrices: { standard: 0, premium: 0 },
  isBoostedSelected: false,
  advertiseDetails: [] as CampaignProps.advertiseDetails,
  chartData: {
    axis_pointer_value: "",
    bid_end: "0",
    bid_start: "0",
    bid_stepper: "0.15",
    recommended_range: { end_value: "0", start_value: "0" },
    low_range: { end_value: "0", start_value: "0" },
  } as CampaignProps.chartData,
  getChartDataAPIInProgress: false,
  chartDataError: "",
  boostedCampaignForm: {
    campaign_duration_from: startDate.getTime(),
    campaign_duration_to: endDate.getTime(),
    campaign_input: "0",
    total_campaign_budget: "0",
  },
  isTotalCampaignManuallyUpdated: false,
  showCampaignLoadingAnimation: false,

  campaignProductResponse: {} as CampaignProps.campaignProductResponse,
};

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCampaignPrices: (state, action: CampaignProps.setCampaignPrices) => {
      state.campaignPrices = action.payload;
    },
    setAdvertType: (state, action: CampaignProps.setAdvertType) => {
      state.advertType = action.payload;
    },
    setBoostedSelected: (state, action: CampaignProps.setBoostedSelected) => {
      state.isBoostedSelected = action.payload;
    },
    setAdvertiseDetails: (state, action: CampaignProps.setAdvertiseDetails) => {
      state.advertiseDetails = action.payload;
    },
    setChartData: (state, action: CampaignProps.setChartData) => {
      state.chartData = action.payload;
    },
    setBoostedCampaignForm: (
      state,
      action: CampaignProps.setBoostedCampaignForm
    ) => {
      state.boostedCampaignForm = action.payload;
    },
    setChartDataAPIInProgress: (
      state,
      action: CampaignProps.setChartDataAPIInProgress
    ) => {
      state.getChartDataAPIInProgress = action.payload;
    },
    setChartDataError: (state, action: CampaignProps.setChartDataError) => {
      state.chartDataError = action.payload;
    },
    setTotalCampaignManuallyUpdated: (
      state,
      action: CampaignProps.setTotalCampaignManuallyUpdated
    ) => {
      state.isTotalCampaignManuallyUpdated = action.payload;
    },
    setShowCampaignLoadingAnimation: (
      state,
      action: CampaignProps.setShowCampaignLoadingAnimation
    ) => {
      state.showCampaignLoadingAnimation = action.payload;
    },

    setCampaignProductResponse: (
      state,
      action: CampaignProps.setCampaignProductResponse
    ) => {
      state.campaignProductResponse = action.payload;
    },
    reset: () => initialState,
  },
});

export const campaignReducer = campaignSlice.reducer;
export const campaignActions = campaignSlice.actions;
