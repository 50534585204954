import { Typography } from "../../../Basic";
import { RowCellContainer } from "../JobStyle";
import { IJobs } from "../Job";

export const Default = ({ cell, className }: IJobs.CustomCellItem) => {
  return (
    <RowCellContainer className={className}>
      <Typography
        content={cell.value}
        styleName="tableRowDefault"
        color="black85"
      />
    </RowCellContainer>
  );
};
