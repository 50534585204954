import { Form } from "react-bootstrap";
import styled from "styled-components";

import { FormFieldCommon } from "../FormFieldsCommon";
import { FormFieldsProps } from "../FormFields";
import { FIELDS } from "../FormFieldsStyles/common";
import { useState } from "react";
import { useAppSelector } from "src/Redux";

export const Input = (props: FormFieldsProps.Input) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    disabled = false,
    placeholder = label || fieldName,
    needLabelPlaceholder = false,
    showLabel = true,
    defaultValue,
    onChangeHandler,
  } = props;

  const [isFocused, setFocused] = useState(false);
  const { isDarkTheme } = useAppSelector((store) => store.common);

  const showErrorLabel = isFocused && false;
  const errorLabel = `${label || fieldName} is required`;

  return (
    <InputContainer {...{ isDarkTheme, disabled, Error: false }}>
      {showLabel && label && (
        <FormFieldCommon.Label content={`${label}${isRequired ? "*" : ""}`} />
      )}
      {!label && needLabelPlaceholder && <FormFieldCommon.LabelPlaceHolder />}
      <Form.Control
        id={`${idPrefix}_${fieldName}`}
        type="text"
        className="form-control"
        name={fieldName}
        placeholder={placeholder}
        onChange={(e) => onChangeHandler && onChangeHandler(e.target.value)}
        value={defaultValue}
        disabled={disabled}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
      {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
    </InputContainer>
  );
};

const InputContainer = styled.div<{
  disabled: boolean;
  Error: boolean;
  isDarkTheme: boolean;
}>`
  ${({ Error, disabled }) => {
    if (disabled) return FIELDS.DISABLED_FORM_CONTROL;
    return Error ? FIELDS.ERROR_INPUT_FORM_CONTROL : FIELDS.INPUT_FORM_CONTROL;
  }};
`;
