import styled from "styled-components";
import { Card } from "react-bootstrap";

import { theme } from "src/components/Theme/";
import { CardGroupProps } from "./CardGroup";
import { TypographySpan } from "src/components/Basic";
import { useAppSelector } from "src/Redux";

export const CardComponent = ({
  title,
  totalCount = 0,
  icon,
  isShownLastWeek = false,
  isBottom = false,
}: CardGroupProps.Card) => {
  const { isDarkTheme } = useAppSelector((store) => store.common);
  const color = isDarkTheme ? "black20" : "black80";

  return (
    <CustomCard>
      <div>
        <CardHeading isBottom={isBottom}>
          <TypographySpan
            styleName="commonCardTitle"
            content={title}
            color={color}
          />
          {isShownLastWeek && (
            <TypographySpan
              styleName="commonCardSubTitle"
              content={" (Last 30 days)"}
              color={color}
            />
          )}
        </CardHeading>
        <TypographySpan
          styleName="commonCardTextCount"
          content={`${totalCount}`}
          color={color}
        />
      </div>
      {icon}
    </CustomCard>
  );
};

const CustomCard = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 0px;
  height: 82px;
  padding: 16px 24px;
  @media (min-width: 768px) {
    height: 98px;
    padding: 16px 16px 16px 24px;
  }
`;
const CardHeading = styled.h6<{ isBottom: boolean }>`
  display: ${({ isBottom }) => (isBottom ? "block" : "flex")};
  margin-bottom: 11px;
  justify-content: start;
  align-items: center;
  gap: 4px;

  .dark-theme & {
    color: ${theme.colors.black20};
  }
`;
