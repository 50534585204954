
import { ICONS } from "../../../assets/custom_Icons";
import { darkmode } from "../../../utils/theme";
import { actions, useAppDispatch } from "src/Redux";
import { LOCAL_STORAGE_KEY } from "src/utils/LOCAL_STORAGE_KEYS";

const DarkModeButton = () => {
  document.querySelector("body")?.classList.add("app");
  const dispatch = useAppDispatch();

  return (
    <div className="new nav-link theme-layout nav-link-bg layout-setting d-flex justify-content-end darkmode_button">
      <span
        className="dark-layout"
        onClick={() => {
          darkmode();
          dispatch(actions.common.setDarkTheme(true));
          localStorage.setItem(LOCAL_STORAGE_KEY.THEME, "true");
        }}
      >
        <ICONS.DarkModeIcon />
      </span>
      <span
        className="light-layout"
        onClick={() => {
          darkmode();
          dispatch(actions.common.setDarkTheme(false));
          localStorage.setItem(LOCAL_STORAGE_KEY.THEME, "false");
        }}
      >
        <ICONS.LightModeIcon />
      </span>
    </div>
  );
};

export { DarkModeButton };
