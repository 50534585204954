import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const IntelectualPropertyProtection = () => (
  <>
    <Points.Heading
      pointNumber="8."
      heading="Intellectual Property Protection"
    />
    <Points.Indent>
      <>
        <Points.Span pointNumber="8.1." Content={POINT_8_1} />
        <Points.Span pointNumber="8.2." Content={POINT_8_2} />
        <Points.Span pointNumber="8.3." Content={POINT_8_3} />
        <Points.Span pointNumber="8.4." Content={POINT_8_4} />
        <Points.Span pointNumber="8.5." Content={POINT_8_5} />
        <Points.Span pointNumber="8.6." Content={POINT_8_6} />
      </>
    </Points.Indent>
  </>
);

const POINT_8_1 = (
  <Points.Content>
    <Text.Span
      content={`All website layout, website content, material, information, data, software, icons, text, graphics, layouts, images, sound clips, advertisements, video clips, user interface design and layout, trade names, logos, trademarks, designs, copyright and/or service marks, together with the underlying software code and everything submitted to Nova and Ditto Media in use of the Services, (“`}
    />
    <Text.Bold content="the intellectual property" />
    <Text.Span content="”) are owned (or co-owned or licensed, as the case may be) by Ditto Media, its shareholders, associates and/or partners, whether directly or indirectly, and as such, are protected from infringement by domestic and international legislation and treaties." />
  </Points.Content>
);
const POINT_8_2 = (
  <Points.Content>
    <Text.Span
      content={`All rights to any intellectual property provided by you to Nova will remain with you, but for which you have provided Ditto Media with a non-exclusive, non-transferable license to use such intellectual property as Ditto Media deems fit on Nova and/or in advertising, for as long as you remain registered on Nova.`}
    />
  </Points.Content>
);
const POINT_8_3 = (
  <Points.Content>
    <Text.Span
      content={`Subject to the rights afforded to you in these Terms, all other rights to all intellectual property on Nova are expressly reserved. `}
    />
    <Text.Bold
      content={`You may not copy, download, print, modify, alter, publish, broadcast, distribute, sell, or transfer any intellectual property, editorial content, graphics or other material on Nova or the underlying software code whether in whole or in part, without the written consent of Ditto Media first being granted, which consent may be refused at the discretion of Ditto Media. No modification of any intellectual property or editorial content or graphics is permitted. Should you breach these provisions, Ditto Media and/or the rightful intellectual property rights owner may launch legal proceedings against you for a breach of contract, resulting in a claim of damages against you.`}
    />
  </Points.Content>
);
const POINT_8_4 = (
  <Points.Content>
    <Text.Span
      content={`Ditto Media reserves the right to make improvements or changes to the intellectual property, information, videos, artwork, graphics, and other materials on Nova, including the information in your profile, or to suspend or terminate Nova, at any time without notice; provided that any transactions or functions already concluded through Nova, will not be affected by such suspension or termination (as the case may be).`}
    />
  </Points.Content>
);
const POINT_8_5 = (
  <Points.Content>
    <Text.Span
      content={`Where any Nova intellectual property has been licensed to Ditto Media or belongs to any third party, other than that which has been submitted by you to Nova in the use of the Services, all rights of use will also be subject to any terms and conditions which that licensor or third party imposes from time to time, and you agree to comply with such third party terms and conditions.`}
    />
  </Points.Content>
);
const POINT_8_6 = (
  <Points.Content>
    <Text.Span
      content={`Subject to adherence to the Terms, Ditto Media grants you a personal, non-exclusive, non-assignable and non-transferable license to use and display all content and information contained on Nova on any machine on which you are the primary user. However, nothing contained on Nova or in these Terms should be construed as granting any license or right to use any intellectual property without the prior written permission of Ditto Media.`}
    />
  </Points.Content>
);
