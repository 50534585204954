import { toast } from "react-toastify";

import { API_INSTANCES } from "src/API_INSTANCES";
import store, { StripeProps, actions } from "src/Redux";

const dispatch = store.dispatch;

const createPaymentIntent = async (values: StripeProps.createPaymentIntent) => {
  const response = await API_INSTANCES.STRIPE.createPaymentIntent(values);
  console.log("createPaymentIntent RESPONSE", response);
  return response.data;
};

const getStripeCard = () => {
  API_INSTANCES.STRIPE.getStripeCard()
    .then((res) => dispatch(actions.stripe.setCardList(res.data)))
    .catch((error) => console.log(error))
    .finally(() => dispatch(actions.common.setPageLoading(false)));
};

const deleteStripeCard = (id: string) => {
  toast.promise(API_INSTANCES.STRIPE.deleteStripeCard(id), {
    pending: "Promise is pending",
    success: {
      render() {
        getStripeCard();
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};
const defaultStripeCard = (id: string) => {
  toast.promise(API_INSTANCES.STRIPE.defaultStripeCard(id), {
    pending: "Promise is pending",
    success: {
      render({ data }) {
        dispatch(actions.stripe.setCardList(data?.data));
        return "Promise resolved 👌";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "Promise rejected 🤯";
      },
    },
  });
};

export const STRIPE = {
  createPaymentIntent,
  getStripeCard,
  deleteStripeCard,
  defaultStripeCard,
};
