import { useEffect, useState } from "react";
import {
  BlockWrapper,
  GridCallbackProps,
  GridContainer,
} from "@tackboon/react-grid-rearrange";
import { Carousel } from "react-bootstrap";
import { Formik, FormikValues } from "formik";
import clsx from "clsx";
import { Grid } from "antd";

import {
  CarouselContainer,
  Container,
  GalleryContainer,
  GalleryEditContainer,
  GalleryPhotoCancelContainer,
  GalleryPhotoDetail,
  IconContainer,
} from "./style";
import { Buttons, FormFields, Typography } from "src/components/Basic";
import { useAppDispatch, useAppSelector, actions } from "src/Redux";
import { GalleryFields, uploadPhoto, idPrefix } from "./utils";
import { ICONS } from "src/assets/custom_Icons";
import { API_CALLS } from "src/API_CALLS";
import { FormikHelpers } from "src/components/Basic/FormFields/FormikFields/FormikFields.helpers";
import { ICompanyProfileSettings } from "./CompanyProfileSettings";

const Gallery = ({ isShow }: ICompanyProfileSettings) => {
  const dispatch = useAppDispatch();
  const screen = Grid.useBreakpoint();
  const [orderList, setOrderList] = useState<number[]>([]);
  const {
    companyGallery,
    isAddPhotoGalleryButtonDisable,
    isGalleryPhotoEdit,
    selectedGalleryPhotoIndex,
  } = useAppSelector((state) => state.settings);

  const onSubmitHandler = (value: FormikValues) => {
    API_CALLS.COMPANY_SETTINGS.updateGalleryPhotoCaption(
      companyGallery[selectedGalleryPhotoIndex].id,
      value.caption
    );
  };
  useEffect(() => {
    setOrderList([]);
  }, [selectedGalleryPhotoIndex]);

  const handleGalleryOrder = ({
    isDragging,
    lastMovingIndex,
    order,
    isClick,
  }: GridCallbackProps) => {
    if (!isDragging && isClick)
      dispatch(actions.settings.setSelectedGalleryPhotoIndex(lastMovingIndex));
    if (isDragging || isClick || lastMovingIndex === -1) return;
    const newIndex = order.findIndex((item) => item === lastMovingIndex);
    const orderListNewIndex = orderList.findIndex(
      (item) => item === lastMovingIndex
    );
    if (orderList.length > 0) {
      if (newIndex !== orderListNewIndex) {
        API_CALLS.COMPANY_SETTINGS.updateGalleryPhotoPosition(
          companyGallery[lastMovingIndex].id,
          newIndex
        );
        setOrderList(order);
      }
    } else {
      if (newIndex !== lastMovingIndex) {
        API_CALLS.COMPANY_SETTINGS.updateGalleryPhotoPosition(
          companyGallery[lastMovingIndex].id,
          newIndex
        );
        setOrderList(order);
      }
    }
  };

  return (
    <GalleryContainer>
      <div className="button-container">
        <Typography content="Gallery" styleName="inputLabel" color="black80" />
        {companyGallery.length > 0 && selectedGalleryPhotoIndex === -1 && (
          <Buttons
            handleClick={() => uploadPhoto()}
            label="Add Photo"
            variant="primary"
            isDisable={isAddPhotoGalleryButtonDisable}
          />
        )}
      </div>
      {selectedGalleryPhotoIndex === -1 ? (
        <Container isBorder={companyGallery.length === 0}>
          {(screen.lg ? true : !isShow) && companyGallery.length > 0 ? (
            <GridContainer
              totalItem={companyGallery.length}
              itemHeight={204}
              itemWidth={screen.lg ? 244 : 316}
              colGap={8}
              rowGap={8}
              cb={(value: GridCallbackProps) => handleGalleryOrder(value)}
            >
              {(styles) =>
                styles.map((style, i) => {
                  return (
                    <BlockWrapper index={i} animationStyle={style} key={i}>
                      <div className="gallery-container">
                        <img
                          src={`${process.env.REACT_APP_NOVA_BASE_URL}${companyGallery[i].path}`}
                          alt=""
                          width={screen.lg ? 244 : 316}
                          height={204}
                          draggable={false}
                        />
                        <GalleryEditContainer
                          onClick={() => {
                            dispatch(
                              actions.settings.setSelectedGalleryPhotoIndex(i)
                            );
                          }}
                        >
                          <ICONS.PenIcon />
                        </GalleryEditContainer>
                      </div>
                    </BlockWrapper>
                  );
                })
              }
            </GridContainer>
          ) : (
            <Buttons
              handleClick={() => uploadPhoto()}
              label="Add Photo"
              variant="primary"
              isDisable={isAddPhotoGalleryButtonDisable}
            />
          )}
        </Container>
      ) : (
        <CarouselContainer>
          <Carousel
            activeIndex={selectedGalleryPhotoIndex}
            indicators={false}
            nextIcon={
              <IconContainer
                isDisable={
                  selectedGalleryPhotoIndex === companyGallery.length - 1
                }
                onClick={() => {
                  if (selectedGalleryPhotoIndex === companyGallery.length - 1) {
                    dispatch(actions.settings.setSelectedGalleryPhotoIndex(0));
                    return;
                  }
                  dispatch(
                    actions.settings.setSelectedGalleryPhotoIndex(
                      selectedGalleryPhotoIndex + 1
                    )
                  );
                }}
              >
                <ICONS.GreaterThanIcon />
              </IconContainer>
            }
            prevIcon={
              <IconContainer
                isDisable={selectedGalleryPhotoIndex === 0}
                style={{ transform: "rotate(180deg)" }}
                onClick={() => {
                  if (selectedGalleryPhotoIndex === 0) {
                    dispatch(
                      actions.settings.setSelectedGalleryPhotoIndex(
                        companyGallery.length - 1
                      )
                    );
                    return;
                  }
                  dispatch(
                    actions.settings.setSelectedGalleryPhotoIndex(
                      selectedGalleryPhotoIndex - 1
                    )
                  );
                }}
              >
                <ICONS.GreaterThanIcon />
              </IconContainer>
            }
          >
            {companyGallery.map((item, index) => (
              <Carousel.Item key={index}>
                <img
                  alt="img"
                  height={"100%"}
                  width={screen.lg && !screen.xl ? "352px" : "100%"}
                  style={{ objectFit: "contain" }}
                  src={`${process.env.REACT_APP_NOVA_BASE_URL}${item.path}`}
                />
                <GalleryPhotoCancelContainer
                  onClick={() => {
                    API_CALLS.COMPANY_SETTINGS.getCompanyGallery();
                  }}
                >
                  <ICONS.CrossIcon />
                </GalleryPhotoCancelContainer>
              </Carousel.Item>
            ))}
          </Carousel>
          <Formik
            enableReinitialize
            initialValues={{
              caption: companyGallery[selectedGalleryPhotoIndex].caption ?? "",
            }}
            onSubmit={() => {}}
          >
            {(formik) => (
              <GalleryPhotoDetail>
                {isGalleryPhotoEdit ? (
                  <FormFields.TextArea {...GalleryFields.caption} />
                ) : (
                  <Typography
                    color="black80"
                    content={
                      companyGallery[selectedGalleryPhotoIndex].caption ?? ""
                    }
                    styleName="tableRowDefault"
                  />
                )}
                <div
                  className={clsx(
                    "d-flex gap-2 cancel-delete-button",
                    !isGalleryPhotoEdit &&
                      "flex-row-reverse justify-content-end",
                    screen.lg && !screen.xl && "flex-column"
                  )}
                >
                  <Buttons
                    handleClick={() => {
                      if (isGalleryPhotoEdit) {
                        dispatch(actions.settings.setGalleryPhotoEdit(false));
                        formik.setFieldValue(
                          "caption",
                          companyGallery[selectedGalleryPhotoIndex].caption
                        );
                      } else {
                        API_CALLS.COMPANY_SETTINGS.deleteCompanyGallery(
                          companyGallery[selectedGalleryPhotoIndex].id
                        );
                        dispatch(
                          actions.settings.setSelectedGalleryPhotoIndex(-1)
                        );
                        dispatch(actions.settings.setGalleryPhotoEdit(false));
                      }
                    }}
                    label={isGalleryPhotoEdit ? "Cancel" : "Delete"}
                    variant="light"
                  />
                  <Buttons
                    handleClick={() => {
                      if (isGalleryPhotoEdit) {
                        FormikHelpers.formikSubmitHandler({
                          formFields: GalleryFields,
                          formik,
                          idPrefix,
                          onSubmitHandler,
                        });
                      } else {
                        dispatch(actions.settings.setGalleryPhotoEdit(true));
                      }
                    }}
                    label={isGalleryPhotoEdit ? "Done Editing" : "Edit"}
                    variant="primary"
                  />
                </div>
              </GalleryPhotoDetail>
            )}
          </Formik>
        </CarouselContainer>
      )}
    </GalleryContainer>
  );
};

export default Gallery;
