import {
 ICONS
} from "src/assets/custom_Icons";
import { SettingNav } from "../Settings";


export const settingsOption: SettingNav[] = [
  { title: "My Profile", icon: <ICONS.UserProfileIcon />, key: "my-profile" },
  {
    title: "Company Profile Settings",
    icon: <ICONS.BuildingIcon />,
    key: "company-profile",
  },
  { title: "Team Overview", icon: <ICONS.MultiUserIcon />, key: "team-overview" },
  {
    title: "Email Notifications",
    icon: <ICONS.EmailIcon />,
    key: "email-notifications",
  },
  {
    title: "Messaging Templates",
    icon: <ICONS.LayoutIcon />,
    key: "messaging-templates",
  },
  {
    title: "Receipts",
    icon: <ICONS.ArchiveIcon />,
    key: "receipts",
  },
  {
    title: "Billing Information ",
    icon: <ICONS.CreditCardIcon />,
    key: "billing-information",
  },
];
