import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import clsx from "clsx";

import {
  Card,
  CardContainer,
  CardNumberContainer,
  Container,
  DropDownContainer,
  DropDownMenuContainer,
  DefaultExpiredContainer,
} from "./style";
import { cardTypeIcon } from "./utils";
import { Typography } from "src/components/Basic";
import { theme } from "src/components/Theme";
import { useAppSelector } from "src/Redux";
import { API_CALLS } from "src/API_CALLS";
import { DeactivateModal } from "../Modal";
import CardNumber from "./CardNumber";

const CardList = () => {
  const { cardList } = useAppSelector((state) => state.stripe);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Container>
      {cardList.map(
        ({ last_4_digits, id, type, is_default, expired, expiry_date }) => {
          return (
            <CardContainer key={id}>
              <Card
                selected={is_default}
                className={clsx(is_default && "selected-card")}
              >
                {cardTypeIcon[type as keyof typeof cardTypeIcon]}
                <CardNumberContainer>
                  <CardNumber content={"****"} />
                  <CardNumber content={"****"} />
                  <CardNumber content={"****"} />
                  <CardNumber content={last_4_digits} />
                </CardNumberContainer>
                {!is_default && !expired && (
                  <Typography
                    styleName="billingCardExpiry"
                    content={expiry_date}
                    color={"jobCheckoutCardNumber"}
                    className="expiry-date"
                  />
                )}
                {!is_default && expired && (
                  <OverlayTrigger
                    placement="bottom"
                    trigger={["click", "hover"]}
                    overlay={
                      <Popover
                        style={{ margin: "0px" }}
                        className={clsx(
                          "bs-popover-bottom default-button",
                          "popover-head-grey"
                        )}
                      >
                        <Popover.Header as="h3" className="popover-headers ">
                          Expired Card
                        </Popover.Header>
                        <Popover.Body style={{ margin: "0px" }}>
                          This is expired card.
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <DefaultExpiredContainer isDefault={false}>
                      expired
                    </DefaultExpiredContainer>
                  </OverlayTrigger>
                )}

                {is_default && (
                  <OverlayTrigger
                    placement="bottom"
                    trigger={["click", "hover"]}
                    overlay={
                      <Popover
                        style={{ margin: "0px" }}
                        className={clsx(
                          "bs-popover-bottom default-button",
                          expired ? "popover-head-grey" : "popover-head-primary"
                        )}
                      >
                        <Popover.Header as="h3" className="popover-headers ">
                          {` ${expired ? "Expired" : "Default"} Card`}
                        </Popover.Header>
                        <Popover.Body style={{ margin: "0px" }}>
                          {expired
                            ? "This is expired card."
                            : "Cannot remove default card."}
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    {expired ? (
                      <DefaultExpiredContainer isDefault={false}>
                        expired
                      </DefaultExpiredContainer>
                    ) : (
                      <DefaultExpiredContainer isDefault={true}>
                        default
                      </DefaultExpiredContainer>
                    )}
                  </OverlayTrigger>
                )}

                <Dropdown>
                  <DropDownContainer className="btn">
                    <FontAwesomeIcon
                      icon={faEllipsisV}
                      size="lg"
                      color={theme.colors.settings_icon}
                    />
                  </DropDownContainer>
                  <DropDownMenuContainer className="dropdown-menu tx-13">
                    <Dropdown.Item
                      href="#"
                      onClick={() => {
                        API_CALLS.STRIPE.defaultStripeCard(id);
                      }}
                    >
                      <Typography
                        content="Set as default"
                        styleName="tableSubHeading"
                        color="black_pure"
                      />
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => {
                        if (is_default) return;
                        setIsModalOpen(true);
                      }}
                    >
                      <Typography
                        content="Remove"
                        styleName="tableSubHeading"
                        color="black_pure"
                      />
                    </Dropdown.Item>
                  </DropDownMenuContainer>
                </Dropdown>
              </Card>
              <DeactivateModal
                content="Are you sure you want to remove this card ? "
                handleclick={() => {
                  API_CALLS.STRIPE.deleteStripeCard(id);
                  setIsModalOpen(false);
                }}
                heading="Remove Card"
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
              />
            </CardContainer>
          );
        }
      )}
    </Container>
  );
};

export default CardList;
