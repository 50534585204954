import styled from "styled-components";

import { Typography } from "src/components/Basic";
import { TermsProps } from "./Terms";

const Title = ({ content }: TermsProps.Text) => (
  <Typography {...{ content, styleName: "termsTitle", color: "black80" }} />
);
const Subtitle = ({ content }: TermsProps.Text) => (
  <SubtitleWrapper>
    <Typography
      {...{ content, styleName: "termsSubTitle", color: "primary_default" }}
    />
  </SubtitleWrapper>
);
const LastUpdated = ({ content }: TermsProps.Text) => (
  <Typography
    {...{ content, styleName: "termsLastUpdated", color: "black80" }}
  />
);
const SubtitleWrapper = styled.div`
  padding: 10px 0px 16px;
  @media (min-width: 576px) {
    padding: 10px 10px 28px;
  }
`;

export const Container = { Title, Subtitle, LastUpdated };
