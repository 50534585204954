import styled from "styled-components";

import { ICONS } from "src/assets/custom_Icons";
import { Typography } from "src/components/Basic";
import { theme } from "src/components/Theme";
import { useContext } from "react";
import { CheckoutSummaryContext } from ".";
import { useAppSelector } from "src/Redux";

type PromotionCodeProps = {
  promotionCode: string;
  setApplyClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setPromotionCode: React.Dispatch<React.SetStateAction<string>>;
  setShowPromotionCodeInput: React.Dispatch<React.SetStateAction<boolean>>;
};
export const PromotionCode = ({
  promotionCode,
  setApplyClicked,
  setPromotionCode,
  setShowPromotionCodeInput,
}: PromotionCodeProps) => {
  const { stripeData } = useAppSelector((store) => store.stripe);
  const { currency } = useContext(CheckoutSummaryContext);
  return (
    <Container>
      <CodeContainer>
        <PromoCodeTag>
          <ICONS.PromoCodeIcon />
          <Typography
            content={promotionCode}
            styleName="inputGeneral"
            color="primary_default"
          />
          <span
            onClick={() => {
              setApplyClicked(false);
              setPromotionCode("");
              setShowPromotionCodeInput(false);
            }}
          >
            <ICONS.XIcon fill={theme.colors.primary_default} />
          </span>
        </PromoCodeTag>
        <Typography
          styleName={"jobCheckoutPriceSummary"}
          content={`-${currency}${stripeData.discount}`}
          color={"black60"}
        />
      </CodeContainer>
      <Typography
        content={`${stripeData.discountPercentage}% off`}
        styleName="jobCheckoutPromoOffer"
        color="black60"
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 14px;
  gap: 4px;
`;
const CodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const PromoCodeTag = styled.div`
  padding: 6px;
  background-color: rgba(30, 150, 242, 0.25);
  width: fit-content;
  display: flex;
  gap: 6px;
  align-items: center;
`;
