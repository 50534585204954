const Polygon = () => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 0L9.33013 6H0.669873L5 0Z" fill="#222020" />
    </svg>
  );
};

export default Polygon;
