import { useContext } from "react";
import styled from "styled-components";

import { Buttons, Typography } from "src/components/Basic";
import { OnboardingContext } from ".";
import { AppContext } from "src/App";

export const Introduction = () => {
  const { userName, setGetStartedClicked } = useContext(OnboardingContext);
  const { isMobile } = useContext(AppContext);
  return (
    <Container>
      <Typography
        styleName={
          isMobile ? "onboardingIntroTextMobile" : "onboardingIntroText"
        }
        content={`Hi ${userName},`}
      />
      <TypoWrapper>
        <Typography
          styleName={
            isMobile ? "onboardingIntroTextMobile" : "onboardingIntroText"
          }
          content="Thank you for signing up with Nova!"
        />
      </TypoWrapper>
      <Buttons
        label="Get Started"
        handleClick={() => setGetStartedClicked(true)}
        variant="primary"
        width={isMobile ? "142px" : "342px"}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 992px) {
    padding-top: 78px;
  }
`;
const TypoWrapper = styled.div`
  margin-bottom: 42px;
`;
