import { API_INSTANCES } from "src/API_INSTANCES";
import { ReportsProps, actions } from "src/Redux/slices";
import store from "src/Redux";

const dispatch = store.dispatch;

//#region Linear charts
const getViewsChart = (values: ReportsProps.LinearChartRequest) => {
  API_INSTANCES.REPORTS.getViewsChart(values)
    .then((response) => {
      const status_code = response.status;
      const result: ReportsProps.linearChartResponse = response.data;
      dispatch(actions.reports.setViewsChartResponse(result));
      //console.log("GET VIEWS CHART RESULT: ", result);
      dispatch(actions.reports.setViewsChartErrorCode(status_code));
    })
    .catch((error) => {
      console.log("GET VIEWS CHART ERROR: ", error);
      const status_code = error.response.status;
      dispatch(actions.reports.setViewsChartErrorCode(status_code));
    });
};
const getApplicationsChart = (values: ReportsProps.LinearChartRequest) => {
  API_INSTANCES.REPORTS.getApplicationsChart(values)
    .then((response) => {
      const result: ReportsProps.linearChartResponse = response.data;
      const status_code = response.status;
      dispatch(actions.reports.setApplicationsChartResponse(result));
      //console.log("GET APPLICATIONS CHART RESULT: ", result);
      dispatch(actions.reports.setApplicationsChartErrorCode(status_code));
    })
    .catch((error) => {
      console.log("GET APPLICATIONS CHART ERROR: ", error);
      const status_code = error.response.status;
      dispatch(actions.reports.setApplicationsChartErrorCode(status_code));
    });
};
const getJobsCreatedChart = (values: ReportsProps.LinearChartRequest) => {
  API_INSTANCES.REPORTS.getJobsCreatedChart(values)
    .then((response) => {
      const status_code = response.status;
      const result: ReportsProps.linearChartResponse = response.data;
      dispatch(actions.reports.setJobsCreatedChartResponse(result));
      //console.log("GET JOBS CREATED CHART RESULT: ", result);
      dispatch(actions.reports.setJobsCreatedChartErrorCode(status_code));
    })
    .catch((error) => {
      console.log("GET JOBS CREATED CHART ERROR: ", error);
      const status_code = error.response.status;
      dispatch(actions.reports.setJobsCreatedChartErrorCode(status_code));
    });
};
//#endregion

//#region Doughnut charts
const getWorkSituationChart = () => {
  API_INSTANCES.REPORTS.getWorkSituationChart()
    .then((response) => {
      const result: ReportsProps.WorkSituationChartResponse = response.data;
      dispatch(actions.reports.setWorkSituationChartResponse(result));
      dispatch(actions.reports.setWorkSituationChartCompleted(true));
      //console.log("GET WORK SITUATION CHART RESULT: ", result);
    })
    .catch((error) => {
      console.log("GET WORK SITUATION CHART ERROR: ", error);
      const status_code = error.response.status;
      dispatch(actions.reports.setWorkSituationChartErrorCode(status_code));
    })
    .finally(() => {
      dispatch(actions.reports.setWorkSituationChartCompleted(true));
    });
};
const getEmploymentTypeChart = () => {
  API_INSTANCES.REPORTS.getEmploymentTypeChart()
    .then((response) => {
      const result: ReportsProps.EmploymentTypeChartResponse = response.data;
      dispatch(actions.reports.setEmploymentTypeChartResponse(result));
      //console.log("GET EMPLOYMENT TYPE CHART RESULT: ", result);
    })
    .catch((error) => {
      console.log("GET EMPLOYMENT TYPE CHART ERROR: ", error);
      const status_code = error.response.status;
      dispatch(actions.reports.setEmploymentTypeChartErrorCode(status_code));
    })
    .finally(() => {
      dispatch(actions.reports.setEmploymentTypeChartCompleted(true));
    });
};
//#endregion

const getAdvertisingMixChart = () => {
  API_INSTANCES.REPORTS.getAdvertisingMixChart()
    .then((response) => {
      const result: ReportsProps.AdvertisingMixChartResponse = response.data;
      dispatch(actions.reports.setAdvertisingMixChartResponse(result));
      //console.log("GET ADVERTISING MIX CHART RESULT: ", result);
    })
    .catch((error) => {
      console.log("GET ADVERTISING MIX CHART ERROR: ", error);
      const status_code = error.response.status;
      dispatch(actions.reports.setAdvertisingMixChartErrorCode(status_code));
    })
    .finally(() => {
      dispatch(actions.reports.setAdvertisingMixChartCompleted(true));
    });
};

const getHiringProgress = () => {
  API_INSTANCES.REPORTS.getHiringProgress()
    .then((response) => {
      const result: ReportsProps.HiringProgressResponse = response.data;
      dispatch(actions.reports.setHiringProgressResponse(result));
      //console.log("GET HIRING PROGRESS RESULT: ", result);
    })
    .catch((error) => {
      console.log("GET HIRING PROGRESS ERROR: ", error);
      const status_code = error.response.status;
      dispatch(actions.reports.setHiringProgressErrorCode(status_code));
    })
    .finally(() => {
      dispatch(actions.reports.setHiringProgressCompleted(true));
    });
};

export const REPORTS = {
  getViewsChart,
  getApplicationsChart,
  getJobsCreatedChart,

  getWorkSituationChart,
  getEmploymentTypeChart,

  getAdvertisingMixChart,

  getHiringProgress,
};
