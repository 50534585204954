import { createSlice } from "@reduxjs/toolkit";
import { RegisterProps } from "./Register";

const initialState: RegisterProps.State = {
  Step1Values: {} as RegisterProps.Step1Values,
  Step2Values: {} as RegisterProps.Step2Values,
  FormValues: {} as RegisterProps.FormValues,
  stepNumber: 1,
  errorMessageFromAPI: "",
  isAPICallInProgress: false,
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    setStep1Values: (state, action: RegisterProps.setStep1Values) => {
      state.Step1Values = action.payload;
      state.FormValues = { ...state.FormValues, ...state.Step1Values };
    },
    setStep2Values: (state, action: RegisterProps.setStep2Values) => {
      state.Step2Values = action.payload;
      state.FormValues = { ...state.FormValues, ...state.Step2Values };
    },
    setStepNumber: (state, action: RegisterProps.setStepNumber) => {
      state.stepNumber = action.payload;
    },
    setErrorMessageFromAPI: (
      state,
      action: RegisterProps.setErrorMessageFromAPI
    ) => {
      state.errorMessageFromAPI = action.payload || "";
    },
    setAPICallInProgress: (
      state,
      action: RegisterProps.setAPICallInProgress
    ) => {
      state.isAPICallInProgress = action.payload;
    },
  },
});

export const registerActions = registerSlice.actions;
export const registerReducer = registerSlice.reducer;
