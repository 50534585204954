import { useContext, useState } from "react";
import { Formik } from "formik";
import {
  BaseStripeElementsOptions,
  StripeElementsOptions,
  loadStripe,
} from "@stripe/stripe-js";
import {
  AddressElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { API_CALLS } from "src/API_CALLS";
import styled from "styled-components";
import { ROUTES } from "src/utils/ROUTES";
import { theme } from "src/components/Theme";
import { Typography } from "src/components/Basic";
import store, { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { PayNowButton } from "../Common";
import { AppContext } from "src/App";

export const CheckoutFormStripe = () => {
  const { common, stripe } = useAppSelector((store) => store);
  const { isDarkTheme } = common;
  const { stripeData } = stripe;

  const { isTabletOrBig } = useContext(AppContext);

  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "";
  const appearance: BaseStripeElementsOptions["appearance"] = {
    theme: isDarkTheme ? "night" : "stripe",
    variables: {
      colorText: isDarkTheme
        ? theme.colors.dark_input_text
        : theme.colors.black80,
      fontFamily: "Poppins, sans-serif",
      borderRadius: "5px",
      colorBackground: isDarkTheme
        ? theme.colors.dark_input_background
        : theme.colors.white_pure,
      colorTextPlaceholder: isDarkTheme
        ? theme.colors.dark_input_placeholder
        : theme.colors.black50,
      colorDanger: theme.colors.red100,
    },
    rules: {
      ".Input": {
        color: isDarkTheme
          ? theme.colors.dark_input_text
          : theme.colors.black80,
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        letterSpacing: "0em",
        boxShadow: "none",
        borderColor: isDarkTheme
          ? theme.colors.dark_input_border
          : theme.colors.black10,
      },
      ".Input:hover": {
        boxShadow: theme.Effects.hover_active,
      },
      ".Input:focus": {
        boxShadow: theme.Effects.hover_active,
        borderColor: isDarkTheme
          ? theme.colors.dark_input_border
          : theme.colors.black10,
      },
      ".Input:active": {
        boxShadow: theme.Effects.hover_active,
      },
      ".Input--invalid": {
        boxShadow: "none",
      },
      ".Input--invalid:hover": {
        borderColor: theme.colors.red100,
        boxShadow: theme.Effects.error,
      },
      ".Input--invalid:focus": {
        borderColor: theme.colors.red100,
        boxShadow: theme.Effects.error,
      },
      ".Input--invalid:active": {
        borderColor: theme.colors.red100,
        boxShadow: theme.Effects.error,
      },
      ".Error": {
        fontFamily: "Poppins, sans-serif",
        fontWeight: "400",
        lineHeight: "16px",
        letterSpacing: "0.01em",
        fontSize: `${isTabletOrBig ? 13 : 12}px`,
      },
      ".Label": {
        color: isDarkTheme
          ? theme.colors.dark_input_label
          : theme.colors.black80,
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "21px",
        letterSpacing: "0em",
      },
    },
  };

  const stripePromise = loadStripe(publishableKey);
  const options: StripeElementsOptions = {
    // Fully customizable with appearance API.
    mode: "payment",
    amount: stripeData.totalPayablePrice,
    currency: stripeData.currencyType,
    appearance,
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeForm />
    </Elements>
  );
};

const StripeForm = () => {
  const dispatch = useAppDispatch();
  const { isDarkTheme } = useAppSelector((store) => store.common);
  const { isTabletOrSmall } = useContext(AppContext);

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { stripeData } = store.getState().stripe;
    if (!elements || !stripe) return;

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message || "");
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const { client_secret: clientSecret } =
      await API_CALLS.STRIPE.createPaymentIntent({
        amount: stripeData.totalPayablePrice,
        currency: stripeData.currencyType,
      });

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: { return_url: window.location.origin + ROUTES.JOBS },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message || "");
    } else {
      dispatch(actions.campaign.setShowCampaignLoadingAnimation(true));
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      {() => {
        return (
          <form onSubmit={handleSubmit}>
            <FormElements>
              <PaymentElement />
              <div style={{ paddingTop: isTabletOrSmall ? "32px" : "40px" }}>
                <Typography
                  styleName="jobCheckoutFormBillingTitle"
                  content="Billing Address"
                  color={isDarkTheme ? "success_default" : "primary_default"}
                />
              </div>

              <AddressElement options={{ mode: "billing" }} />
            </FormElements>
            <br />
            {errorMessage && (
              <div style={{ color: theme.colors.red100 }}>{errorMessage}</div>
            )}
            <PayNowButton
              disabled={!stripe || !elements}
              clickHandler={() => {
                dispatch(
                  actions.campaign.setShowCampaignLoadingAnimation(true)
                );
              }}
            />
          </form>
        );
      }}
    </Formik>
  );
};

const FormElements = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
