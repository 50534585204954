import { Typography } from "src/components/Basic";
import { IJobs } from "../Job";
import { JobRowCellContainer } from "../JobStyle";
import { ActiveJobsProps } from "src/Redux/slices/ActiveJobs/ActiveJobs";
import { ROUTES } from "src/utils/ROUTES";

export const JobColumn = ({
  cell,
  navigate,
  className,
}: IJobs.CustomCellItem) => {
  const rowData = cell.row.original as ActiveJobsProps.jobOverview;

  return (
    <JobRowCellContainer
      className={className}
      onClick={() => navigate(`${ROUTES.PIPELINE_OVERVIEW}/${rowData.id}`)}
    >
      <Typography
        content={cell.value}
        styleName="tableRowDefault"
        color="black85"
        className="tableCell"
      />
      <Typography
        content={rowData.city}
        styleName="tableSubHeading"
        color="blue_grey"
      />
    </JobRowCellContainer>
  );
};
