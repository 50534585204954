import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const RefundAndReturns = () => (
  <>
    <Points.Heading pointNumber="5." heading="Refunds And Returns" />
    <Points.Indent>
      <Points.Content>
        <Text.Span content={`Unless otherwise required by applicable laws, `} />
        <Text.Bold content="Ditto Media does not refund any fee which has been paid for any package" />
        <Text.Span content=". This also means that we will not return any fee already paid where you terminate your use of Nova before the paid subscription period has lapsed entirely or where your account has been terminated for violating these Terms." />
      </Points.Content>
    </Points.Indent>
  </>
);
