import { Form, FormGroup } from "react-bootstrap";
import { Field, FieldProps } from "formik";
import styled from "styled-components";

import { FormFieldCommon } from "../FormFieldsCommon";
import { FormFieldsProps } from "../FormFields";
import { FIELD_CSS } from "../FormFieldsStyles/common";
import { theme } from "../../../../components/Theme";

export const SwitchGroup = (props: FormFieldsProps.SwitchGroup) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    options,
    disabled = false,
    needLabelPlaceholder = false,
    showLabel,
  } = props;

  return (
    <Field name={fieldName} key={fieldName}>
      {({ meta, form }: FieldProps) => {
        const selectedItemsMap = meta.value || {};
        const { setFieldValue, setFieldTouched } = form;
        const { touched, error } = meta;

        const isSelectedItemsEmpty = Object.keys(selectedItemsMap).every(
          (key) => selectedItemsMap[key] === false
        );
        const hasError = isRequired && touched && isSelectedItemsEmpty;
        const errorLabel = error || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        return (
          <FormGroup className="form-group">
            {showLabel && label && (
              <FormFieldCommon.Label
                content={`${label}${isRequired ? "*" : ""}`}
              />
            )}
            {!label && needLabelPlaceholder && (
              <FormFieldCommon.LabelPlaceHolder />
            )}
            <SwitchContainer
              id={`${idPrefix}_${fieldName}`}
              disabled={disabled}
              showOptionsInRow={false}
            >
              {options.map(({ label, value }) => (
                <Form.Label key={value} className="custom-switch ps-0">
                  <span className="custom-switch-description me-2">
                    {label}
                  </span>
                  <Form.Control
                    type="checkbox"
                    name={`${fieldName}_${value}`}
                    className="custom-switch-input"
                    value={value}
                    onChange={(event) => {
                      const value = event.target.value;
                      selectedItemsMap[value] = selectedItemsMap[value]
                        ? false
                        : true;
                      setFieldValue(fieldName, selectedItemsMap);
                      setFieldTouched(fieldName, true);
                    }}
                    checked={selectedItemsMap[value]}
                    disabled={disabled}
                  />
                  <span className="custom-switch-indicator"></span>
                </Form.Label>
              ))}
            </SwitchContainer>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </FormGroup>
        );
      }}
    </Field>
  );
};

export const Switch = (props: FormFieldsProps.Switch) => {
  const {
    fieldName,
    idPrefix,
    label,
    isRequired,
    disabled = false,
    onChangeHandler,
  } = props;

  return (
    <Field name={fieldName} key={fieldName}>
      {({ meta, form }: FieldProps) => {
        const { setFieldValue, setFieldTouched } = form;
        const { touched, value = false, error } = meta;

        const hasError = isRequired && touched && !value;
        const errorLabel = error || `${label || fieldName} is required`;
        const showErrorLabel = hasError && errorLabel;

        return (
          <FormGroup className="form-group">
            <SwitchContainer
              id={`${idPrefix}_${fieldName}`}
              disabled={disabled}
              showOptionsInRow={false}
            >
              <Form.Label className="custom-switch ps-0">
                <span className="custom-switch-description me-2 component-label">
                  {label}
                </span>
                <Form.Control
                  type="checkbox"
                  name={fieldName}
                  className="custom-switch-input"
                  value={value}
                  onChange={() => {
                    setFieldValue(fieldName, !value);
                    setFieldTouched(fieldName, true);
                    onChangeHandler && onChangeHandler(value);
                  }}
                  checked={value}
                  disabled={disabled}
                />
                <span className="custom-switch-indicator"></span>
              </Form.Label>
            </SwitchContainer>
            {showErrorLabel && <FormFieldCommon.Error content={errorLabel} />}
          </FormGroup>
        );
      }}
    </Field>
  );
};

const SwitchContainer = styled.div<{
  disabled: boolean;
  showOptionsInRow: boolean;
}>`
  ${FIELD_CSS.CHECKBOX_RADIO_SWITCH_COMMON};
  .custom-switch-indicator {
    background: ${({ disabled }) =>
      disabled ? theme.colors.black10 : theme.colors.black60};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
  .custom-switch-input:checked ~ .custom-switch-indicator {
    background: ${({ disabled }) =>
      disabled ? theme.colors.success_20 : theme.colors.success_default};
  }
  .custom-switch-indicator:before {
    top: 2px;
  }
  .custom-switch-description {
    ${FIELD_CSS.CHECKBOX_RADIO_SWITCH_LABEL};
  }
  ${FIELD_CSS.CHECKBOX_COMPONENT_LABEL};
`;
