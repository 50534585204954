import { Card } from "react-bootstrap";
import styled from "styled-components";
import { theme } from "../../../Theme";
import { Typography } from "../../../Basic";
import ReactApexChart from "react-apexcharts";
import { option } from "./utils";
import { useAppSelector } from "src/Redux";
import { PagesCommon } from "../../Common";
const CandidateApplicationChart = () => {
  const { daily_average, growth } = useAppSelector(
    (state) => state.dashboard.candidate_applications
  );
  return (
    <ChartCard>
      <Card.Header className="card-title pt-4 px-4">
        <Typography
          content="Candidate Applications"
          styleName="chartTitle"
          color="black80"
        />
      </Card.Header>
      <Card.Body>
        <PagesCommon.Components.DailyAverage dailyAverage={daily_average} />
        <div style={{ minWidth: "930px" }}>
          <ReactApexChart
            options={{
              ...option,
              yaxis: { ...option.yaxis, ...growth?.yaxis },
              xaxis: { ...option.xaxis, ...growth?.xaxis },
            }}
            series={growth?.series}
            type="line"
            height={270}
          />
        </div>
      </Card.Body>
    </ChartCard>
  );
};

export default CandidateApplicationChart;

const ChartCard = styled(Card)`
  .card-title:before {
    background: ${theme.colors.success_default};
    height: 17px;
    top: 24px;
  }
  .chart-features {
    padding: 17px 84px 0px 84px;
  }
  .card-body {
    overflow: auto;
  }
  .dark-theme & {
    .card-title label {
      color: ${theme.colors.black10};
    }
    .chart-features div label {
      &:first-child {
        color: ${theme.colors.black20};
      }
      &:last-child {
        color: ${theme.colors.black10};
      }
    }
  }
`;
