import { API_INSTANCES } from "src/API_INSTANCES";
import store, { actions } from "src/Redux";

const dispatch = store.dispatch;

const getNotificationList = () => {
  API_INSTANCES.NOTIFICATION.getNotificationList()
    .then((res) =>
      dispatch(actions.notification.setNotificationList(res.data.notifications))
    )
    .catch((error) => console.log(error))
    .finally(() => dispatch(actions.common.setPageLoading(false)));
};

export const NOTIFICATION = { getNotificationList };
