import React from "react";
import { Accordion } from "react-bootstrap";

import { IPipeLine } from "src/components/Pipeline/PipeLine";
import { AccordionHeader } from "../ExperienceStyle";
import { ICONS } from "src/assets/custom_Icons";
import { Typography } from "src/components/Basic";

const CareerAccordian: React.FC<IPipeLine.IAccordian> = ({ accordianData }) => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <Accordion
      defaultActiveKey={String(accordianData[0].id)}
      className="d-flex flex-column gap-2"
    >
      {accordianData.map((item) => (
        <Accordion.Item
          eventKey={String(item.id)}
          className="accordion-heading"
          key={item.candidate_id}
        >
          <Accordion.Header>
            <AccordionHeader>
              <ICONS.PlusCircleIcon />
              <div className="d-flex flex-column gap-2">
                <Typography
                  content={item.job_title}
                  styleName="candidateNameEmailOpen"
                  color="primary_default"
                />
                <Typography
                  content={item.company}
                  styleName="pipelineDefaultNav"
                  color="black80"
                />
                <Typography
                  content={`${month[item.from_month - 1]} ${item.from_year} - ${
                    month[item.to_month - 1]
                  } ${item.to_year}`}
                  styleName="careerYear"
                  color="primary_default"
                />
              </div>
            </AccordionHeader>
          </Accordion.Header>
          <Accordion.Body>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

export default CareerAccordian;
