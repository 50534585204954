import Select from "react-select";

import { IDropdownProps } from "./dropdown";
import { Field, FieldProps } from "formik";
import { theme } from "src/components/Theme";
import { DropdownContainer } from "./dropdownStyle";
import { Typography } from "src/components/Basic";
import { ErrorLabel } from "../style";

const Dropdown = (props: IDropdownProps.IProps) => {
  const {
    fieldName,
    idPrefix,
    isRequired,
    disabled = false,
    options,
    placeholder,
    onChangeHandler,
  } = props;

  return (
    <Field name={fieldName} key={fieldName}>
      {({ form, meta }: FieldProps) => {
        const { setFieldValue, setFieldTouched } = form;
        const { touched, value, error } = meta;

        const hasError = isRequired && touched && !value;
        const errorLabel = error || `${placeholder} is Required`;
        const showErrorLabel = hasError && errorLabel;

        return (
          <DropdownContainer Error={hasError} disabled={disabled}>
            <Select
              id={`${idPrefix}_${fieldName}`}
              name={fieldName}
              onChange={(selectedItem) => {
                setFieldValue(fieldName, selectedItem?.value);
                onChangeHandler && onChangeHandler();
              }}
              onBlur={() => setFieldTouched(fieldName, true)}
              options={options}
              classNamePrefix="select"
              placeholder={
                <div style={{ marginTop: "5px" }}>
                  <Typography
                    content={placeholder}
                    styleName="authInputText"
                    color="black50"
                  />
                </div>
              }
              isDisabled={disabled}
              defaultInputValue={value}
              styles={{
                input: (baseStyles: any) => {
                  return { ...baseStyles, color: theme.colors.primary_default };
                },
              }}
              openMenuOnFocus
            />
            {showErrorLabel && <ErrorLabel>{errorLabel}</ErrorLabel>}
          </DropdownContainer>
        );
      }}
    </Field>
  );
};

export default Dropdown;
