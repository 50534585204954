import { useContext } from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";

import { PagesCommon } from "../../Common";
import { DatepickerButton } from "./DatepickerButton";
import { ChartWithDateFilterUtils } from "./Utils";
import { ReportsProps } from "src/Redux";
import { ERROR_CODES } from "src/API_CALLS/utils";
import { LinearChartContext } from ".";

export const LinearChart = () => {
  const { type, data, setHeight } = useContext(LinearChartContext);

  const { response, title, errorCode } = data[type];
  let growth = response.growth ? response.growth : ({} as ReportsProps.growth);

  //#region Chart Data Updation from API Response
  const options = {
    ...ChartWithDateFilterUtils.options,
    xaxis: {
      ...ChartWithDateFilterUtils.options.xaxis,
      ...growth?.xaxis,
      type: "datetime",
    },
    yaxis: { ...ChartWithDateFilterUtils.options.yaxis, ...growth?.yaxis },
  };
  const series = growth?.series
    ? growth?.series
    : ([{ data: [] }] as ApexOptions["series"]);
  //#endregion
  const hasNoResultsError = ERROR_CODES.NO_RESULTS_ERROR === errorCode;

  return (
    <PagesCommon.Styles.ChartContainer
      setHeight={setHeight || hasNoResultsError}
    >
      <Container>
        <PagesCommon.Components.ChartTitle title={title} />
        <DatepickerButton />
      </Container>

      {hasNoResultsError ? (
        <PagesCommon.Components.InsufficientData />
      ) : (
        <>
          <PagesCommon.Components.DailyAverage
            dailyAverage={response.daily_average}
          />
          <div style={{ overflowX: "scroll", overflowY: "hidden" }}>
            <div style={{ minWidth: "930px" }}>
              <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={270}
              />
            </div>
          </div>
        </>
      )}
    </PagesCommon.Styles.ChartContainer>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;
