import { TermsAndPrivacyComponents } from "../Components";

const { Points, Text } = TermsAndPrivacyComponents;

export const Cookies = () => (
  <>
    <Points.Heading pointNumber="7." heading="Cookies" />
    <Points.Indent>
      <>
        <Points.Span pointNumber="7.1." Content={POINT_7_1} />
        <Points.Span pointNumber="7.2." Content={POINT_7_2} />
      </>
    </Points.Indent>
  </>
);

const POINT_7_1 = (
  <Text.Span content="The Platform may make use of “cookies” to automatically collect information and data through the standard operation of the Internet servers. “Cookies” are small text files a platform can use (and which we may use) to recognize repeat users, facilitate the user’s on-going access to and use of a platform and allow a platform to track usage behavior and compile aggregate data that will allow the platform operator to improve the functionality of the platform and its content, and to display more focused advertising to a user by way of third party tools." />
);
const POINT_7_2 = (
  <Text.Span
    content="The type of information collected by cookies is not used to personally identify you. If you do not want information collected using cookies, there is a simple procedure in most browsers that allows you to deny or accept the cookie feature. Please note that cookies may be necessary to provide you with certain features available on our Platform and thus if you disable the cookies on your browser you may not be able to use those features, and your access to our Platform will therefore be limited. If you do not disable “cookies”, you are deemed to consent to our use of any personal data collected using those cookies, subject to the provisions of this Privacy Policy and our other policies or terms.
"
  />
);
