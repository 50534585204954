import { theme } from "src/components/Theme";
import styled from "styled-components";

export const MainContainer = styled.div`
  max-width: 993px;
  margin: auto;
  @media (min-width: 1440px) {
    max-width: 1440px;
  }
`;
export const NoDataContainer = styled.div`
  border-radius: 5px;
  background: ${theme.colors.white_pure};
  box-shadow: 1px 1px 7px 0px rgba(154, 154, 204, 0.1);
  margin: 0 18px 90px;
  height: calc(100vh - 400px);
  padding: 41px 0 0 54px;
  display: flex;
  flex-direction: column;
  gap: 67px;
  .dark-theme & {
    background: ${theme.colors.black110};
    label {
      color: ${theme.colors.black10};
    }
  }
`;
export const PipelineContainer = styled.div`
  margin: 39px 0px 20px 28px;
  ${theme.text.pipelineText.lg}
  .back-button {
    cursor: pointer;
    display: none;
    label {
      cursor: pointer;
    }
  }
  label:first-child {
    @media (max-width: 991px) {
      color: ${theme.colors.primary_default};
    }
  }
  @media (max-width: 991px) {
    ${theme.text.pipelineText.sm}
    margin: 7px 18px 4px 20px;
    display: flex;
    justify-content: space-between;
    .back-button {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  @media (min-width: 1440px) {
    margin: 39px 0px 20px 15px;
  }
  .dark-theme & {
    .back-button svg path {
      fill: ${theme.colors.white10};
    }
    label {
      &:first-child {
        color: ${theme.colors.primary_default};
        @media (min-width: 992px) {
          color: ${theme.colors.white10};
        }
      }
      &:last-child {
        color: ${theme.colors.white10};
      }
    }
  }
`;
export const Container = styled.div`
  margin: 0px 12px 30px 22px;
  display: flex;
  gap: 10px;
  @media (min-width: 1440px) {
    margin: 0px 15px 40px;
  }
  @media (max-width: 991px) {
    margin: 0px 8px 40px;
  }
`;
export const CandidateContainer = styled.div<{ isShown: boolean }>`
  background: ${theme.colors.white_pure};
  width: 100%;
  position: relative;
  min-height: 412px;
  .eye-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 991px) {
    display: ${({ isShown }) => (isShown ? "block" : "none")};
  }
  .dark-theme & {
    background: ${theme.colors.black110};
  }
`;
export const Wrapper = styled.div<{ isShown: boolean }>`
  display: flex;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 100%;
    display: ${({ isShown }) => (isShown ? "block" : "none")};
  }
`;
export const Modal = styled.div<{ isShown: boolean; isMobileShow: boolean }>`
  display: ${({ isShown }) => (isShown ? "block" : "none")};
  position: absolute;
  z-index: 10;
  left: 0px;
  top: 64px;
  width: 100%;
  min-height: calc(100% - 64px);
  overflow: auto;
  background: #23232580;
  box-shadow: 0px 4px 16px 0px rgba(51, 51, 51, 0.08),
    0px 4px 4px 0px rgba(51, 51, 51, 0.04);
  @media (min-width: 1440px) {
  }
  @media (max-width: 991px) {
    display: ${({ isShown, isMobileShow }) =>
      isShown && isMobileShow ? "block" : "none"};
  } ;
`;
export const ModalBody = styled.div`
  background: ${theme.colors.white100};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow: auto;
  padding-top: 35px;
  min-height: calc(100vh - 625px);
  @media (max-width: 991px) {
    min-height: calc(100vh - 565px);
  }
  .react-pdf__message {
    color: ${theme.colors.primary_default};
    ${theme.text.filePreviewHeading}
    .dark-theme & {
      color: ${theme.colors.primary200};
    }
  }
  .download-file-text {
    display: flex;
    justify-content: center;
    margin: 20px auto 0;
    text-decoration: underline;
    text-decoration-color: ${theme.colors.primary_default};
    cursor: pointer;
    width: fit-content;
    label {
      cursor: pointer;
    }
  }
  .dark-theme & {
    background: ${theme.colors.black120};
  }
`;
export const ModalHeader = styled.div`
  background: ${theme.colors.light_default};
  border-bottom: 1px solid ${theme.colors.black10};
  height: 84px;
  padding: 16px 20px 18px 19px;
  display: flex;
  justify-content: space-between;
  svg {
    cursor: pointer;
  }
  & > div:first-child {
    width: 85%;
    @media (max-width: 768px) {
      width: 80%;
    }
    & > label:first-child {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
    }
  }
  .dark-theme & {
    background: ${theme.colors.black110};
    border-bottom: 1px solid ${theme.colors.black130};

    & > div label {
      &:first-child {
        color: ${theme.colors.black20};
      }
      &:last-child {
        color: ${theme.colors.grey30};
      }
    }
  }
`;
export const Circle = styled.div`
  border-radius: 100%;
  width: 30px;
  height: 30px;
  border: 1.5px solid ${theme.colors.black50};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .dark-theme & {
    border: 1.5px solid ${theme.colors.blue_grey};
    svg path {
      stroke: ${theme.colors.blue_grey};
    }
  }
`;
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  background: ${theme.colors.white100};
  gap: 16px;
  padding-top: 20px;
  padding-bottom: 61px;
  .dark-theme & {
    background: ${theme.colors.black120};
  }
`;
export const ModalMainContainer = styled.div`
  max-width: 993px;
  margin: 316px auto 30px;
  @media (min-width: 1440px) {
    max-width: 1440px;
    margin: 316px auto 30px;
  }
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 245px;
  }
`;
export const ModalWrapper = styled.div`
  width: 530px;
  margin-left: 410px;
  @media (min-width: 1440px) {
    width: 846px;
    margin-left: 410px;
  }
  @media (max-width: 991px) {
    width: calc(100% - 32px);
    margin: 0 auto;
  }
`;
