import { colors } from "src/components/Theme/Colors";
import { IIconProps } from "./customIcons";

const BriefCase = ({
  width,
  height,
  color = "white_pure",
}: IIconProps.Iprops) => {
  return (
    <svg
      width={width || "16"}
      height={height || "17"}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6 3.86842H11.2V3.02632C11.2 2.3563 10.9471 1.71372 10.4971 1.23994C10.047 0.766165 9.43652 0.5 8.8 0.5H7.2C6.56348 0.5 5.95303 0.766165 5.50294 1.23994C5.05286 1.71372 4.8 2.3563 4.8 3.02632V3.86842H2.4C1.76348 3.86842 1.15303 4.13459 0.702944 4.60836C0.252856 5.08214 0 5.72472 0 6.39474V13.9737C0 14.6437 0.252856 15.2863 0.702944 15.7601C1.15303 16.2338 1.76348 16.5 2.4 16.5H13.6C14.2365 16.5 14.847 16.2338 15.2971 15.7601C15.7471 15.2863 16 14.6437 16 13.9737V6.39474C16 5.72472 15.7471 5.08214 15.2971 4.60836C14.847 4.13459 14.2365 3.86842 13.6 3.86842ZM6.4 3.02632C6.4 2.80298 6.48429 2.58878 6.63431 2.43086C6.78434 2.27293 6.98783 2.18421 7.2 2.18421H8.8C9.01217 2.18421 9.21566 2.27293 9.36569 2.43086C9.51571 2.58878 9.6 2.80298 9.6 3.02632V3.86842H6.4V3.02632ZM14.4 13.9737C14.4 14.197 14.3157 14.4112 14.1657 14.5691C14.0157 14.7271 13.8122 14.8158 13.6 14.8158H2.4C2.18783 14.8158 1.98434 14.7271 1.83431 14.5691C1.68429 14.4112 1.6 14.197 1.6 13.9737V9.34211C3.63701 10.1928 5.80855 10.6299 8 10.6305C10.1914 10.6291 12.3628 10.192 14.4 9.34211V13.9737ZM14.4 7.4979C12.3827 8.43076 10.2034 8.91245 8 8.91245C5.79664 8.91245 3.61728 8.43076 1.6 7.4979V6.39474C1.6 6.1714 1.68429 5.9572 1.83431 5.79928C1.98434 5.64135 2.18783 5.55263 2.4 5.55263H13.6C13.8122 5.55263 14.0157 5.64135 14.1657 5.79928C14.3157 5.9572 14.4 6.1714 14.4 6.39474V7.4979Z"
        fill={colors[color]}
      />
    </svg>
  );
};

export default BriefCase;
