import { useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import topbar from "topbar";

import { LayoutProps } from "./Layout";
import Header from "./Header";
import Sidebar from "./Sidebar/Sidebar";
import Footer from "./Footer";
import { actions, useAppDispatch, useAppSelector } from "src/Redux";
import { handleChangeTheme } from "src/utils/theme";
import { API_CALLS } from "src/API_CALLS";

export const Layout = ({ children }: LayoutProps.Children) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isPageLoad, isDarkTheme } = useAppSelector((store) => store.common);

  useEffect(() => {
    if (isDarkTheme) {
      handleChangeTheme("light-theme", "dark-theme");
    } else {
      handleChangeTheme("dark-theme", "light-theme");
    }
  }, [isDarkTheme]);

  useEffect(() => {
    API_CALLS.NOTIFICATION.getNotificationList();
    dispatch(actions.common.setPageLoading(true));
    topbar.show();
  }, [location.pathname]);

  useEffect(() => {
    if (!isPageLoad) topbar.hide();
  }, [isPageLoad]);

  useEffect(() => {
    API_CALLS.PIPELINE.getMyProfile();
  }, []);

  return (
    <LayoutContainer>
      <Container>
        <Header />
        <Sidebar />
        {children}
      </Container>
      <Footer />
    </LayoutContainer>
  );
};

const LayoutContainer = styled.div`
  .main-header {
    margin-bottom: 0;
  }
`;
const Container = styled.div`
  min-height: calc(100vh - 51px);
`;
