import instance from "src/axios/axios-user";
import { ReportsProps } from "src/Redux/slices";
import { API_PATHS } from "src/utils/API_PATHS";

const getActiveJobs = () => instance.get(API_PATHS.activeJobs);
const getUnreadCandidates = () => instance.get(API_PATHS.unreadCandidates);
const getJobViews = () => instance.get(API_PATHS.jobViews);
const getApplications = () => instance.get(API_PATHS.applications);

const getViewsChart = (values: ReportsProps.LinearChartRequest) =>
  instance.post(API_PATHS.viewsChart, JSON.stringify(values));
const getApplicationsChart = (values: ReportsProps.LinearChartRequest) =>
  instance.post(API_PATHS.applicationsChart, JSON.stringify(values));

const getWorkSituationChart = () => instance.get(API_PATHS.workSituationChart);
const getEmploymentTypeChart = () =>
  instance.get(API_PATHS.employmentTypeChart);

const getAdvertisingMixChart = () =>
  instance.get(API_PATHS.advertisingMixChart);
const getJobsCreatedChart = (values: ReportsProps.LinearChartRequest) =>
  instance.post(API_PATHS.jobsCreatedChart, JSON.stringify(values));

const getHiringProgress = () => instance.get(API_PATHS.hiringProgress);

export const REPORTS = {
  getActiveJobs,
  getJobViews,
  getApplications,
  getUnreadCandidates,
  getViewsChart,
  getApplicationsChart,
  getWorkSituationChart,
  getEmploymentTypeChart,
  getAdvertisingMixChart,
  getJobsCreatedChart,
  getHiringProgress,
};
