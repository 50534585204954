import { ProgressBar } from "react-bootstrap";

import { RowCellContainer } from "../JobStyle";
import { IJobs } from "../Job";

export const CampaignStatus = ({ cell, className }: IJobs.CustomCellItem) => {
  return (
    <RowCellContainer className={className}>
      <ProgressBar
        variant="success"
        role="progressbar"
        now={Number(cell.value.slice(0, cell.value.length - 1))}
      />
    </RowCellContainer>
  );
};
